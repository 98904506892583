import React, {useState, useEffect} from 'react';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelSrf} from '../../model/srf.js';
import {useStateContext} from '../../helpers/state-context.js';
import {OutputIndicator} from './indicator.js';
import {ReactComponent as PlusSvg} from '../../assets/icons/plus.svg';
import {scrollTo} from '../../helpers/scroll.js';
import {useDonutColor} from '../../helpers/srf/use_donut_color.js';
import {useTranslation} from '../../helpers/use_translation.js';

const Output = function(){
    const {objectiveColor} = useDonutColor();
    const {getText} = useTranslation();
    const [state, dispatch] = useStateContext(['st_id', 'output', 'renderSize', 'language']);
    const [expandedOutputs, setExpandedOutputs] = useState([]);
    const resultSet = useModelFeedback(ModelSrf,
            {
                select: 'output_num as num, output_title as title, ' +
                    'count(indicator_id) as indicator_count',
                filter: 'st_outcome_id eq ' + state.st_id,
                language: state.language
            });

    useEffect(() => {
        let timerId = null;
        if(state.output !== null && ! expandedOutputs.includes(state.output)){
            setExpandedOutputs([...expandedOutputs, state.output]);
            timerId = setTimeout(() => {
                const element = document.getElementById('srf-output-' + state.output);
                if(element){
                    scrollTo(element, true, 1, null, -150);
                }
            }, 500);
        };
        return () => {clearTimeout(timerId);};
    }, [state.output]);

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const data = resultSet.data.sort((a, b) => a.num < b.num ? -1 : 1);
    const areAllSelected = expandedOutputs.length === data.length;
    return <div className={'zol-short-term-outputs'}>
        <div style={{display: 'flex', justifyContent: 'flex-end'}} className={'pdf-export-hide'}>
            <span className={'zol-st-toggle' + (areAllSelected ? '' : ' zol-st-toggle-active')}
                    onClick={() => {setExpandedOutputs(data.map((output) => output.num));}}>
                {getText('show-list-all')}
            </span> |
            <span className={'zol-st-toggle' + (expandedOutputs.length === 0 ? '' : ' zol-st-toggle-active')}
                    onClick={() => {setExpandedOutputs([]);}}>
                {getText('hide-list-all')}
            </span>
        </div>
       <div className={'zol-short-term-outputs-list'}>
           {data.map((output, key) => {
               const indicatorTitle = (output.indicator_count > 1) ?
                   getText('filter-indicator-many') :
                   getText('filter-indicator-one');
               return <div key={key} className={'short-term-content-output page-break'}
                       style={{
                           borderColor: `rgb(${objectiveColor?.join(',')})`
                           // margin: (key == 0 || key == (data.length - 1)) ?
                           //     '20px 0 ' : '40px 0'
                       }}
                       id={'srf-output-' + output.num}>
                <span className={'output-num'}
                        style={{
                            backgroundColor: `rgb(${objectiveColor?.join(',')})`
                        }}>
                    {getText('filter-output-one')} {output.num}
                </span>
                   <p className={'output-title'}>{output.title}</p>
                   <div className="zol-show-toggle-container">
                       <div className="zol-show-toggle"
                               onClick={() => {
                                   if(expandedOutputs.includes(output.num)){
                                       setExpandedOutputs(expandedOutputs.filter((item) => (
                                           item !== output.num)));
                                   }else{
                                       setExpandedOutputs([...expandedOutputs, output.num]);
                                       dispatch({type: 'output', value: output.num});
                                   }
                               }}>
                           <p className="indicator-number">
                               {output.indicator_count} {indicatorTitle}
                           </p>
                           <p className="show-hide-button pdf-export-hide">
                               {expandedOutputs.includes(output.num) ?
                                   `${getText('hide-list')} ` :
                                   `${getText('show-list')} `
                               }
                               <PlusSvg transform={
                                   expandedOutputs.includes(output.num) ? 'rotate(45)' : null
                               }/>
                           </p>
                       </div>
                       {expandedOutputs.includes(output.num) ?
                           <OutputIndicator outputId={output.num}/> :
                           null
                       }
                   </div>
               </div>;
           })}
       </div>
    </div>;
};

export {Output};
