import './tree.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {TreeNode} from './node.js';

const UITree = function(props){
    const value = Array.isArray(props.value) ? props.value : [props.value];

    return <ul className={'zol-tree'}>
            {props.data.map((node, key) => (
                <TreeNode key={key}
                        level={0}
                        node={node}
                        onSelect={props.onSelect}
                        isItemChecked={props.isItemChecked}
                        onCheck={props.onCheck}
                        value={value}
                        isCheckable={props.isCheckable}>
                    {node.children}
                </TreeNode>
            ))}
        </ul>;
};

UITree.defaultProps = {
    isCheckable: false,
    isSearchable: false,
    value: [],
    data: [],
    onSelect: () => {},
    onCheck: () => {}
};

UITree.propTypes = {
    onSelect: PropTypes.func,
    isSearchable: PropTypes.bool,
    onCheck: PropTypes.func,
    isItemChecked: PropTypes.func,
    value: PropTypes.array.isRequired,
    data: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                defaultVisible: PropTypes.bool,
                visible: PropTypes.bool,
                children: PropTypes.array
            })
    ).isRequired,
    isCheckable: PropTypes.bool
};

export {UITree};
