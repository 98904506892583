import React from 'react';
import PropTypes from 'prop-types';
import './item-block.scss';
import {useStateContext} from '../../helpers/state-context.js';
import styles from '../../assets/variables.module.scss';

const IndicatorLine = function(props){
    const [state, dispatch] = useStateContext(['page', 'lt_id', 'st_id', 'priority', 'output', 'renderSize']);
    const {boxes} = props;
    const isMobile = state.renderSize === 'small';
    const isTablet = state.renderSize === 'medium';
    const widthBox = 100 / boxes.length || props.widthBox;

    const lengthLine = widthBox * (boxes.length ? boxes.length - 1 : 0);
    const maxLengthLine = (state.page === 'objectives' ? 310 : 410) *
        (boxes.length ? boxes.length - 1 : 0);
    // the line should not go pass the last (or before the first box),
    // so for these boxes the width is divided by 2 as the line starts in the
    // middle of each box. When there are more than 2 boxes, the full length of the
    // box should be added to the width of the line.

    const lengthLineShortTerm = 'calc(100% - 130px)';
    const goToOverview = () => {
        dispatch({type: 'setSTOutcome', value: null});
    };

    const mobileStyleTitle = (isMobile || isTablet) ?
        {padding: '8px', maxWidth: 225, top: '-30px'} : {};

    const title = (isMobile || isTablet) ?
        <p style={{margin: 0, letterSpacing: '-1px'}}>
            {props.title} ({props.stOutcomes.length})
        </p> :
        props.title;

    return props.firstRequirement(state) ?
        <div className="zol-indicator-line pdf-export-hide">
            <div className="zol-box-line">
                <div className="zol-line"/>
            </div>
            <div className="zol-box-horizontal-line" style={{width: '100%'}}>
                <div className="zol-indicator-line-horizontal"
                        style={{width: lengthLine + '%', maxWidth: maxLengthLine}}/>
                <div className={'zol-indicator-title'}>
                    {props.title}
                </div>
            </div>
            {boxes.map((box, key) => (
                <div key={key} id="zol-box-line-vertical" className="zol-box-line"
                        style={{width: 'calc(' + widthBox + '% - 10px)',
                            maxWidth: (state.page === 'objectives' ? 300 : 400),
                            margin: '0px 5px',
                            display: 'inline-block'}}>
                    <div className="zol-line"/>
                </div>
            ))}
        </div> :
        <div className="zol-indicator-line pdf-export-hide"
                style={{width: lengthLineShortTerm, margin: 'auto'}}>
            <div className="zol-box-line" style={{height: '125px'}}>
                <div className="zol-line"/>
            </div>
            <div className="zol-box-horizontal-line">
                <div className="zol-indicator-line-horizontal"/>
                <div className={'zol-indicator-title zol-link'}
                        onClick={goToOverview}
                        style={mobileStyleTitle}>
                    {title}
                    {(isMobile || isTablet) ?
                        <div className={'zol-indicator-mobile-nav'}>
                            {props.stOutcomes.map((it, key) => {
                                const color = (it.id === boxes.id) ? styles.colorBlueText : styles.colorLight;
                                return <span className={'mobile-nav-circle'}
                                        style={{backgroundColor: color}}
                                        key={key}/>;
                            })}
                        </div> : null}
                </div>
            </div>
            <div className="zol-box-line" style={{width: widthBox + 'px'}}>
                <div className="zol-line"/>
            </div>
        </div>;
};

IndicatorLine.defaultProps = {
    stOutcomes: [],
    title: '',
    firstRequirement: (state) => (state.lt_id && !state.st_id)
};

IndicatorLine.propTypes = {
    boxes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    widthBox: PropTypes.number,
    title: PropTypes.string,
    stOutcomes: PropTypes.array,
    firstRequirement: PropTypes.func
};

export {IndicatorLine};
