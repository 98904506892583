import './donut.scss';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {DonutAllAnnotations} from './annotation/all-annotations.js';
import {useSize} from '../../../helpers/use_size.js';
import {useAnimation} from '../../../helpers/animation/use_animation.js';
import {getDonutColors} from '../../../helpers/color.js';
import {scrollTo} from '../../../helpers/scroll.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {DonutShadow} from './shadow.js';
import {useSegmentColor} from './use_segment_color.js';
import {useDonutData} from './use_donut_data.js';
import {DonutRing} from './ring.js';
import {DonutSegmentLongTerm} from './segment/long-term.js';
import {useTranslation} from '../../../helpers/use_translation.js';
import {ReactComponent as IconExternalLink} from '../../../assets/icons/external-link-outline.svg';

const donutSegment = {oee: 'output', ccp: 'priority', objectives: 'lt_id'};

// TODO: refactor, try split it up in multiple simpler components
const ChartDonut = function(props){
    const [state, dispatch] = useStateContext();
    const [annotationActive, setAnnotation] = useState(state.page === 'objectives');
    const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);
    const data = useDonutData(state.page);
    const colors = getDonutColors(data.length, state.page);
    const {getSegmentColor} = useSegmentColor(data, colors);

    const {getText} = useTranslation();
    const isMobile = (state.renderSize === 'small');

    const stateKey = donutSegment[state.page];

    // Rotate the donut when an item is selected
    useEffect(() => {
        if(state[stateKey] && ! props.frozen && (data?.length > 0)){
            const contentElement = document.getElementsByClassName('donut-center');
            const allSelectableDonutItems = props.has1Level ?
                    data : data.map((obj) => (obj.children)).flat(1);
            const selectedItem = allSelectableDonutItems.find((lt) => (
                lt.data.id === parseInt(state[stateKey])
            ));

            if(selectedItem){
                const rotate = 180 - (selectedItem.startAngle + selectedItem.endAngle) / 2 / Math.PI * 180;
                dispatch({type: 'rotate', value: rotate});
                // Scroll down to details;
                if(contentElement && contentElement.length > 0 &&
                        selectedItem.startAngle !== currentSelectedIndex){
                    setCurrentSelectedIndex(selectedItem.startAngle);
                    if(! state.output){
                        scrollTo(contentElement[0], true, 1, null, isMobile ? 0 : -60);
                        setTimeout(() => {
                            scrollTo(contentElement[0], true, 1, null, isMobile ? 0 : -60);
                        }, 500);
                        setTimeout(() => {
                            scrollTo(contentElement[0], true, 1, null, isMobile ? 0 : -60);
                        }, 1000);
                    }
                    return;
                }
            }else{
                dispatch({type: 'rotate', value: 0});
            }
        }else{
            dispatch({type: 'rotate', value: 0});
        }
    }, [state[stateKey], props.frozen, data.length, dispatch]);

    // Reset the circle when no item is selected
    useEffect(() => {
        // When state rotate changes to 0 we hide the annotation for a second, so it isn't shown while rotate.
        let timerId = null;
        if(state.rotate === 0){
            scrollTo(document.body, true);
            setAnnotation(false);
            timerId = setTimeout(() => {setAnnotation(true);}, 1000);
        }
        return () => {
            clearTimeout(timerId);
        };
    }, [state.rotate]);

    const [size, measuredRef] = useSize((w, h) => {return {width: w, height: h};}, {width: 1, height: 1});
    const width = state.exportImage === 'zol-export' ? 1000 : (props.frozen ? props.width : size.width);
    let height = state.exportImage === 'zol-export' ?
        1000 :
        (isMobile && ! props.frozen ? size.width * 1.1 : props.height);
    // width minus the width of the label on each sides minus the space between label and donut
    // height minus height of label on top and bottom minus the margin between label and donut
    // 0 at ccp because there are no annotations
    const labelSpaceX = isMobile && props.has1Level ?
            0 :
            (isMobile && ! props.onlyLongTerm ? 50 : (props.has1Level ? 0 : 2 * (120 + 20)));
    const maxWidth = width - labelSpaceX;
    const labelSpaceY = isMobile && ! props.onlyLongTerm ? 50 : (props.has1Level ? 0 : 2 * (75 + 30));
    const maxHeight = height - labelSpaceY;
    const donutRadius = Math.min(maxWidth, maxHeight) / 2;
    const innerRadius = props.startRadius * donutRadius;

    // to make sure that the annotations are readable add some more spacing at the top/bottom in mobile
    height += isMobile && ! state[stateKey] && state.page === 'objectives' ? 100 : 0;

    const translateHeightCenter = height / 2;
    const heightDetails = state[stateKey] ?
        (props.has1Level ? donutRadius * (props.startRadius + 0.2) : donutRadius * .75) :
        0;
    height += heightDetails;

    // custom tween function to get the smallest distance between two angles
    const circleTween = (startValue, endValue, fact) => {
        let offset = 0;
        while(Math.abs(endValue - startValue + offset) > 180){
            offset += endValue - startValue + offset < 0 ? 360 : -360;
        }
        return fact === 1 ? endValue : startValue + fact * (endValue - startValue + offset);
    };
    const angle = useAnimation(state.rotate, 500, circleTween);
    const rotation = props.frozen ? '' : ` rotate(${angle})`;

    const resetDonut = () => {
        dispatch({type: 'rotate', value: 0});
        if(state.page === 'objectives'){
            dispatch({type: 'reg_priorities', value: []});
            dispatch({type: 'sdg', value: []});
            dispatch({type: 'gcm', value: []});
            dispatch({type: 'setLTOutcome', value: null});
        }else if(state.page === 'ccp'){
            dispatch({type: 'priority', value: null});
        }else if(state.page === 'oee'){
            dispatch({type: 'output', value: null});
        }
    };

    const isFilterActive = state[stateKey] || state.sdg?.length > 0 || state.gcm?.length > 0 ||
            state.reg_priorities?.length > 0;

    const styleCenter = {
        width: 2 * innerRadius,
        height: 2 * innerRadius,
        marginLeft: -1 * innerRadius,
        marginTop: -1 * innerRadius - (isFilterActive ? (isMobile ? 10 : 20) : 0),
        top: translateHeightCenter,
        left: 0.5 * width
    };

    return <div ref={measuredRef}
            className={'srf-donut page-break' + (state[stateKey] === null ? '' : ' is-selected')}
            style={{position: 'relative', marginTop: isMobile ? 20 : 0}}>
        <svg height={height} width={width} viewBox={`0 0 ${width} ${height}`} id={props.id}>
            {state[stateKey] ?
                <g transform={`translate(${width / 2}, ${translateHeightCenter}) rotate(127.5)`}>
                    <DonutSegmentLongTerm data={data}
                            isMobileOrTablet={isMobile}
                            donutRadius={props.has1Level ?
                                donutRadius * (props.startRadius + 0.4) : donutRadius}
                            has1Level={props.has1Level}
                            value={stateKey}/>
                </g> :
                null
            }
            <g className={'zol-donut-segments'}
                    transform={`translate(${width / 2}, ${translateHeightCenter})` + rotation}>

                <DonutShadow data={data} startRadius={props.startRadius}
                        donutRadius={donutRadius} has1Level={props.has1Level}/>

                <DonutRing data={data}
                        radius={props.startRadius}
                        slice={null}
                        type={state.page}
                        has1Level={props.has1Level}
                        frozen={props.frozen}
                        getSegmentColor={getSegmentColor}
                        stateKey={stateKey}
                        annotationActive={annotationActive}
                        onlyLongTerm={props.onlyLongTerm}
                        isMobile={isMobile}
                        width={width}
                        donutRadius={donutRadius}/>

                {props.onlyLongTerm ?
                    <DonutAllAnnotations
                            data={data}
                            widthScreen={width}
                            height={height}
                            radius={donutRadius}/> :
                    null}
            </g>

        </svg>
        <div className={'donut-center donut-center-' + state.language} style={styleCenter}>
            <h1>{getText('donut-center-title')}</h1>

            {isMobile ? null : <h2>{getText('donut-center-subtitle')}</h2>}

            {isFilterActive ?
                <span className="donut-center-reset pdf-export-hide" onClick={resetDonut}>
                    {getText('donut-reset')}
                </span> :
                (isMobile ?
                    null :
                    <a href="https://www.iom.int/iom-strategic-plan-2024-2028" target="_blank"
                            className="link-strategic-plan" rel="noreferrer">
                        Strategic plan<br/>2024-2028 <IconExternalLink/>
                    </a>
                )
            }
        </div>
    </div>;
};

ChartDonut.propTypes = {
    data: PropTypes.array,
    height: PropTypes.number,
    width: PropTypes.number,
    startRadius: PropTypes.number,
    annotations: PropTypes.array,
    frozen: PropTypes.bool,
    onlyLongTerm: PropTypes.bool,
    id: PropTypes.string,
    has1Level: PropTypes.bool
};

ChartDonut.defaultProps = {
    startRadius: (1 / 3),
    height: 800,
    frozen: false,
    onlyLongTerm: false,
    has1Level: false
    // todo: create data defaultprop
};

export {ChartDonut};
