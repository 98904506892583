import {getHash} from './get_hash.js';
import {stringify} from './stringify.js';

const memo = function(func, storageLimit = 100){
    const memoMap = new Map();

    return function(...args){
        const callHash = getHash(stringify(args));
        if(! memoMap.has(callHash)){
            while(memoMap.size >= storageLimit){
                memoMap.delete(memoMap.keys()[0]);
            }
            memoMap.set(callHash, func(args));
        }
        return memoMap.get(callHash);
    };
};

export {memo};
