import './index.scss';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UIInput} from '../../components/ui/input/input.js';
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

function SearchInput(props){
    const [value, setValue] = useState(props.value || '');

    useEffect(() => {
        // Search automatically after 1 second
        const timerId = setTimeout(() => {
            if(props.isAutoSearchEnabled){
                props.onSearch(value);
            }
        }, 1000);

        return () => {
            clearTimeout(timerId);
        };
    }, [value]);

    const applyValue = (event) => {
        event.stopPropagation();
        const nextValue = props.value === value ? '' : value;
        setValue(nextValue);
        props.onSearch(nextValue);
    };

    return <div className={'search-bar-container' + (props.isActive ? ' zol-active' : '')}>

        <UIInput value={value}
                name="search"
                onChange={(v) => {
                    props.onChange(v);
                    setValue(v);
                }}
                onEnter={props.onSearch}
                placeholder={props.placeholder}/>

        <div className={(value.length > 2 ? 'cancel' : 'search') + '-button'} onClick={applyValue}>
            <FontAwesomeIcon icon={props.value.length > 2 ? faTimes : faSearch} className={'search-icon'}/>
        </div>
    </div>;
};

SearchInput.defaultProps = {
    value: '',
    searching: false,
    isActive: false,
    onSearch: () => {},
    onChange: () => {},
    isAutoSearchEnabled: true
};

SearchInput.propTypes = {
    value: PropTypes.string,
    onSearch: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    isActive: PropTypes.bool,
    onCancel: PropTypes.func,
    isAutoSearchEnabled: PropTypes.bool
};

export {SearchInput};
