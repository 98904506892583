import React from 'react';
import {list} from './level_mapping.js';
import {UITabs} from '../../ui/tabs/tabs.js';
import {UIButton} from '../../ui/button/button.js';
import {AdminList} from '../list.js';
import {useStateContext} from '../../../helpers/state-context/use_state_context.js';

const AdminLinkList = function(props){
    const [state, dispatch] = useStateContext(['subpage', 'category', 'type']);

    return <UITabs defaultValue={state.category} onChange={(value) => dispatch({type: 'category', value})}>
        {list.map((item) =>
            <UITabs.Tab key={item.id} value={item.id} title={item.title}>
                {item.id.indexOf('rp') !== -1 ?
                    <UIButton className={'zol-light'}
                            onClick={() => dispatch({type: 'subpage', value: 'new'})}
                            style={{margin: '10px 5px'}}>
                        New
                    </UIButton> :
                    null
                }
                <AdminList model={item.model}/>
            </UITabs.Tab>
        )}
    </UITabs>;
};

export {AdminLinkList};
