import React from 'react';
import {PropTypes} from 'prop-types';

// TODO: note that we are not importing any css here. I think we want to have here general styling of an
// input element, while we can import another stylesheet on the form level that overwrites certain styling
// for use in the form.

const UIInputCheckbox = function(props){
    const setNewValue = (option, setActive) => {
        const value = setActive ?
            [...props.value, option] :
            props.value.filter((item) => item !== option);
        props.onChange(value);
        props.onSave(value);
    };

    const options = props.options instanceof Array ? props.options : Object.keys(props.options);

    return <div className={'zol-ui-input-checkbox ' + props.className}
            style={{display: 'flex', flexWrap: 'wrap', ...props.style}}>

        {options.map((o, key) => {
            const item = (props.options instanceof Array) ? o : props.options[o];
            const value = (props.options instanceof Array) ? o.value : o;
            const label = (typeof item === 'object') ? item.label : item;
            const isChecked = Array.isArray(props.value) ? props.value.includes(value) : false;
            return <label key={key} className={'zol-ui-input-checkbox-item'}
                    style={{margin: '10px 0px', opacity: (item?.disabled) ? .85 : 1}}>
                <input type={'checkbox'}
                        disabled={item?.disabled || false}
                        checked={isChecked}
                        onChange={(e) => setNewValue(value, ! isChecked)}/>
                {label}
            </label>;
        })}

    </div>;
};

UIInputCheckbox.defaultProps = {
    value: [],
    hasError: false,
    onChange: () => {},
    onSave: () => {},
    className: ''
};

UIInputCheckbox.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    value: PropTypes.array,
    hasError: PropTypes.bool,
    style: PropTypes.object
};

export {UIInputCheckbox};
