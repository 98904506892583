import React from 'react';
import {Model} from './model.js';
import {Loader} from '../elements/loader.js';
import {ComponentError} from '../elements/component-error.js';

// TODO: in case of an error we could send the error to a global logging event, which we can connect to
// tracking software like google analytics if we want.

const insertModelFeedback = function(resultSet){
    const result = {...resultSet, feedback: null};

    if(typeof resultSet.status === 'undefined'){
        result.feedback = <ComponentError title="Wrong data format for ModelConsumer"
                message={'ModelConsumer with processStatus enables expects a status property in the ' +
                        'resultSet. Provide a well formatted resultSet or disable processStatus'}/>;
    }else if(resultSet.status === Model.Status.INACTIVE || resultSet.status === Model.Status.WAITING){
        result.feedback = <Loader size={50}/>;
    }else if(resultSet.status === Model.Status.FAILED){
        const error = (typeof resultSet.error === 'object') ? resultSet.error.message : resultSet.error;
        result.feedback = <ComponentError title="Error fetching data" message={error}/>;
    }
    return result;
};

export {insertModelFeedback};
