import React, {useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {useStateContext} from '../../../helpers/state-context.js';

// TODO: styling to css
const styleHeader = {
    borderBottom: '2px solid rgb(229, 229, 229)'
};

const styleItem = {
    display: 'inline-block',
    padding: '10px 25px',
    color: 'rgb(75, 75, 75)',
    cursor: 'pointer',
    marginBottom: '-1px',
    fontSize: '16px'
};

const styleItemSelected = {
    ...styleItem,
    color: 'rgb(0,0,0)',
    fontWeight: 'bold',
    borderBottom: '2px solid rgb(55, 70, 142)'
};

const UITabsHeader = function(props){
    // get the current value for active tab and get the dispatcher to set a new value
    const [state, dispatch] = useStateContext([props.name]);
    const value = state[props.name];

    useEffect(() => {
        // Select first index of tab when selected item doesn't exist anymore;
        if(Object.keys(props.options).length > 0 && !Object.keys(props.options).includes(value)){
            dispatch({type: props.name, value: Object.keys(props.options)[0]});
        }
    }, [props.options]);

    return <div style={styleHeader}>

        {Object.keys(props.options).map((option) => (

            <span key={option}
                    style={option === value ? styleItemSelected : styleItem}
                    onClick={() => {dispatch({type: props.name, value: option});}}>

                {typeof props.options[option] === 'function' ?
                    props.options[option]() :
                    props.options[option]
                }

            </span>

        ))}

    </div>;
};

UITabsHeader.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired
};

export {UITabsHeader};
