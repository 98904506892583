import React from 'react';
import {PropTypes} from 'prop-types';
import {gcmMapping} from '../../../assets/icons-gcm.js';
import {setTooltip} from '../../../helpers/srf/set_tooltip.js';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {ModelSrfLinkView} from '../../../model/srf-link-view.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {sortFilter} from '../../../helpers/srf/sort_filter.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const OverviewGcm = function(props){
    const {shortTermId} = props;
    const [state, dispatch] = useStateContext(['reg_priorities', 'sdg', 'gcm', 'language']);
    const filterState = {reg_priorities: state.reg_priorities, sdg: state.sdg, gcm: state.gcm};
    const {getText} = useTranslation();

    const resultSet = useModelFeedback(ModelSrfLinkView, {
        select: 'gcm_objective_id as id, gcm_objective_num as num, gcm_objective_title as title',
        filter: `gcm_objective_id ne null and item_type eq 'srf_st_outcome' and fk_item_id eq ${shortTermId}`,
        language: state.language
    });

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    if(resultSet.data.length === 0){
        return null;
    }

    const hasFilter = Object.values(filterState).some((values) => (values.length > 0));
    const hasGcmFilter = filterState.gcm.length > 0;
    const groupOpacity = hasFilter && ! hasGcmFilter ? 0.4 : 1;

    const openGcm = () => {
        dispatch({type: 'setSTOutcome', value: props.shortTermId});
        dispatch({type: 'topic', value: 'gcm'});
    };

    const items = sortFilter(resultSet.data, 'gcm');
    const title = (items?.length > 1) ?
        getText('filter-gcm-many') :
        getText('filter-gcm-one');
    return <div className={`zol-short-term-overview-block`} style={{opacity: groupOpacity}}>
        <span className={`zol-short-term-overview-title`} onClick={openGcm}>
            {items?.length || getText('st-outome-overview-count-empty')} {title}
        </span>
        <div className={'zol-short-term-overview-items'}>
            {items.map((item) => {
                const GcmIcon = gcmMapping[item.id];
                const opacity = hasGcmFilter && ! state.gcm.includes(item.id) ? 0.4 : 1;
                return <div key={item.id} className={'zol-st-overview-gcm'} style={{opacity}}>
                    <span onMouseOut={(e) => setTooltip(e, 'gcm', null)}
                            onMouseMove={(e) => setTooltip(e, 'gcm', item)}
                            onClick={openGcm}
                            className={'icon'}>
                        <GcmIcon style={{width: 40, height: 40}}/>
                    </span>
                </div>;
            })}
        </div>
    </div>;
};

OverviewGcm.propTypes = {
    shortTermId: PropTypes.number.isRequired
};

export {OverviewGcm};
