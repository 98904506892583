import './index.scss';
import React, {useState} from 'react';
import {DownOutlined, UpOutlined} from '@ant-design/icons';
import {useTranslation} from '../../helpers/use_translation.js';
import {useStateContext} from '../../helpers/state-context.js';

const Feedback = function(){
    const {getText} = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);
    const [{hover}] = useStateContext(['hover']);
    const opacity = hover ? 0.2 : 1;
    const ButtonIcon = modalVisible ? DownOutlined : UpOutlined;
    return <div className={`zol-feedback-container pdf-export-hide`} style={{opacity}}>
        <div className={`zol-feedback ${((modalVisible) ? 'zol-feedback-open' : '')}`}>
            <div className={'zol-feedback-button'}
                    onClick={() => setModalVisible((prev) => !prev)}>
                {getText('feedback-title')}
                <ButtonIcon className={'feedback-icon'}/>
            </div>
            <div className={'zol-feedback-content'}>
                For more information on the updated SRF Structure, please visit the dedicated&nbsp;
                <a href="https://iomint.sharepoint.com/sites/RBM-Shared-Space" target="_blank"
                        rel="noreferrer" style={{color: '#0033a0'}} >
                    SharePoint page
                </a>
                <br/><br/>
                {getText('feedback-text')}
                <br/><br/>
                <a style={{color: '#0033a0'}} href={'mailto:RBM@iom.int'}>RBM@iom.int</a>
            </div>
        </div>
    </div>;
};

export {Feedback};
