import React from 'react';
import {AdminCcpList} from '../../components/admin/ccp/list.js';
import {ModelConsumer} from '../../helpers/model/model-consumer.js';
import {useStateContext} from '../../helpers/state-context.js';
import {AdminCcpNew} from '../../components/admin/ccp/new.js';
import {AdminCcpEditLinks} from '../../components/admin/ccp/edit_links.js';
import {list as resultList} from '../../components/admin/ccp/level_mapping.js';

const AdminCcp = function(props){
    const [state] = useStateContext(['type', 'subpage', 'id', 'category']);
    if(state.subpage === 'new'){
        return <>
            <AdminCcpList/>
            <AdminCcpNew/>
        </>;
    }else if(state.subpage === 'edit-link'){
        return <>
            <AdminCcpList/>
            <AdminCcpEditLinks/>
        </>;
    }else if(state.subpage === 'edit' && state.id !== null){
        const selected = resultList.find((item) => item.id === state.category);
        return <>
            <AdminCcpList/>
            <ModelConsumer model={selected.editModel || selected.model} selectionCriteria={{id: state.id}}>
                {(resultSet) => <AdminCcpNew initialValues={resultSet.data[0]}/>}
            </ModelConsumer>
        </>;
    }
    return <AdminCcpList/>;
};

export {AdminCcp};
