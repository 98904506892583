import './select-grouped.scss';
import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';

const Option = function(props){
    const [isGroupOpen, setIsGroupOpen] = useState(false);

    useEffect(() => {
        setIsGroupOpen(props.searchQuery.length > 0);
    }, [props.searchQuery]);
    const toggleGroup = (event) => {
        event.stopPropagation();
        setIsGroupOpen(! isGroupOpen);
    };

    const toggleOption = (event) => {
        event.stopPropagation();
        if(props.isOpenOnTitleClick && props.isGroup && props.children){
            if(! event.target.className.includes('checkbox')){
                toggleGroup(event);
                return;
            }
        }
        props.onChange(props.isChecked ? null : props.value);
    };

    const checkboxState = props.isChecked ? 'checked' : (props.isPartlyChecked ? 'partly' : 'empty');
    const classDisabled = props.isEnabled ? '' : ' zol-disabled';

    return <li className="zol-select-grouped-option" onClick={toggleOption}>
        {props.isGroup && props.children ?
            <span className={'zol-select-grouped-group-toggle' + (isGroupOpen ? ' zol-active' : '')}
                    onClick={toggleGroup}>
                {isGroupOpen ? '-' : '+'}
            </span> :
            null
        }
        <span className={'zol-select-grouped-checkbox zol-checkbox-' + checkboxState + classDisabled}/>
        <span className="zol-select-grouped-option-label">{props.label}</span>
        {isGroupOpen ? props.children : null}
    </li>;
};

Option.defaultProps = {
    isGroup: false,
    isEnabled: false,
    isPartlyChecked: false,
    searchQuery: '',
    isOpenOnTitleClick: false
};

Option.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    isGroup: PropTypes.bool,
    isEnabled: PropTypes.bool,
    isPartlyChecked: PropTypes.bool,
    searchQuery: PropTypes.string,
    isOpenOnTitleClick: PropTypes.bool
};

export {Option};
