import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';

class ModelSrfRpRegion extends ModelAuthenticatedXhr {
    static modelName = 'ModelSrfRpRegion';

    static getSelect(input){
        return input;
    }

    static createItem(data, onSuccess = () => {}){
        this.saveData(ModelSrfRpRegion, CONFIG.api_endpoint + 'srf-rp-region', 'POST', data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static updateItem(id, data, onSuccess = () => {}){
        this.saveData(ModelSrfRpRegion, CONFIG.api_endpoint + 'srf-rp-region/' + id, 'PATCH', data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static deleteItem(id, onSuccess = () => {}){
        this.saveData(ModelSrfRpRegion, CONFIG.api_endpoint + 'srf-rp-region/' + id, 'DELETE', null,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                });
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf-rp-region',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getEndpoint(){
        return this.endpoint + (this.select.id ? '/' + this.select.id : '');
    }

    getPayload(select){
        return null;
    }

    getSelect(input){
        return ModelSrfRpRegion.getSelect(input);
    }
}

export {ModelSrfRpRegion};
