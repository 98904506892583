import {useStateContext} from '../../../helpers/state-context.js';
import {useLinkFilter} from '../../../helpers/srf/use_link_filter.js';

const useSegmentColor = (data, colors) => {
    const filter = useLinkFilter();
    const [state] = useStateContext();

    const getSegmentColor = (item, isLevel1, frozen, has2Levels, type) => {
        const colorId = has2Levels ?
            data.findIndex((it) => it.data.objective_id === item.data.objective_id) :
            item.index;
        let opacity = '.4';
        // Handle opacity when hovering over an objective or lt_outcome;
        if(state.hover !== null && !frozen){
            // Hover opacity;
            if((!has2Levels || (state.hover?.type === 'objectives')) &&
                    (
                        (item.data.objective_id && state.hover?.id === item.data.objective_id) ||
                        (! item.data.objective_id && state.hover?.id === item.data.id)
                    )){
                opacity = '1';
            }else if((state.hover?.type === 'lt_outcome') &&
                ((isLevel1 && item.children?.some((child) => state.hover.id === child.data.id)) ||
                    (!isLevel1 && state.hover.id === item.data.id))){
                opacity = '1';
            }
        }
        // Handle opacity when 'lt_id' or 'priority' is selected
        if(state[type] !== null && !frozen &&
            // if has 2 levels and it's child is selected
            ((isLevel1 && item.children
                    ?.some((child) => parseInt(state[type]) === child.data.id)) ||
                // if you have 2 levels and level2-item is selected
                (!isLevel1 && parseInt(state[type]) === item.data.id) ||
                // if only 1 level and item is selected
                (isLevel1 && !has2Levels && parseInt(state[type]) === item.data.id))){
            // Selected item opacity;
            opacity = '1';
        }else if(!isLevel1 && (frozen || (state[type] === null && state.hover === null))){
            // if frozen is true, user cannot hover over circle and hence colour should remain the same
            opacity = '.7';
        }else if(isLevel1 && (frozen || (state[type] === null && state.hover === null))){
            opacity = '1';
        }

        // Handle opacity when there is a value selected inside the filter;
        if(filter.hasValue){
            opacity = '0.4';
            if(isLevel1 &&
                    filter.links.some((link) => (
                        link.item_type === 'srf_objective' && item.data.objective_id === link.fk_item_id
                    ))){
                opacity = '1';
            }else if(! isLevel1 &&
                    filter.links.some((link) => (
                        link.item_type === 'srf_lt_outcome' && item.data.id === link.fk_item_id
                    ))){
                opacity = '1';
            }
        }
        return {
            color: 'rgba(' + colors[colorId].join(',') + ', ' + opacity + ')',
            opacity
        };
    };

    return {getSegmentColor};
};

export {useSegmentColor};
