import React from 'react';
import {Tooltip} from '../elements/tooltip.js';
import styles from '../../assets/variables.module.scss';

const styleList = {
    margin: 0,
    padding: '0px 20px'
};

const styleListItem = {
    margin: '5px 0px',
    padding: '5px 0px',
    borderBottom: '1px solid ' + styles.colorOffWhite,
    color: styles.colorPrimary
};

// TODO: need to make this into a component or hook and load the text from useTranslation
const setTooltip = (event, type, hoveredItem, isMobile = false) => {
    if(hoveredItem === null){
        return Tooltip.getInstance().setState({});
    }
    let text;

    if(type === 'sdg' && hoveredItem?.length > 0){
        const sdg = hoveredItem[0];
        text = <>
            {sdg.sdg_goal ? <strong>{sdg.sdg_goal}</strong> : null}
            {isMobile || true ?
                <div>Targets {hoveredItem.map((item) => (item.sdg_target_num)).join(', ')}</div> :
                <ul style={styleList}>
                    {hoveredItem.map((item, key) => {
                        const title = item.sdg_target_num + ': ' + (item.title || item.sdg_target);
                        const truncateTitle = title.length > 128 ?
                                title.substring(0, 128) + '...' :
                                title;
                        return <li key={key} style={styleListItem}>
                            <span style={{color: 'black'}}>{truncateTitle}</span>
                        </li>;
                    })}
                </ul>
            }
        </>;
    }else if(type === 'gcm'){
        const gcm = hoveredItem;
        text = <>
            <b>GCM Objective {gcm.id}</b>
            {gcm.title ?
                <ul style={styleList}>
                    <li>{gcm.title}</li>
                </ul> :
            null
            }
        </>;
    }else if(type === 'rp'){
        const rpAmount = hoveredItem.children.length;
        text = <>
            <b>
                {hoveredItem.children.length}
                {' Regional Priorit'}{rpAmount === 1 ? 'y' : 'ies'} - {hoveredItem.title}
            </b>
            {(hoveredItem?.children?.length > 0) ?
                <ul style={styleList}>
                    {hoveredItem.children.map((child, key) => {
                        const truncateTitle = child.title.length > 128 ?
                                child.title.substring(0, 128) + '...' :
                                child.title;
                        return <li key={key} style={styleListItem}>
                            <span style={{color: 'black'}}>{child.num || truncateTitle}</span>
                        </li>;
                    })}
                </ul> :
                null
            }

        </>;
    }else if(type === 'output'){
        text = <>
            <b>Output - {hoveredItem.num}</b>
            <p style={{margin: 0}}>{hoveredItem.short_title || hoveredItem.title}</p>
        </>;
    }

    const side = event.pageX > 0.5 * event.view.innerWidth ? 'right' : 'left';
    return Tooltip.getInstance().setState({
        position: isMobile ?
            [event.pageX, event.pageY] :
            [event.pageX + 50 * (side === 'right' ? -1 : 1), event.pageY],
        text,
        anchor: isMobile ? 'bottom' : side
    });
};

export {setTooltip};
