import './index.scss';
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {UISwitch} from '../ui/switch/switch.js';
import {ItemBlock} from '../item-block/index.js';
import {Output} from '../output/index.js';
import {useStateContext} from '../../helpers/state-context.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelSrf} from '../../model/srf.js';
import {Tooltip} from '../../helpers/elements/tooltip.js';
import {LinkedRegionalPriority} from '../linked/regional-priority.js';
import {LinkedGcm} from '../linked/gcm.js';
import {LinkedSdg} from '../linked/sdg.js';
import {ShortTermOutcomeNavigation} from './navigation.js';
import {IndicatorLine} from '../item-block/indicator-line.js';
import {ShortTermIndicator} from '../short-term-indicator/index.js';
import {useTranslation} from '../../helpers/use_translation.js';
import {ShortTermOutcomeMobile} from './mobile/index.js';

const ShortTermOutcome = function(props){
    const {isMobileOrTablet} = props;
    const [state, dispatch] = useStateContext();
    const {getText} = useTranslation();
    const options = [
        {
            value: 'st_outcome_indicator',
            label: getText('filter-st-outcome-indicator-many-short')
        },
        {
            value: 'output',
            label: getText('filter-output-many')
        },
        // {
        //     value: 'rp',
        //     label: getText('filter-rp-many')
        // },
        {
            value: 'sdg',
            label: getText('filter-sdg-many')
        },
        {
            value: 'gcm',
            label: getText('filter-gcm-many')
        }
    ];
    const resultSetSelectedItem = useModelFeedback(ModelSrf,
            {select: 'st_outcome_id as id, st_outcome_num as num, st_outcome_short_title as short_title, ' +
                'st_outcome_title as title, lt_outcome_title, lt_outcome_num',
            filter: 'st_outcome_id eq ' + state.st_id,
            language: state.language});
    const resultSetStOutcomes = useModelFeedback(ModelSrf,
            {select: 'st_outcome_id as id, st_outcome_num as num',
                filter: 'lt_outcome_id eq ' + state.lt_id,
                language: state.language});
    useEffect(() => {
        // Remove tooltip incase there was one active from LT-outcome page.
        Tooltip.getInstance().setState({});
    }, []);

    const exportStyle = (state.exportImage) ?
        {maxWidth: 'calc(100% - 10px)'} :
        {};

    const stOutcomeData = resultSetStOutcomes.feedback ? [] : resultSetStOutcomes.data;
    const selectedItem = resultSetSelectedItem.feedback ? {id: 1, num: '1'} : resultSetSelectedItem.data[0];

    return <>
        <div className="zol-details-short-term-navigation">
            <ShortTermOutcomeNavigation type={'back'}/>
            {/* TODO: Use css property text-transform: uppercase; instead of typing it in app caps */}
            <IndicatorLine boxes={selectedItem}
                    stOutcomes={stOutcomeData}
                    title={getText('filter-st-outcome-many')}/>
            <ShortTermOutcomeNavigation type={'nav'} stOutcomes={stOutcomeData}/>
        </div>
        <div className="details-short-term" style={exportStyle}>
            {isMobileOrTablet ?
                    <ShortTermOutcomeMobile item={selectedItem}/> :
                    <ItemBlock style={{marginTop: '-5px'}}
                            title={getText('filter-st-outcome-one') + ' ' + selectedItem.num}
                            type={'st-outcome'}
                            text={selectedItem.title}>
                        <div className={'details-short-term-content'}>
                            <UISwitch className="st-outcome-switch"
                                    options={options || []}
                                    value={state.topic}
                                    onChange={(val) => dispatch({type: 'topic', value: val})}/>
                            {state.topic === 'st_outcome_indicator' ? <ShortTermIndicator/> : null}
                            {state.topic === 'output' ? <Output/> : null}
                            {state.topic === 'rp' ? <LinkedRegionalPriority/> : null}
                            {state.topic === 'sdg' ? <LinkedSdg/> : null}
                            {state.topic === 'gcm' ? <LinkedGcm/> : null}
                        </div>
                    </ItemBlock>
            }
        </div>
    </>;
};

ShortTermOutcome.propTypes = {
    options: PropTypes.array,
    selectedItem: PropTypes.object,
    isMobileOrTablet: PropTypes.bool
};

ShortTermOutcome.defaultProps = {
    isMobileOrTablet: false
};

export {ShortTermOutcome};
