import {getHash} from '../functions/get_hash.js';
import {stringify} from '../functions/stringify.js';
import {deepFreeze} from '../functions/deep_freeze.js';

class Model {
    static Status = {
        INACTIVE: 'inactive',
        WAITING: 'waiting',
        FAILED: 'failed',
        SUCCESS: 'success'
    };

    static modelName = null;
    static instances = {};
    static cache = new Map();
    static cacheIds = [];

    static instancesToRemove = {};
    static removeCycleTimerId = null;

    static saveRequestQue = new Map();

    static getExistingInstance(ModelClass, selectionCriteria){
        const select = ModelClass.getSelect({...selectionCriteria});
        const hash = getHash(stringify(select));
        const className = ModelClass.modelName;

        if(className === null){
            console.warn('Please provide a static name property for the class ' + ModelClass.name);
        }
        const id = className + '.' + hash;
        // this model is stage for removal, however we need it again, so we keep it
        if(Model.instancesToRemove[id]){
            delete Model.instancesToRemove[id];
        }

        let instance = null;
        if(Model.instances[className] && Model.instances[className][hash]){
            instance = Model.instances[className][hash];
        }

        return {hash, instance};
    }

    static addConsumer(ModelClass, selectionCriteria, callback){
        let {hash, instance} = Model.getExistingInstance(ModelClass, selectionCriteria);

        if(instance === null){
            const className = ModelClass.modelName;
            if(! Model.instances[className]){
                Model.instances[className] = {};
            }

            instance = new ModelClass({...selectionCriteria});
            instance.setModelName(className);
            Model.instances[className][hash] = instance;
        }

        callback(instance);

        return {
            instanceId: hash,
            listenerId: instance.addListener(() => (callback(instance)))
        };
    }

    static removeConsumer(ModelClass, instanceId, listenerId){
        const className = ModelClass.modelName;
        if(! Model.instances[className] || ! Model.instances[className][instanceId]){
            console.warn('Trying to remove non-existing dependency from model');
            return;
        }

        const instance = Model.instances[className][instanceId];
        instance.removeListener(listenerId);
        if(! instance.isActive()){
            Model.stageForRemoval(className, instanceId);
        }
    }

    // We don't remove the model instance right away. We might need it again, so we stage is for removal
    static stageForRemoval(className, instanceId){
        const id = className + '.' + instanceId;
        if(! Model.instancesToRemove[id]){
            Model.instancesToRemove[id] = (new Date()).getTime();
        }
        if(! Model.removeCycleTimerId){
            Model.removeCycleTimerId = setInterval(Model.removeInstances, 1000);
        }
    }

    // every two second we clear model instances that are no longer needed.
    static removeInstances(){
        const thresshold = (new Date()).getTime() - 2000;
        for(const id of Object.keys(Model.instancesToRemove)){
            if(Model.instancesToRemove[id] < thresshold){
                const parts = id.split('.');
                const className = parts[0];
                const instanceId = parts[1];
                delete Model.instancesToRemove[id];
                Model.instances[className][instanceId].remove();
                delete Model.instances[className][instanceId];
            }
        }
    }

    static invalidateAllInstances(modelClasses){
        if(! (modelClasses instanceof Array)){
            modelClasses = [modelClasses];
        }
        for(const ModelClass of modelClasses){
            const className = ModelClass.modelName;
            // flush the cache
            Model.flushCache(className, 0);
            // see if there are active instances and clear them out, so they will reload
            if(! Model.instances[className]){
                continue;
            }
            for(const hash of Object.keys(Model.instances[className])){
                const instance = Model.instances[className][hash];
                instance.invalidate();
            }
        }
    }

    /**
     * Return object with select criteria, for example extracted
     * from the state object
     */
    static getSelect(input){
        return {};
    }

    static saveData(ModelClass, payload, callback = () => {}){

    }

    static flushCache(modelName, keepLimit = false){
        if(keepLimit === false){
            keepLimit = this.cacheLimit;
        }
        let count = 0;
        const removeEntries = [];
        for(let i = Model.cacheIds.length - 1; i >= 0; i--){
            const id = Model.cacheIds[i];
            if(id[0] === modelName){
                if(count >= keepLimit){
                    removeEntries.push([i, id]);
                }
                count++;
            }
        }
        for(const entry of removeEntries){
            Model.cacheIds.splice(entry[0], 1);
            Model.cache.delete(entry[1].join('.'));
        }
    }

    static flushAllMemory(){
        // Invalidate all instances
        for(const modelInstances of Object.values(Model.instances)){
            for(const modelInstance of Object.values(modelInstances)){
                modelInstance.invalidate();
            }
        }
    }

    constructor(selectData){
        if(typeof selectData !== 'object'){
            console.error('Model initiated without selectData object. Make sure add selectData in the' +
                    'the constructor argument of your model and make sure to pass it to the super class.');
        }

        this.name = this.modelName + Math.round(999999 * Math.random());

        this.status = Model.Status.INACTIVE; // inactive, waiting, failed, success
        this.error = '';

        this.selectData = selectData;

        // object holding information about the data (what is it), for
        // example the request parameters, but can also describe the
        // amount of returned results etc
        this.meta = null;

        // data itself
        this.data = null;

        // object containing the select criteria
        this.select = {};
        this.hash = '';

        this.cacheEnabled = true;
        this.cacheLimit = 7;

        this.listenersCurrentId = 0;
        this.listeners = new Map();

        this.lastModified = (new Date()).getTime();
        this.statusBeforeUpdate = Model.Status.INACTIVE;
        this.selectChanged = false;
    }

    setModelName(name){
        this.modelName = name;
    }

    init(){
        // start listening to any dependencies
        this.update();
    }

    setIdle(){
        // stop listening to any dependencies

        // Before we set the status on inactive, however we now keep the status as it is. When the model
        // starts again (init) it can now take off from where it left. Meaning we don't need to load new data
    }

    addListener(callback){
        const doInit = this.listeners.size === 0;

        if(typeof callback !== 'function'){
            console.error('Can not attach callback to ' + this.name + ', because it is not a funtion');
            return;
        }

        this.listenersCurrentId++;
        this.listeners.set(this.listenersCurrentId, callback);

        if(doInit){
            this.init();
        }

        return this.listenersCurrentId;
    }

    removeListener(idOrCallback){
        let result = false;
        if(typeof idOrCallback == 'number'){
            this.listeners.delete(idOrCallback);
            result = idOrCallback;
        }else if(this.listeners && this.listeners.size > 0){
            for(const [id, callback] of this.listeners){
                if(callback === idOrCallback){
                    this.listeners.delete(id);
                    result = id;
                }
            }
        }else{
            console.warn('remove listener from non initialized object ' + this.modelName);
        }

        if(! this.isActive()){
            this.setIdle();
        }

        return result;
    }

    trigger(){
        this.lastModified = (new Date()).getTime();
        if(this.isActive()){
            for(const id of this.listeners.keys()){
                this.applyCallback(id);
            }
        }
    }

    applyCallback(id){
        const callback = this.listeners.get(id);
        if(typeof callback !== 'function'){
            this.removeListener(id);
        }else{
            callback();
        }
    }

    isActive(){
        // if nobody is listening, why bother speaking...
        return this.listeners.size > 0;
    }

    getSelect(input){
        return Model.getSelect(input);
    }

    update(){
        this.statusBeforeUpdate = this.status;

        // no one is listening, don't bother updating
        if(! this.isActive()){
            this.setIdle();
            return;
        }

        // check what we need
        const select = this.getSelect(this.selectData);
        const hash = getHash(stringify(select));

        this.selectChanged = hash !== this.hash;

        // everything is already up-to-date, don't continue the update
        // todo: if status is not success, we might want to retry at some point, only we need to prevent
        // ending up in a loop
        if(! this.selectChanged){
            return;
        }

        this.status = Model.Status.WAITING;
        this.data = null;
        this.meta = null;
        this.error = null;
        this.select = select;
        this.hash = hash;

        // see if we already have the required data cached, when we have
        // it sets the values accordingly
        this.getCache();

        if(this.status === Model.Status.SUCCESS){
            this.trigger();
        }else{
            this.fetchData();
        }
    }

    fetchData(){
        // here comes the logic which data to select
        this.setData({});
    }

    setData(data, meta = null){
        this.data = data;
        this.meta = meta;
        this.status = Model.Status.SUCCESS;

        deepFreeze(this.data);
        deepFreeze(this.meta);

        this.setCache();

        this.trigger(); // calls the listeners
    }

    setError(error = null){
        if(!error){
            error = 'An unknown error occured while loading the data';
        }
        this.error = error;

        this.status = Model.Status.FAILED;

        this.trigger(); // calls the listeners
    }

    getData(){
        return {
            status: this.status,
            meta: this.meta,
            data: this.data,
            error: this.error
        };
    }

    setCache(){
        if(! this.cacheEnabled || this.status !== Model.Status.SUCCESS){
            return;
        }
        const id = this.modelName + '.' + this.hash;
        if(! Model.cache.has(id)){
            Model.cache.set(id, [this.meta, this.data]);
            Model.cacheIds.push([this.modelName, this.hash]);
            Model.flushCache(this.modelName);
        }
    }

    getCache(){
        const id = this.modelName + '.' + this.hash;
        if(this.cacheEnabled && Model.cache.has(id)){
            const data = Model.cache.get(id);
            this.meta = data[0];
            this.data = data[1];
            this.status = Model.Status.SUCCESS;
            return true;
        }
        return false;
    }

    invalidate(){
        // first remove all cache
        if(this.cacheEnabled){
            Model.flushCache(this.modelName, 0);
        }
        // reset the status of the instance
        this.hash = '';
        this.status = Model.Status.INACTIVE;
        this.data = null;
        this.meta = null;
        this.error = null;

        // update the model, so it will fetch new data
        this.update();
    }

    remove(){
        try {
            this.setIdle();

            delete this.data;
            delete this.meta;
        } catch (e){
            console.warn('Error removing model ' + this.modelName + ': ' + e.message);
        }
    }
}

export {Model};
