
import {SimpleEvent} from '../simple-event.js';
import {executeCallback} from '../../helpers/functions/execute_callback.js';

class NetworkXhr {
    constructor(url, options, onSuccess, onError = () => {}){
        this.url = url;
        this.options = options;
        this.onSuccess = onSuccess;
        this.onError = onError;
        this.onFinish = function(){};
        this.event = null;
        this.request = null;
        this.aborted = false;
        this.tries = 0;

        this.start = this.start.bind(this);
    }

    addFinishCallback(callback){
        this.onFinish = callback;
    }

    start(){
        const o = this.options ? this.options : {};
        const req = new XMLHttpRequest();
        const method = o.method || 'get';
        const async = (typeof o.async != 'undefined' ? o.async : true);
        let useXMLHttpRequest = true;
        const authorization = o.authorization || null;
        let params = o.data || null;
        const body = o.body || '';

        if(o.useXMLHttpRequest === false){
            useXMLHttpRequest = false;
        }

        // if(typeof req.withCredentials != 'undefined'){
        //     req.withCredentials = true;
        // }

        this.aborted = false;

        let hdl = function(){};

        try {
            let requrl = this.url;

            let contentType = o.contentType || 'raw';
            if(typeof params === 'object' && contentType === 'json'){
                params = JSON.stringify(params, 4);
            }else if(typeof params === 'object'){
                const p = [];
                for(const key in params){
                    if(Object.prototype.hasOwnProperty.call(params, key)){
                        p.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
                    }
                }
                params = p.join('&');
                contentType = 'params';

                req.queryString = params;
            }

            // if we have a GET or a body, append the parameters to the URL
            if((method.toLowerCase() === 'get' && params) || (body !== '' && params)){
                const pos = requrl.indexOf('?');
                if(pos === -1){
                    requrl += '?';
                }else if(pos < this.url.length - 1){
                    requrl += '&';
                }
                requrl += params;
            }

            if(body !== '' && method.toLowerCase() !== 'get'){
                params = body;
            }

            req.open(method, requrl, async);

            // Set "X-Requested-With" header
            if(useXMLHttpRequest){
                req.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            }

            if(['post', 'put', 'patch'].includes(method.toLowerCase()) && body === ''){
                if(contentType === 'params'){
                    req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                }else if(contentType === 'json'){
                    req.setRequestHeader('Content-Type', 'application/json');
                }else{
                    req.setRequestHeader('Content-Type', 'multipart/form-data');
                }
            }

            if(authorization !== null){
                if(authorization.type === 'Bearer'){
                    req.setRequestHeader('Authorization', 'Bearer ' + authorization.access_token);
                }else{
                    throw new Error('Unsuported Authorization type ' + authorization.type);
                }
            }

            for(const key in o.headers){
                if(o.headers.hasOwnProperty(key)){
                    req.setRequestHeader(key, o.headers[key]);
                }
            }

            hdl = () => {
                if(! this.aborted && req.readyState === 4){
                    if(req.status === 0 && this.tries < 2){
                        this.tries++;
                        // timeout required to make sure this request and event
                        // finishes first before restarting
                        setTimeout(this.start, 50);
                        return;
                    }else if(req.status === 0){
                        executeCallback(this.onError, 'Network error: could not complete request');
                    }
                    if((/^[20]/).test(req.status)){
                        executeCallback(this.onSuccess, req.responseText);
                    }
                    if((/^[45]/).test(req.status)){
                        let message = req.statusText;
                        if(req.responseText){
                            message = req.responseText;
                            try {
                                const result = JSON.parse(req.responseText);
                                if(result.error){
                                    message = result.error;
                                }
                            // Fail silently
                            } catch (e){ }
                        }
                        executeCallback(this.onError, message);
                    }
                    executeCallback(this.onFinish);
                }
            };

            if(this.event != null){
                this.event.remove();
            }
            if(async){
                this.event = new SimpleEvent(req, 'readystatechange', hdl);
            }

            req.send(params);
            this.request = req;
        } catch (e){
            executeCallback(this.onError, [e.message]);
            executeCallback(this.onFinish);
        }

        if(! async){
            hdl();
        }
    }

    abort(){
        this.remove();
    }

    remove(){
        try {
            this.aborted = true;
            this.request.abort();
        } catch (e){
            // fails silently
        }
        try {
            if(this.event != null){
                this.event.remove();
            }
            Object.keys(this).forEach((key) => {
                if(this && this[key]){
                    delete this[key];
                }
            });
        } catch (e){
            console.warn('Error deleting XHR object: ' + e.message);
        }
    }
};

export {NetworkXhr};
