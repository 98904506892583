import {ModelCcp} from '../../../model/ccp.js';
import {ModelCcpOutput} from '../../../model/ccp_output.js';
import {ModelCcpIndicator} from '../../../model/ccp_indicator.js';

export const list = [
    {
        id: 'ccp_priority',
        title: 'Cross-Cutting Priorities',
        model: ModelCcp
    },
    {
        id: 'ccp_output',
        title: 'Outputs',
        model: ModelCcpOutput,
        foreign_key: 'ccp_id'
    },
    {
        id: 'ccp_indicator',
        title: 'Indicators',
        model: ModelCcpIndicator,
        foreign_key: 'output_id'
    }
];
