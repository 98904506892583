import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {UIButton} from '../ui/button/button.js';
import {FileTextOutlined, CaretUpFilled, CaretDownFilled} from '@ant-design/icons';
import {useStateContext} from '../../helpers/state-context.js';
import {FullLayoutChartModal} from '../full-layout-chart/modal.js';
import {useTranslation} from '../../helpers/use_translation.js';
import {CONFIG} from '../../config.js';
import {useClickOutsideElement} from '../../helpers/use_click_outside_element.js';

const ExportButtons = function(props){
    const [state, dispatch] = useStateContext();
    const isMobile = (state.renderSize === 'small');
    const isTablet = (state.renderSize === 'medium');
    const [popupOpen, setPopupOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const {getText, language} = useTranslation();

    const measuredRef = useClickOutsideElement(() => {
        setIsDropdownOpen(false);
    });

    const handleExportPdf = () => {
        dispatch({type: 'exportImage', value: 'zol-export'});
        setIsDropdownOpen(false);
    };
    const handleExportExcel = (type) => {
        window.open(CONFIG.api_endpoint + 'srf-admin/downloads/' + type +
                (language == 'en' ? '' : '_' + language));
        setIsDropdownOpen(false);
    };

    if(isMobile || isTablet){
        return null;
    }
    return <>
        <div style={{opacity: state.hover ? 0.2 : 1}} className="zol-full-layout-buttons pdf-export-hide">
            <div style={{textAlign: 'right'}}>
                {(!state.lt_id && props.enableFullLayout) ?
                    <UIButton className={'zol-primary zol-srf-export'}
                            style={{marginRight: 10}}
                            title={getText('full-layout-open-button-description')}
                            onClick= {() => {setPopupOpen(true);}}>
                        {getText('full-layout-open-button')}
                    </UIButton> : null}

                <div className={'export-button-container' + (isDropdownOpen ? ' zol-active' : '')}>
                    <UIButton className={'zol-primary zol-srf-export'}
                            title={getText('export-button-description')}
                            onClick={() => {if(! isDropdownOpen){setIsDropdownOpen(true);}}}>
                        <FileTextOutlined style={{fontSize: 16, marginRight: 5}}/>
                        {getText('export-button')}
                        {isDropdownOpen ?
                            <CaretDownFilled style={{fontSize: 16, marginLeft: 10, marginRight: -10}}/> :
                            <CaretUpFilled style={{fontSize: 16, marginLeft: 10, marginRight: -10}}/>
                        }
                    </UIButton>
                    {isDropdownOpen ?
                        <div ref={measuredRef}>
                            <span className="export-pdf" onClick={handleExportPdf}>
                                {getText('export-button-pdf')}
                            </span>
                            <span className="export-exel" onClick={() => {handleExportExcel('objectives');}}>
                                {getText('export-button-objectives')}
                            </span>
                            <span className="export-exel" onClick={() => {handleExportExcel('ccp');}}>
                                {getText('export-button-ccp')}
                            </span>
                            <span className="export-exel" onClick={() => {handleExportExcel('oee');}}>
                                {getText('export-button-oee')}
                            </span>
                        </div> :
                        null
                    }
                </div>
            </div>
        </div>
        {popupOpen ? <FullLayoutChartModal setPopupOpen={setPopupOpen}/> : null}
    </>;
};

ExportButtons.propTypes = {
    enableFullLayout: PropTypes.bool.isRequired
};

export {ExportButtons};
