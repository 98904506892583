import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {UISwitchItem} from './item.js';
import './switch.scss';
import {useSize} from '../../../helpers/use_size.js';

/*
    A switch between two or more values. The width of the switch is determined automatically by the widest
    option.
*/
const UISwitch = function(props){
    const [width, setWidth] = useState(20);
    const [maxWidth, setMaxWidth] = useState(0);
    const [switchWidth, elementRef] = useSize((w, h) => (w), 0);
    const [lastSwitchWidth, setLastSwitchWidth] = useState(switchWidth);

    useEffect(() => {
        let timerId = null;
        // if the parent element gets smaller we need to re-evaluate the width of the items
        if(switchWidth < lastSwitchWidth && switchWidth < width * props.options.length){
            setWidth(switchWidth / props.options.length);
            setMaxWidth(switchWidth / props.options.length);
        }else if(switchWidth > lastSwitchWidth){
            setMaxWidth(0);
        }else if(switchWidth < width * props.options.length){
            timerId = setTimeout(() => {
                setWidth(switchWidth / props.options.length);
                setMaxWidth(switchWidth / props.options.length);
            }, 200);
        }
        setLastSwitchWidth(switchWidth);
        return () => {
            clearTimeout(timerId);
        };
    }, [switchWidth, lastSwitchWidth, width]);

    const updateSize = (newWidth) => {
        if(newWidth > width){
            setWidth(newWidth);
        }
    };

    const index = props.options.reduce(
            (index, item, rowNr) => (item.value === props.value ? rowNr : index), 0);

    const margin = 0.5 * (switchWidth - props.options.length * width);
    const styleHighlight = {
        top: 0,
        bottom: 0,
        left: margin + index * width,
        width
    };

    return <div ref={elementRef} className={'zol-ui-switch ' + props.className}>
        <span style={styleHighlight} className="zol-ui-switch-highlight"/>
        {props.options.map((item) => (
            <UISwitchItem key={item.value}
                    isActive={props.value === item.value}
                    minWidth={width}
                    maxWidth={maxWidth}
                    onClick={() => {props.onChange(item.value);}}
                    updateSize={updateSize}>

                {item.label}

            </UISwitchItem>
        ))}
    </div>;
};

UISwitch.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
    })).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

UISwitch.defaultProps = {
    className: ''
};

export {UISwitch};
