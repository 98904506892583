import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {StateContext, getSimpleReducer} from '../../../helpers/state-context.js';
import {UITabsTab, ofTypeUITabsTab} from './tab.js';
import {UITabsHeader} from './header.js';
import {UITabsContent} from './content.js';

const getReducer = function(name, onChange){
    const reducer = getSimpleReducer([name]);
    return (state, action) => {
        let res = true;
        if(action.type === name){
            // return false if you want to cancel switching the new tab
            res = onChange(action.value);
            if(res === false){
                return {...state};
            }
        }
        return reducer(state, action);
    };
};

const UITabs = function(props){
    // set a default name
    const [name, setName] = useState('zol-tabs' + Math.round(99999 * Math.random()));
    // if the props has a new name, set the name
    useEffect(() => {
        if(props.name && props.name !== name){
            setName(props.name);
        }
    }, [props.name, name]);

    const options = {};
    React.Children.forEach(props.children, (tab) => {
        // should normally not happen, but in case it does, simply ignore
        if(! React.isValidElement(tab)){
            return;
        }
        options[tab.props.value] = tab.props.title || tab.props.value;
    });

    // set initial state. Use defaultValue or the first option
    const initialState = {};
    initialState[name] = props.defaultValue || Object.keys(options)[0].name;

    return <StateContext initialState={initialState} reducer={getReducer(name, props.onChange)}>

        <UITabsHeader name={name} options={options} selectedValue={props.value}/>

        <UITabsContent name={name} selectedValue={props.value}>
            {props.children}
        </UITabsContent>

    </StateContext>;
};

UITabs.Tab = UITabsTab;

UITabs.propTypes = {
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    children: ofTypeUITabsTab,
    onChange: PropTypes.func
};

UITabs.defaultProps = {
    onChange: () => {}
};

export {UITabs};
