import React from 'react';
import {PropTypes} from 'prop-types';
import {setTooltip} from '../../../helpers/srf/set_tooltip.js';
import {sdgMapping} from '../../../assets/icons-sdg.js';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {ModelSrfLinkView} from '../../../model/srf-link-view.js';
import {StructuredSet} from '../../../helpers/data-wrangling/structured-set.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {sortFilter} from '../../../helpers/srf/sort_filter.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const OverviewSdg = function(props){
    const {shortTermId} = props;
    const [state, dispatch] = useStateContext(['reg_priorities', 'sdg', 'gcm', 'language']);
    const filterState = {reg_priorities: state.reg_priorities, sdg: state.sdg, gcm: state.gcm};
    const {getText} = useTranslation();

    const resultSetLinks = useModelFeedback(ModelSrfLinkView, {
        select: 'sdg_goal_id, sdg_goal_title, sdg_target_id, sdg_target_title, sdg_target_num',
        filter: `sdg_target_id ne null and item_type eq 'srf_st_outcome' and fk_item_id eq ${shortTermId}`,
        language: state.language
    });

    if(resultSetLinks.feedback){
        return resultSetLinks.feedback;
    }

    if(resultSetLinks.data.length === 0){
        return null;
    }

    const hasFilter = Object.values(filterState).some((values) => (values.length > 0));
    const hasSdgFilter = state.sdg.length > 0;
    const groupOpacity = hasFilter && ! hasSdgFilter ? 0.4 : 1;

    const openSdg = () => {
        dispatch({type: 'setSTOutcome', value: props.shortTermId});
        dispatch({type: 'topic', value: 'sdg'});
    };

    const options = sortFilter(resultSetLinks.data, 'sdg');
    const data = StructuredSet.fromArray(options, {
        sdg_goal_id: 'sdg_goal_id',
        sdg_goal_title: 'sdg_goal_title',
        sdg_targets: [{
            sdg_goal: 'sdg_goal_title',
            sdg_target_id: 'sdg_target_id',
            sdg_target_num: 'sdg_target_num',
            sdg_target: 'sdg_target_title'
        }]
    }).getData();

    const sdgCount = resultSetLinks.data?.length;
    const title = (sdgCount > 1) ?
        getText('filter-sdg-many') :
        getText('filter-sdg-one');
    return <div className={`zol-short-term-overview-block`} style={{opacity: groupOpacity}}>
        <span className={`zol-short-term-overview-title`} onClick={openSdg}>
            {sdgCount || getText('st-outome-overview-count-empty')} {title}
        </span>
        <div className={'zol-short-term-overview-items'}>
            {data.map((item) => {
                const SdgIcon = sdgMapping[item.sdg_goal_id];
                const hasSdg = item.sdg_targets.some(
                        (c) => (state.sdg.includes(c.sdg_target_id)));
                const opacity = hasSdgFilter && ! hasSdg ? 0.4 : 1;
                return <div key={item.sdg_goal_id} className={'zol-st-overview-sdg'} style={{opacity}}>
                    <span onMouseOut={(e) => setTooltip(e, 'sdg', null)}
                            onMouseMove={(e) => setTooltip(e, 'sdg', item.sdg_targets)}
                            onClick={openSdg}>
                        <SdgIcon style={{width: 40}}/>
                    </span>
                    <div>{item.sdg_targets.length}</div>
                </div>;
            })}
        </div>
    </div>;
};

OverviewSdg.propTypes = {
    shortTermId: PropTypes.number.isRequired
};

export {OverviewSdg};
