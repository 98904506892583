import './button.scss';
import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../assets/variables.module.scss';

const UIButton = function(props){
    const condProps = {
        className: 'zol-ui-button pdf-export-hide',
        style: {...props.style},
        onClick: props.onClick
    };
    if(props.className.includes('zol-primary')){
        condProps.style.backgroundColor = styles.colorPrimary;
    }
    if(props.disabled){
        condProps.className += ' zol-ui-button-disabled';
        condProps.onClick = null;
    }
    if(props.className){
        condProps.className += ' ' + props.className;
    }

    return <span {...condProps}>
        {props.children}
    </span>;
};

UIButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object
};

UIButton.defaultProps = {
    className: 'zol-primary',
    onClick: () => {}
};

export {UIButton};
