import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';
import {ModelSrf} from './srf.js';
import {ModelSrfOutput} from './srf_output.js';

let srfOutputModel = null;
ModelSrfOutput.addConsumer(ModelSrfOutput, {}, (model) => {
    if(model.status === ModelSrfOutput.Status.SUCCESS){
        srfOutputModel = model;
    }
});

class ModelSrfIndicator extends ModelAuthenticatedXhr {
    static modelName = 'ModelSrfIndicator';

    static getSelect(input){
        return input;
    }

    static createItem(data, onSuccess = () => {}){
        this.saveData([ModelSrfIndicator, ModelSrf], CONFIG.api_endpoint + 'srf-indicator', 'POST', data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                        // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static updateItem(id, data, onSuccess = () => {}){
        this.saveData([ModelSrfIndicator, ModelSrf], CONFIG.api_endpoint + 'srf-indicator/' + id, 'PATCH',
                data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                        // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static deleteItem(id, onSuccess = () => {}){
        this.saveData([ModelSrfIndicator, ModelSrf], CONFIG.api_endpoint + 'srf-indicator/' + id, 'DELETE',
                null,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                });
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf-indicator',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getEndpoint(){
        return this.endpoint + (this.select.id ? '/' + this.select.id : '');
    }

    getPayload(select){
        return null;
    }

    getSelect(input){
        return ModelSrfIndicator.getSelect(input);
    }

    getDataFromResponse(data){
        try {
            const res = JSON.parse(data);

            const outputs = srfOutputModel?.data?.reduce((obj, item) => {
                if(!obj[item.id]){
                    obj[item.id] = item.num;
                }
                return obj;
            }, {});
            // Add correct output to indicator;
            const test = (res.result === undefined) ?
                res.value || [] :
                res.result.map((item) => {
                    item.parent = outputs[item.fk_srf_output_id];
                    item.parent_id = item.fk_srf_output_id;
                    return {...item};
                });
            return test;
        } catch (e){
            return super.getDataFromResponse(data);
        }
    }
}

export {ModelSrfIndicator};
