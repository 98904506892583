
class Vector2 {
    constructor(x = 0, y = 0){
        this.x = x;
        this.y = y;
    }

    multiply(scalar){
        this.x *= scalar;
        this.y *= scalar;
        return this;
    }

    devide(scalar){
        if(scalar){
            this.x /= scalar;
            this.y /= scalar;
        }else{
            this.x = 0;
            this.y = 0;
        }
        return this;
    }

    add(vector){
        this.x += vector.x;
        this.y += vector.y;
        return this;
    }

    substract(vector){
        this.x -= vector.x;
        this.y -= vector.y;
        return this;
    }

    lengthSq(){
        return Math.pow(this.x, 2) + Math.pow(this.y, 2);
    }

    length(){
        return Math.sqrt(this.lengthSq());
    }

    dotProductArray(vector){
        return (this.x * vector[0] + this.y * vector[1]);
    }

    dotProduct(vector){
        return (this.x * vector.x + this.y * vector.y);
    }

    normalize(){
        return this.devide(this.length());
    }

    copy(){
        return new Vector2(this.x, this.y);
    }
}

export {Vector2};
