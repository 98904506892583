const objectsEqual = function(x, y){
    const jsonOrdered = function(obj){
        return JSON.stringify(obj,
                function(key, value){
                    if(value instanceof Object && !Array.isArray(value)){
                        return Object.keys(value).sort().reduce(function(ret, key){
                            ret[key] = value[key];
                            return ret;
                        }, {});
                    }else if(Array.isArray(value)){
                        value.sort(function(a, b){
                            if(typeof a === 'object' && typeof b === 'object' && a.order && b.order){
                                return a.order - b.order;
                            }
                            return a < b ? -1 : 1;
                        });
                    }
                    return value;
                }
        );
    };

    return jsonOrdered(x) === jsonOrdered(y);
};

export {objectsEqual};
