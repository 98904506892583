import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {useStateContext, StateContext, StateConsumer} from '../state-context.js';
import {getSimpleReducer} from '../state-context.js';

const getInitialStateParams = function(props, urlProps){
    // we save the parsed url parameter in a new property 'parsed'
    const state = {
        'url-state-context': {
            path: urlProps.path.slice(0),
            query: urlProps.query.slice(0),
            hash: urlProps.hash.slice(0),
            parsed: {
                path: urlProps.parsed.path.slice(0),
                query: {...urlProps.parsed.query},
                hash: urlProps.parsed.hash.slice(0)
            }
        }
    };

    // get the parameters to watch in the statecontext
    for(const type of ['path', 'query', 'hash']){
        // load the state values from the URL. Add the parameters to the url-state-context and remove all
        // the parsed values from the parsed object.
        for(let i = 0; i < props[type].length; i++){
            const param = props[type][i];
            state['url-state-context'][type].push(param);
            const parsed = state['url-state-context'].parsed[type];
            if(type === 'query'){
                if(parsed.hasOwnProperty(param)){
                    state[param] = parsed[param];
                    delete parsed[param];
                }else{
                    state[param] = props.initialState[param] || null;
                }
            }else if(parsed.length > 0){
                state[param] = parsed.splice(0, 1)[0];
            }else{
                state[param] = props.initialState[param] || null;
            }
        }
    }
    return state;
};

const getUrlForState = function(nextState){
    // combine the nextState value params with state['url-state-context'] parameters to compile a URL
    // Note that this will ignore any child UrlStateContext nodes. This is expected behavior, they will
    // likely no longer exist once this nodes params have changed.
    const newUrlState = {};
    for(const type of ['path', 'query', 'hash']){
        newUrlState[type] = type === 'query' ? {} : [];
        for(const key of nextState['url-state-context'][type]){
            if(type === 'query'){
                newUrlState.query[key] = nextState[key];
            }else{
                newUrlState[type].push(nextState[key]);
            }
        }
    }

    return newUrlState;
};

const InnerUrlStateContext = function(props){
    const [currentUrlState, dispatchUrl] = useStateContext('url-state-context');

    const initialState = getInitialStateParams({...props}, currentUrlState['url-state-context']);
    const [lastState, setLastState] = useState(initialState);

    const contextStateProps = {
        initialState,
        reducer: (state, action) => {
            if(action.type && action.type === 'updateUrlStateContext'){
                return {...state, ...action.value};
            }

            const stateReducer = props.reducer || getSimpleReducer(Object.keys(initialState));
            const nextState = stateReducer(state, action);
            if(state !== nextState){
                const urlState = getUrlForState(nextState);
                dispatchUrl({type: 'urlPush', value: urlState});
            }
            return nextState;
        }
    };

    return <StateContext {...contextStateProps}>
        <StateConsumer watch={['url-state-context', ...props.path, ...props.query, ...props.hash]}>
            {(_, dispatch) => {
                // compare last state with current initialState. If properties have changed, dispatch a state
                // update. This change is caused by an update of the url.
                const nextInitialState = {};
                for(const param of [...props.path, ...props.query, ...props.hash]){
                    if(lastState[param] !== initialState[param]){
                        nextInitialState[param] = initialState[param];
                    }
                }

                // check if the parse params from the url that haven't been mapped yet have changed. If so, we
                // need to propagate the change down the tree.
                typeloop: for(const type of ['path', 'query', 'hash']){
                    const lastParams = type === 'query' ?
                            Object.keys(lastState['url-state-context'].parsed.query) :
                            lastState['url-state-context'].parsed[type];
                    const curParams = type === 'query' ?
                            Object.keys(initialState['url-state-context'].parsed.query) :
                            initialState['url-state-context'].parsed[type];

                    if(lastParams.length !== curParams.length){
                        nextInitialState['url-state-context'] = initialState['url-state-context'];
                        break;
                    }

                    for(let i = 0; i < lastParams.length; i++){
                        if(lastParams[i] !== curParams[i]){
                            nextInitialState['url-state-context'] = initialState['url-state-context'];
                            break typeloop;
                        }
                    }
                }

                // dispatch all new state values in one go
                if(Object.keys(nextInitialState).length > 0){
                    setTimeout(() => {
                        setLastState(initialState);
                        dispatch({type: 'updateUrlStateContext', value: nextInitialState});
                    }, 0);
                }

                return props.children;
            }}
        </StateConsumer>
    </StateContext>;
};

InnerUrlStateContext.defaultProps = {
    path: [],
    query: [],
    hash: []
};

InnerUrlStateContext.propTypes = {
    reducer: PropTypes.func,
    path: PropTypes.array,
    query: PropTypes.array,
    hash: PropTypes.array
};

export {InnerUrlStateContext};
