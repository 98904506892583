import React from 'react';
import {AdminObjectivesList} from '../../components/admin/objectives/list.js';
import {ModelConsumer} from '../../helpers/model/model-consumer.js';
import {useStateContext} from '../../helpers/state-context.js';
import {AdminObjectivesNew} from '../../components/admin/objectives/new.js';
import {AdminGuidanceNew} from '../../components/admin/objectives/guidance-new.js';
import {AdminObjectivesEditLinks} from '../../components/admin/objectives/edit_links.js';
import {list as objectivesList} from '../../components/admin/objectives/level_mapping.js';

const AdminObjectives = function(props){
    const [state] = useStateContext(['type', 'subpage', 'id', 'category']);
    if(state.subpage === 'new'){
        return <>
            <AdminObjectivesList/>
            <AdminObjectivesNew/>
        </>;
    }else if(state.subpage === 'edit-link'){
        return <>
            <AdminObjectivesList/>
            <AdminObjectivesEditLinks/>
        </>;
    }else if(state.subpage === 'edit' && state.id !== null){
        const selected = objectivesList.find((item) => item.id === state.category);
        return <>
            <AdminObjectivesList/>
            {state.category === 'srf_indicator_guidance' ?
                <AdminGuidanceNew/> :
                <ModelConsumer model={selected.editModel || selected.model}
                        selectionCriteria={{id: state.id}}>
                    {(resultSet) => (
                        <AdminObjectivesNew initialValues={resultSet.data[0]}/>
                    )}
                </ModelConsumer>
            }
        </>;
    }
    return <AdminObjectivesList/>;
};

export {AdminObjectives};
