import React from 'react';
import translationEnglishJSON from '../assets/translations/en.json';
import translationFrenchJSON from '../assets/translations/fr.json';
import translationSpanishJSON from '../assets/translations/es.json';
import {useStateContext} from './state-context.js';
import {useEffect} from 'react';
import {ReactComponent as EnglishIcon} from '../assets/flags/GBR.svg';
import {ReactComponent as FrenchIcon} from '../assets/flags/FRA.svg';
import {ReactComponent as SpanishIcon} from '../assets/flags/ESP.svg';

const languages = {
    en: {
        translation: translationEnglishJSON,
        icon: EnglishIcon,
        label: 'English'
    },
    es: {
        translation: translationSpanishJSON,
        icon: SpanishIcon,
        label: 'Español'
    },
    fr: {
        translation: translationFrenchJSON,
        icon: FrenchIcon,
        label: 'Français'
    }
};

const useTranslation = () => {
    const [state, dispatch] = useStateContext();
    useEffect(() => {
        // reload language from localstorage
        const language = localStorage.getItem('srf_lng');
        if(language && languages[language] && state.language !== language && state.page === null){
            dispatch({type: 'language', value: language});
        }
    }, [state.language]);

    const getLanguages = () => (
        Object.keys(languages).map((languageKey) => {
            const language = languages[languageKey];
            const LanguageIcon = languages[languageKey].icon;
            return {
                label: <span className={'srf-lang-flag'}>
                    <LanguageIcon style={{width: 30, height: 20}}/>
                    <span className={'srf-lang-flag-label'}>{language.label}</span>
                </span>,
                title: language.label,
                value: languageKey
            };
        })
    );

    const setLanguage = (lang) => {
        localStorage.setItem('srf_lng', lang);
        dispatch({type: 'language', value: lang});
    };

    const getText = (key, item, language = null) => {
        // Check if key exists
        language = language || state.language;
        if(! languages[language].translation[key]){
            console.error(`Translation for key: '${key}' doesn't exist in '${language}.json'`);
            return '';
        }
        let text = languages[language].translation[key];
        // handle dynamic variables
        const matches = text.match(/{{(.*?)}}/g);
        if(typeof item === 'object' && matches?.length > 0){
            for(const match of matches){
                const key = match.slice().replace(/{|}/g, '');
                if(item[key]){
                    text = text.replace(match, item[key]);
                }
            }
        }
        return text;
    };

    return {
        language: state.language,
        setLanguage,
        getText,
        getLanguages
    };
};

export {useTranslation};
