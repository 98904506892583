import React from 'react';
import {PropTypes} from 'prop-types';
import {useStateContext} from '../../../helpers/state-context.js';
import {ofTypeUITabsTab} from './tab.js';

const UITabsContent = function(props){
    // get the current value for active tab
    const [state] = useStateContext([props.name]);
    const value = state[props.name];

    let activeTab = null;
    for(const child of props.children){
        // should normally not happen, but in case it does, simply ignore
        if(! React.isValidElement(child)){
            return;
        }

        if(child.props.value === value){
            activeTab = child;
            break;
        }
    }

    return activeTab;
};

UITabsContent.propTypes = {
    name: PropTypes.string.isRequired,
    children: ofTypeUITabsTab
};

export {UITabsContent};
