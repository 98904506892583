import {useStateContext} from '../../helpers/state-context.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelSrfStOutcomeIndicator} from '../../model/srf_st_outcome_indicator.js';

const useIndicatorData = function(){
    const [state] = useStateContext(['st_id', 'language']);
    const lang = state.language === 'en' ? '' : '_' + state.language;
    const resultSet = useModelFeedback(
            ModelSrfStOutcomeIndicator,
            {
                select: 'title' + lang + ' as title, id, num, is_core_kpi, fk_srf_st_outcome_id',
                filter: 'fk_srf_st_outcome_id eq ' + state.st_id,
                order: 'num'
            }
    );

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const data = resultSet.data;
    const dataLength = data.length;
    return {dataLength, data};
};

export {useIndicatorData};
