import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../assets/variables.module.scss';
import {useSize} from '../../../../helpers/use_size.js';
import {getHighlightedText} from '../../../search/get_highlighted_text.js';
import {useStateContext} from '../../../../helpers/state-context.js';
import {DonutAnnotationSdgs} from './sdgs.js';
import {useTranslation} from '../../../../helpers/use_translation.js';
import {FormatString} from '../../../../helpers/format/string.js';

const DonutAnnotation = function(props){
    const [size, measuredRef] = useSize((w) => w, 180);
    const [state] = useStateContext(['searchText', 'rotate', 'hover', 'exportImage']);
    const {getText} = useTranslation();

    if(!props.item || !props.visible){
        return null;
    }
    const rotate = state.rotate * Math.PI / 180;
    const item = {...props.item};
    item.endAngle = props.item.endAngle + rotate;
    item.startAngle = props.item.startAngle + rotate;

    const getPoints = () => {
        const posA = [];
        const posB = [0, 0];

        const radian = ((item.endAngle - item.startAngle) / 2 + item.startAngle);
        // the angle in radians from start angle to middle of selected item in donut
        posA[0] = Math.sin(radian) * props.radius * 1.05;
        // the x-value that starts 5% outside the donut
        posA[1] = Math.cos(radian) * props.radius * - 1.05;
        // the y-value where you do it times -, as the top half
        // of the donut is where the y-coordinates are negative
        posB[0] = Math.sin(radian) * props.radius * 1.1;
        posB[1] = Math.cos(radian) * props.radius * -1.1;

        const width = props.isMobile ?
            0.45 * props.widthScreen :
            Math.min(0.33 * props.widthScreen,
                    posB[0] > 0 ? 0.5 * props.widthScreen - posB[0] : 0.5 * props.widthScreen + posB[0]);

        return [posA, posB, width];
    };

    const [posA, posB, width] = getPoints();
    const fullCircleInRadian = 2 * Math.PI;
    const itemCenterDegree = fullCircleInRadian + (item.startAngle + (item.endAngle - item.startAngle) / 2);
    const midangle = itemCenterDegree % fullCircleInRadian;
    const isRightSide = midangle < Math.PI;
    const isAlignOut = midangle > Math.PI / 6 && midangle < 11 / 6 * Math.PI;
    const isUpperHalf = posA[1] < 0 && posB[1] < 0 && isAlignOut ?
            true : false;

    const maxLines = 3;

    const heightText = 25 * (maxLines + 1) + (state.exportImage ? 50 : 0);
    // lineHeight + bottom or top times number of lines + title line
    const posC = [posB[0] + (size ? size : 180) * (isRightSide ? 1 : -1), posB[1]];

    // line equally long to foreignObject box
    const points = [posA, posB, posC];
    const textPos = [...posB];
    if(!props.isMobile){
        textPos[0] = posB[0] - (isRightSide ? 0 : width);
        textPos[1] = textPos[1] - (isUpperHalf ? heightText : 0);
    }else{
        textPos[0] = isRightSide ? 0 : -props.radius;
        textPos[1] = props.radius * (isUpperHalf ? -1 : 1) - (isUpperHalf ? heightText : 0);
    }
    const textStyle = {
        position: 'absolute',
        lineHeight: '22px',
        bottom: isUpperHalf ? '5px' : null,
        top: isUpperHalf ? null : '5px',
        right: isRightSide ? null : '0px',
        left: isRightSide ? '0px' : null,
        color: styles.colorBlueText,
        overflow: 'hidden', // from overflow to webkitBoxOrient is to add an ellipsis when too much text (...)
        // display: '-webkit-box',
        WebkitLineClamp: state.exportImage ? 'initial' : maxLines.toString(),
        // WebkitBoxOrient: 'vertical',
        width
    };

    const titleStyle = {
        margin: 0,
        fontSize: 14,
        wordWrap: 'wrap',
        textTransform: 'uppercase',
        fontWeight: 800
    };

    const annotationStyle = {
        // When hovering over the donut the annotation causes flickering as it overlaps with the cursor.
        // Keeping it allowed when not hovering over an item so you can hover over the SDGs.
        pointerEvents: (state.hover === null) ? 'inherit' : 'none'
    };

    const getCornerName = () => {
        const vertical = isUpperHalf ? 'top' : 'bottom';
        const horizontal = isRightSide ? 'right' : 'left';
        return vertical + '-' + horizontal;
    };

    return <g className={'donut-annotation'} transform={`rotate(${-state.rotate})`} style={annotationStyle}>
        {props.isMobile ?
            <>
                <line x1={posA[0]} x2={posA[0]} y1={posA[1]}
                        y2={posA[1] + props.radius / 4 * (isUpperHalf ? -1 : 1)}
                        stroke={styles.colorBlue}
                        strokeWidth={2} strokeLinecap='round' strokeDasharray='2 5'/>
                <circle cx={posA[0]} cy={posA[1] + 4 + props.radius / 4 * (isUpperHalf ? -1 : 1)}
                        r={4}
                        style={{fill: styles.colorBlue}}/>
            </> :
            <polyline fill={'none'} stroke={styles.colorBlue} strokeWidth={.5} points={points}/>}
        <foreignObject transform={`translate(${textPos})`}
                width={width}
                height={heightText}
                style={{textAlign: props.isMobile ?
                    (isRightSide ? 'right' : 'left') :
                    (isRightSide ? (isAlignOut ? 'left' : 'right') : (isAlignOut ? 'right' : 'left'))}}>
            <div ref={measuredRef} style={textStyle}>
                <p style={titleStyle}>
                    {(props.type === 'objectives' ?
                            getText('filter-objective-one') : getText('filter-lt-outcome-one')) + ' ' +
                    (props.item.data.num || props.item.data.id)
                    }
                </p>
                {/* TODO: shorten the titles is not functioning ideal, because we cannot rely on the borwser
                    to deal with textOverflow: ellipses correctly. Converting to UISvgText might be a
                    solutions. */}
                <p style={
                        {
                            fontSize: props.isMobile ? 14 : 18,
                            lineHeight: props.isMobile ? '18px' : '22px',
                            margin: 0,
                            height: props.isMobile ?
                                    (isUpperHalf ? '54px' : '70px') :
                                    (isUpperHalf ? '45px' : '70px'),
                            overflow: 'hidden'
                        }}>
                    {getHighlightedText(
                            FormatString.truncate(props.item.data.title, isUpperHalf ? 80 : 140),
                            state.searchText)}
                </p>
            </div>
        </foreignObject>
        {(props.item.children || props.item.data.id) ?
            <foreignObject
                    transform={`translate(${textPos[0]}, ${(textPos[1] + (isUpperHalf ? heightText : -95))})`}
                    width={width}
                    height={heightText}
                    className={`${getCornerName()}`}
                    style={{
                        textAlign: isRightSide ? 'right' : 'left',
                        position: 'relative',
                        height: 100
                    }}>
                <DonutAnnotationSdgs divRef={measuredRef}
                        itemId={props.item.children ? props.item.data.objective_id : props.item.data.id}
                        itemType={props.item.children ? 'srf_objective' : 'srf_lt_outcome'}/>
            </foreignObject> :
            null
        }

    </g>;
};

DonutAnnotation.propTypes = {
    item: PropTypes.object,
    data: PropTypes.array,
    radius: PropTypes.number,
    outerRadius: PropTypes.number,
    visible: PropTypes.bool,
    type: PropTypes.string,
    isMobile: PropTypes.bool,
    widthScreen: PropTypes.number
};

export {DonutAnnotation};
