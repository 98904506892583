import './table.scss';
import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {UITableHeader} from './header.js';
import {useSize} from '../../../helpers/use_size.js';
import {useStickyHeader} from './use_sticky_header.js';
import {useTableScroll} from './use_table_scroll.js';
import {orderBy} from '../../../helpers/data-wrangling/order_by.js';

/*
    TODO: both filter and order should be managed outside of the table component. Often the table
    only reflect a small subset of the total data that is stored on the server and a change in order
    or filter should update a model to fetch new data.
*/
const UITable = function(props){
    const [size, measuredRef] = useSize((w, h) => {return {width: w, height: h};}, {width: 1, height: 1});

    const [orderState, setOrderState] = useState('');
    const order = props.currentOrder ? props.currentOrder : orderState;
    const setOrder = (order) => {
        props.setOrder(order);
        setOrderState(order);
    };

    const data = props.data;
    const [isScrolling, setIsScrolling] = useState(false);

    const [tableElement, setTableElement] = useState(null);
    const setMeasuredRef = (node) => {
        measuredRef(node);
        setTableElement(node);
    };

    const [offsetHeader, offsetContent] = useStickyHeader(tableElement, props.stickyHeader);

    const [scrollButtons, scrollOffset] = useTableScroll(tableElement, isScrolling, size.width, offsetHeader);

    // if the window resizes or the columns change, we need to re-evaluate if we need to scroll
    useEffect(() => {
        const totalWidth = props.columns.reduce((sum, column) => (
            sum + (column.width ? column.width : 1)
        ), 0);
        setIsScrolling(totalWidth > size.width);
    }, [props.columns, size]);

    // get the width of the full table
    const totalWidth = props.columns.reduce(
            (sum, column) => (sum + (column.width ? column.width : 1)), 0);

    if(totalWidth === 0){
        // empty table
        return null;
    }

    // calculate absolute width of each column, scale up to 100% if there's room left
    const columns = props.columns.map((column) => {
        return {
            ...column,
            // If total column width is under the table width we align in percentages so it fills up the whole
            // table width otherwise we use the width assigned
            width: isScrolling ?
                (column.width || 1) :
                Math.round(size.width * (column.width || 1) / totalWidth)
        };
    });

    const tableWidth = columns.reduce((sum, col) => (sum + col.width), 0);

    // Order data when order state is set and no setOrder callback was setup for the table.
    if(order && order.length > 0 && ! props.setOrder){
        const [key, orders] = order.split(' ');
        orderBy(data, key, orders);
    }

    const styleRow = {
        cursor: props.onRowClick ? 'pointer' : 'default',
        ...props.rowStyle
    };

    return <div className={'zol-table-container' + (isScrolling ? ' zol-table-scrollable' : '')}
            style={{position: 'relative', clear: 'both'}} ref={setMeasuredRef}>
        <table className="zol-table" style={{width: tableWidth}}>
            <UITableHeader order={order}
                    setOrder={setOrder}
                    isScrolling={isScrolling}
                    contentHeight={offsetContent}
                    columns={columns}
                    offsetTop={offsetHeader}
                    scrollOffset={scrollOffset}
                    setFilter={props.setFilter}/>

            <tbody className={'zol-table-body'} style={{marginTop: offsetContent + 'px'}}>
                {offsetContent > 0 ?
                    // adding 2 spacers instead of one, so it doesn't mess up the odd/even properties
                    [1, 2].map((i) => (<tr key={'spacer' + i} className="zol-table-body-offset">
                        <td style={{height: 0.5 * offsetContent}} colSpan={columns.length}/>
                    </tr>)) :
                    null
                }

                {data.length > 0 ?
                    data.map((row, index) => (
                        <tr key={index} className={'zol-table-row'} style={styleRow}
                                onClick={() => (props.onRowClick ? props.onRowClick(row) : null)}>
                            {columns.map((column, colIndex) => (
                                <td key={colIndex}
                                        className={'zol-table-cell' +
                                                (column.isFixed ? ' zol-table-cell-fixed' : '')}
                                        style={{
                                            position: column.isFixed ? 'relative' : 'inherit',
                                            left: column.isFixed ? scrollOffset : '',
                                            width: column.width,
                                            wordBreak: 'break-word',
                                            ...column.cellStyle
                                        }}>
                                    {column.renderCell ?
                                        column.renderCell(column.value(row, index), row) :
                                        column.value(row, index)
                                    }
                                </td>
                            ))}

                        </tr>
                    )) :
                    <tr>
                        <td colSpan={columns.length} style={{textAlign: 'center'}}>
                            {props.emptyText}
                        </td>
                    </tr>
                }
            </tbody>

        </table>

        {scrollButtons}

    </div>;
};

UITable.defaultProps = {
    emptyText: 'List is empty.',
    setFilter: (column, filterValue) => {},
    stickyHeader: true
};

UITable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                // the minimum width of the column. When there is more space the width of all columns will be
                // scaled equally. If the total width is larger than available space we get a scrollbar.
                width: PropTypes.number,
                title: PropTypes.string,
                renderTitle: PropTypes.func,
                value: PropTypes.func,
                cellStyle: PropTypes.object,
                renderCell: PropTypes.func,
                // enable the ability to sort the results of the table by values of this column. Provide a
                // function to specify a custom sort component in the header
                sort: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
                filter: PropTypes.bool, // When enable allow filtering results by values in this column
                isFixed: PropTypes.bool, // When enabled fixes the column for horizontal scrolling
                headerStyle: PropTypes.object, // additional styling for the header cell
                // When enabled the headers will rotate, saving some space
                rotateHeader: PropTypes.bool
            })
    ).isRequired,
    emptyText: PropTypes.string,
    rowStyle: PropTypes.object,
    onRowClick: PropTypes.func,
    setFilter: PropTypes.func,
    setOrder: PropTypes.func,
    currentOrder: PropTypes.string,
    stickyHeader: PropTypes.bool
};

export {UITable};
