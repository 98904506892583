import React from 'react';
import {useStateContext} from '../helpers/state-context.js';
import {useTranslation} from '../helpers/use_translation.js';
import {useScrollPosition} from '../helpers/scroll.js';

// This component displays the selected filters at the top of the view.
const FilterOverlay = function(props){
    const [state, dispatch] = useStateContext();
    const {getText} = useTranslation();
    const {y: scrollY} = useScrollPosition();

    const titles = {
        0: getText('filter-gcm-many'),
        1: getText('filter-sdg-many'),
        2: getText('filter-rp-many')
    };
    const isMobileOrTablet = state.renderSize === 'small' || state.renderSize === 'medium';
    const hasFilter = [state.gcm, state.sdg, state.reg_priorities].some((i) => i.length > 0);
    const filters = [state.gcm, state.sdg, state.reg_priorities]
            .reduce((filter, item, key) => {
                if(item.length > 0){
                    return filter + (filter.length > 0 ? ', ' : '') + titles[key];
                }
                return filter;
            }, '');

    const opacity = Math.min(1, scrollY / 120);

    if(hasFilter){
        return <div className={'gradient'} style={{top: isMobileOrTablet ? '130px' : '110px'}}>
            <div>
                {getText('filter-overlay-text', {filters})}
                <span className={'zol-link'}
                        onClick={() => {
                            dispatch({type: 'setValues',
                                value: {
                                    reg_priorities: [],
                                    sdg: [],
                                    gcm: []
                                }});
                        }}>
                    {getText('filter-overlay-clear-button')}
                </span>
            </div>
        </div>;
    }

    return <div className="gradient" style={{opacity}}/>;
};

export {FilterOverlay};
