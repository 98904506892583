import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {App} from './App.js';
import {reportWebVitals} from './reportWebVitals.js';
import {UrlStateContext} from './helpers/url-state-context.js';
import {SizedApp} from './helpers/elements/sized-app.js';
import {StateContext} from './helpers/state-context.js';

// After login we may get a hash attached to the url with the myiom-token-lifetime. This will mess up our
// application UrlStateContext, so we want to remove that.
const getValueFromHash = (name, clear = true) => {
    const hash = window.location.hash;
    const value = hash.match(name + '=') ?
            hash.split(name + '=')[1].split(';')[0] :
            null;
    if(clear && value !== null){
        // cleanup the url
        const expr = new RegExp(name + '=(.*?);', 'g');
        const newHash = hash.replace(expr, '');
        window.location.hash = newHash.length <= 1 ? '' : newHash;
    }
    return value;
};
getValueFromHash('myiom-token-lifetime');
const savedIndicators = (sessionStorage.getItem('srf-saved-indicators') || '')
        .split(',').filter((str) => (!! str)).map((str) => (isNaN(str) ? str : parseInt(str)));

const component = (
    <StateContext initialState={{initItem: null}}>
        <UrlStateContext path={['page']} initialState={{page: 'objectives'}}>
            <SizedApp>
                <StateContext initialState={{savedIndicators, language: 'en'}}>
                    <App/>
                </StateContext>
            </SizedApp>
        </UrlStateContext>
    </StateContext>
);

ReactDOM.render(component, document.getElementById('srf-root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
