import React from 'react';

// JV: function name and file name do not match
// JV: I would make this a hool: useHighlightedText and get the searchText directly from the useStateContext
// inside this hook. That makes usage easier, as you can use do:
// const parsedText = useHighlightedText(rawText);
const getHighlightedText = (text, searchText) => {
    if(text){
        const highlight = searchText;
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {parts.map((part, i) =>
                    part?.toLowerCase() === highlight?.toLowerCase() ? <mark key={i}>{part}</mark> : part
                )}
            </span>
        );
    };
};

export {getHighlightedText};
