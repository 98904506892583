import React from 'react';
import {PropTypes} from 'prop-types';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {ModelSrf} from '../../../model/srf.js';
import {useDonutColor} from '../../../helpers/srf/use_donut_color.js';
import {setTooltip} from '../../../helpers/srf/set_tooltip.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const OverviewOutput = function(props){
    const {objectiveColor} = useDonutColor();
    const [state, dispatch] = useStateContext();
    const {getText} = useTranslation();

    const resultSet = useModelFeedback(ModelSrf, {
        select: 'output_num as num, output_title as title, output_short_title as short_title',
        filter: 'st_outcome_id eq ' + props.shortTermId,
        order: 'num',
        language: state.language
    });

    if(resultSet.feedback){
        return resultSet.feedback;
    };

    const openOutput = (item) => {
        dispatch({type: 'setSTOutcome', value: props.shortTermId});
        if(item){
            dispatch({type: 'output', value: item.num});
        }
        dispatch({type: 'topic', value: 'output'});
    };

    const outputCount = resultSet.data?.length;
    const title = (outputCount > 1) ?
        getText('filter-output-many') :
        getText('filter-output-one');
    return <div className={`zol-short-term-overview-block`}>
        <span className={`zol-short-term-overview-title ${(outputCount === 0 ? '-inactive' : '')}`}
                onClick={() => {openOutput();}}>
            {outputCount || getText('st-outome-overview-count-empty')} {title}
        </span>
        <div className={'zol-short-term-overview-items'}>
            {resultSet.data.map((item, key) => (
                    <span key={key}
                            className={'zol-st-overview-output'}
                            onMouseMove={(e) => setTooltip(e, 'output', item)}
                            onMouseOut={(e) => setTooltip(e, 'output', null)}
                            onClick={() => {openOutput(item);}}
                            style={{
                                // JV: use the CSS file, objective colour can also be there when we add a
                                // the LongTermOutcome component
                                backgroundColor: `rgb(${objectiveColor?.join(',')})` || '#67c5ea'
                            }}>
                        {item.num}
                    </span>
            ))}
        </div>
    </div>;
};

OverviewOutput.propTypes = {
    shortTermId: PropTypes.number
};

export {OverviewOutput};
