import React from 'react';
import {useTranslation} from '../helpers/use_translation.js';

const LanguagesSelection = function(props){
    const {language, getLanguages, setLanguage} = useTranslation();
    const languages = getLanguages();
    return <div className={'srf-header-languages'}>
        {languages.map((lan, key) => (
            <React.Fragment key={key}>
                <span onClick={() => setLanguage(lan.value)}
                        className={'language-title' + (language === lan.value ? ' language-active' : '')}>
                    {lan.value}
                </span>
                <span className={'language-separator'}>{(key < languages.length - 1) ? '|' : ''}</span>
            </React.Fragment>
        ))}
    </div>;
};

export {LanguagesSelection};
