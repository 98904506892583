import React from 'react';
import PropTypes from 'prop-types';
import './item-block.scss';
import {useStateContext} from '../../helpers/state-context.js';
import {getHighlightedText} from '../search/get_highlighted_text.js';
import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import {useDonutColor} from '../../helpers/srf/use_donut_color.js';
import {useTranslation} from '../../helpers/use_translation.js';

const iconStyle = {
    float: 'right',
    marginRight: 30,
    fontSize: 20
};

const ItemBlock = function(props){
    const [state] = useStateContext(['output', 'searchText', 'exportImage']);
    const {donutColor} = useDonutColor();
    const {getText} = useTranslation();

    const exportWidth = props.type === 'st-outcome' ? '100%' : '50%';
    const exportStyle = (state.exportImage) ?
            {
                width: `calc(${exportWidth} - 102px)`,
                maxWidth: '100%',
                border: props.type !== 'oee' ? '.5px solid grey' : 'none',
                margin: props.type !== 'oee' ? '12.5px 25px' : 0,
                display: props.type === 'st-outcome' ? 'block' : 'inline-block'
            } :
            {display: 'inline-block'};

    if(props.type === 'short-term-mobile-overview'){
        return <div className={'zol-short-term-overview-mobile-item' +
                (props.className ? props.className : '')}
                style={{...props.style}}
                onClick={() => props.onClick()}>
            <div className={'block-title-box'}>
                <div className={'block-title'}>
                    {props.title ?
                        <span style={{color: `rgb(${donutColor?.join(',')})`}}>
                            {getHighlightedText(
                                    getText('filter-st-outcome-one') + ' ' + props.title,
                                    state.searchText
                            )}
                        </span> :
                        null
                    }
                    <p style={{margin: '5px 0'}}>
                        {typeof props.text === 'string' ?
                            getHighlightedText(props.text, state.searchText) :
                            props.text
                        }
                    </p>
                </div>
                <div className={'details-button'}>
                    {props.details}
                </div>
            </div>
            {props.children}
        </div>;
    }
    return <div className={'zol-item-component'}
            id={props.type === 'output' ? 'zol-item-output' : null}
            style={{...props.style, ...exportStyle}}
            onClick={() => props.onClick()}>
        <div className="zol-item-block">
            {props.title ?
                <span className={'block-title ' + (props.expanded !== null ? ' block-expandable' : '')}
                        onClick={() => {
                            if(props.onExpand){
                                return props.onExpand();
                            }
                        }}
                        style={{
                            backgroundColor: `rgb(${donutColor?.join(',')})`
                        }}>
                    {getHighlightedText(props.title, state.searchText)}
                    {props.expanded === null ? null : props.expanded ?
                        <CaretUpOutlined style={iconStyle}/> :
                        <CaretDownOutlined style={iconStyle}/>
                    }
                </span> :
                null
            }
            {(props.expanded !== null ? props.expanded : true) ?
                <div className={'block-text' + (props.expanded !== null ? ' block-expandable' : '')}
                        style={props.textBlockStyle}>
                    <p style={{margin: '5px 0'}}>
                        {typeof props.text === 'string' ?
                            getHighlightedText(props.text, state.searchText) :
                            props.text
                        }
                    </p>
                    {props.details}
                </div> :
                null
            }
        </div>
        {props.children}
    </div>;
};

ItemBlock.propTypes = {
    details: PropTypes.element,
    title: PropTypes.string,
    expanded: PropTypes.bool,
    onExpand: PropTypes.func,
    type: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    textBlockStyle: PropTypes.object,
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

ItemBlock.defaultProps = {
    expanded: null,
    item: {
        title: 'Title',
        description: 'Description'
    },
    style: {width: '100%'},
    onClick: (v) => {},
    type: ''
};

export {ItemBlock};
