import {ReactComponent as IconGcm1} from './gcms/gcm1.svg';
import {ReactComponent as IconGcm2} from './gcms/gcm2.svg';
import {ReactComponent as IconGcm3} from './gcms/gcm3.svg';
import {ReactComponent as IconGcm4} from './gcms/gcm4.svg';
import {ReactComponent as IconGcm5} from './gcms/gcm5.svg';
import {ReactComponent as IconGcm6} from './gcms/gcm6.svg';
import {ReactComponent as IconGcm7} from './gcms/gcm7.svg';
import {ReactComponent as IconGcm8} from './gcms/gcm8.svg';
import {ReactComponent as IconGcm9} from './gcms/gcm9.svg';
import {ReactComponent as IconGcm10} from './gcms/gcm10.svg';
import {ReactComponent as IconGcm11} from './gcms/gcm11.svg';
import {ReactComponent as IconGcm12} from './gcms/gcm12.svg';
import {ReactComponent as IconGcm13} from './gcms/gcm13.svg';
import {ReactComponent as IconGcm14} from './gcms/gcm14.svg';
import {ReactComponent as IconGcm15} from './gcms/gcm15.svg';
import {ReactComponent as IconGcm16} from './gcms/gcm16.svg';
import {ReactComponent as IconGcm17} from './gcms/gcm17.svg';
import {ReactComponent as IconGcm18} from './gcms/gcm18.svg';
import {ReactComponent as IconGcm19} from './gcms/gcm19.svg';
import {ReactComponent as IconGcm20} from './gcms/gcm20.svg';
import {ReactComponent as IconGcm21} from './gcms/gcm21.svg';
import {ReactComponent as IconGcm22} from './gcms/gcm22.svg';
import {ReactComponent as IconGcm23} from './gcms/gcm23.svg';

export const gcmMapping = {
    1: IconGcm1,
    2: IconGcm2,
    3: IconGcm3,
    4: IconGcm4,
    5: IconGcm5,
    6: IconGcm6,
    7: IconGcm7,
    8: IconGcm8,
    9: IconGcm9,
    10: IconGcm10,
    11: IconGcm11,
    12: IconGcm12,
    13: IconGcm13,
    14: IconGcm14,
    15: IconGcm15,
    16: IconGcm16,
    17: IconGcm17,
    18: IconGcm18,
    19: IconGcm19,
    20: IconGcm20,
    21: IconGcm21,
    22: IconGcm22,
    23: IconGcm23
};
