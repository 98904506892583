import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';
import {ModelSrfRpRegion} from './srf_rp_region.js';

let srfRpRegionModel = null;
ModelSrfRpRegion.addConsumer(ModelSrfRpRegion, {}, (model) => {
    if(model.status === ModelSrfRpRegion.Status.SUCCESS){
        srfRpRegionModel = model;
    }
});

class ModelSrfRpPriority extends ModelAuthenticatedXhr {
    static modelName = 'ModelSrfRpPriority';

    static getSelect(input){
        return input;
    }

    static createItem(data, onSuccess = () => {}){
        this.saveData(ModelSrfRpPriority, CONFIG.api_endpoint + 'srf-rp-priority', 'POST', data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static updateItem(id, data, onSuccess = () => {}){
        this.saveData(ModelSrfRpPriority, CONFIG.api_endpoint + 'srf-rp-priority/' + id, 'PATCH', data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static deleteItem(id, onSuccess = () => {}){
        this.saveData(ModelSrfRpPriority, CONFIG.api_endpoint + 'srf-rp-priority/' + id, 'DELETE', null,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                });
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf-rp-priority',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getDataFromResponse(data){
        try {
            const response = JSON.parse(data);
            const regions = srfRpRegionModel?.data?.reduce((obj, item) => {
                if(!obj[item.id]){
                    obj[item.id] = item.title;
                }
                return obj;
            }, {});
            // Add correct regions to the priority;
            for(const priority of response.result){
                if(regions[priority.fk_region_office_id]){
                    priority.region_name = regions[priority.fk_region_office_id];
                }
            }
            return response.result === undefined ?
                    response.value || [] :
                    response.result;
        } catch (e){
            return super.getDataFromResponse(data);
        }
    }

    getEndpoint(){
        return this.endpoint + (this.select.id ? '/' + this.select.id : '');
    }

    getPayload(select){
        return null;
    }

    getData(){
        return {
            status: this.status,
            data: this.data || [],
            parentValues: srfRpRegionModel?.data
        };
    }

    getSelect(input){
        return ModelSrfRpPriority.getSelect(input);
    }
}

export {ModelSrfRpPriority};
