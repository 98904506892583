import {ModelSrfGcmObjective} from '../../../model/srf_gcm_objective.js';
import {ModelSrfSdgGoal} from '../../../model/srf_sdg_goal.js';
import {ModelSrfSdgTarget} from '../../../model/srf_sdg_target.js';
import {ModelSrfRpRegion} from '../../../model/srf_rp_region.js';
import {ModelSrfRpPriority} from '../../../model/srf_rp_priority.js';

export const list = [
    {
        id: 'gcm_objective',
        title: 'GCM Objectives',
        model: ModelSrfGcmObjective
    },
    {
        id: 'sdg_goal',
        title: 'Sdg goals',
        model: ModelSrfSdgGoal
    },
    {
        id: 'sdg_target',
        title: 'SDG Targets',
        model: ModelSrfSdgTarget,
        foreign_key: 'fk_srf_sdg_goal'
    },
    {
        id: 'rp_region',
        title: 'RP Region',
        model: ModelSrfRpRegion
    },
    {
        id: 'rp_priority',
        title: 'RP priority',
        model: ModelSrfRpPriority,
        foreign_key: 'fk_region_office_id'
    }
];
