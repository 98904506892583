import React from 'react';
import PropTypes from 'prop-types';
import {ItemBlock} from '../item-block/index.js';
import {useTranslation} from '../../helpers/use_translation.js';
import {getHighlightedText} from '../search/get_highlighted_text.js';
import {useStateContext} from '../../helpers/state-context.js';

const OeeIndicator = function(props){
    const [state] = useStateContext();
    const {item} = props;
    const {getText} = useTranslation();

    const formatText = (text) => {
        const html = text.replace(/[\n][i|v|x]*?[.]/gi, '<strong>$&</strong>');
        const parts = html.split('<strong>');
        const lines = [];
        let isBold = false;
        while(parts.length > 0){
            if(isBold){
                const p = parts[0].split('</strong>');
                if(p.length > 1){
                    lines.push(<p><strong>{p[0]}</strong>{getHighlightedText(p[1], state.searchText)}</p>);
                }else{
                    lines.push(<p>{getHighlightedText(p[1], state.searchText)}</p>);
                }
            }else{
                lines.push(<p className="indicator-text-main">
                    {getHighlightedText(parts[0], state.searchText)}
                </p>);
                isBold = true;
            }
            parts.splice(0, 1);
        }
        return <>
            {lines.map((line, index) => (
                <React.Fragment key={index}>{line}</React.Fragment>
            ))}
        </>;
    };

    return <ItemBlock
            className={'page-break'}
            type={props.type}
            title={getText('filter-indicator-one') + ' ' + props.item.num}
            text={formatText(item.title)}
            style={{display: 'block'}}/>;
};

OeeIndicator.defaultProps = {
    isMobileOrTablet: false,
    language: 'en',
    outputNum: 1,
    searchText: '',
    type: 'oee'
};

OeeIndicator.propTypes = {
    item: PropTypes.object.isRequired,
    isMobileOrTablet: PropTypes.bool,
    language: PropTypes.string,
    outputNum: PropTypes.number,
    searchText: PropTypes.string,
    type: PropTypes.string
};

export {OeeIndicator};
