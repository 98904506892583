const linkKeys = {
    gcm: 'gcm_objective_id',
    reg_priorities: 'rp_priority_id',
    sdg: 'sdg_target_id'
};

const getLinkFilter = function(state, excludeFields = []){
    let filter = '';
    for(const field of Object.keys(linkKeys)){
        if(excludeFields.includes(field)){
            continue;
        }
        if(state[field]?.length > 0){
            filter += (filter.length > 0 ? ' and ' : '');
            const values = state[field].map((fieldValue) => `${linkKeys[field]} eq ${fieldValue}`);
            filter += `(${values.join(' or ')})`;
        }
    }
    return (filter.length === 0) ? false : filter;
};

export {getLinkFilter};
