import {ModelSrfObjective} from '../../../model/srf_objective.js';
import {ModelSrfLtOutcome} from '../../../model/srf_lt_outcome.js';
import {ModelSrfStOutcome} from '../../../model/srf_st_outcome.js';
import {ModelSrfOutput} from '../../../model/srf_output.js';
import {ModelSrfIndicator} from '../../../model/srf_indicator.js';
import {ModelSrfIndicatorGuidance} from '../../../model/srf_indicator_guidance.js';
// import {ModelSrf} from '../../../model/srf.js';
import {ModelSrfStOutcomeIndicator} from '../../../model/srf_st_outcome_indicator.js';

export const list = [
    {
        id: 'srf_objective',
        title: 'Objectives',
        model: ModelSrfObjective
    },
    {
        id: 'srf_lt_outcome',
        title: 'Lt Outcomes',
        model: ModelSrfLtOutcome,
        foreign_key: 'fk_srf_objective_id'
    },
    {
        id: 'srf_st_outcome',
        title: 'St Outcomes',
        model: ModelSrfStOutcome,
        foreign_key: 'fk_srf_lt_outcome_id'
    },
    {
        id: 'srf_st_outcome_indicator',
        title: 'St Outcomes Indicator',
        model: ModelSrfStOutcomeIndicator,
        foreign_key: 'fk_srf_st_outcome_id'
    },
    {
        id: 'srf_output',
        title: 'Outputs',
        model: ModelSrfOutput,
        foreign_key: 'fk_srf_st_outcome_id'
    },
    {
        id: 'srf_indicator',
        title: 'Indicators',
        model: ModelSrfIndicator,
        // select: 'indicator_id as id, indicator_title as title, output_num as parent, ' +
        //         'output_id as parent_id, st_outcome_num',
        // editModel: ModelSrfIndicator,
        foreign_key: 'fk_srf_output_id'
    },
    {
        id: 'srf_indicator_guidance',
        title: 'Indicator guidance',
        model: ModelSrfIndicatorGuidance,
        foreign_key: 'fk_srf_indicator_id'
    }
];
