import React from 'react';
import PropTypes from 'prop-types';
import {UITabs} from '../../ui/tabs/tabs.js';
import {AdminList} from '../list.js';
import {UIButton} from '../../ui/button/button.js';
import {list} from './level_mapping.js';
import {useStateContext} from '../../../helpers/state-context.js';

const AdminOeeList = function(props){
    const [state, dispatch] = useStateContext(['subpage', 'category', 'type']);
    return <UITabs defaultValue={state.category} onChange={(value) => dispatch({type: 'category', value})}>
        {list.map((item) =>
            <UITabs.Tab key={item.id} value={item.id} title={item.title}>
                <UIButton className={'zol-light'}
                        onClick={() => dispatch({type: 'subpage', value: 'new'})}
                        style={{margin: '10px 5px'}}>
                    New
                </UIButton>
                <AdminList model={item.model} select={item.select} editModel={item.editModel}/>
            </UITabs.Tab>
        )}
    </UITabs>;
};

AdminOeeList.propTypes = {
    onTabChange: PropTypes.func
};

export {AdminOeeList};
