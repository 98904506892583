import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './indicator-details.scss';
import {useTranslation} from '../../helpers/use_translation.js';
import {ReactComponent as IconDownRight} from '../../assets/icons/corner-down-right.svg';
import {useStateContext} from '../../helpers/state-context.js';

const IndicatorPath = function(props){
    const [isOpen, setIsOpen] = useState(false);
    const [{exportImage}] = useStateContext(['exportImage']);
    const {getText} = useTranslation();
    const isShownOpen = !! exportImage;

    const path = ['objective', 'lt_outcome', 'st_outcome'];
    if(props.type === 'IND'){
        path.push('output');
    }
    const indicator = props.indicator;

    return <div className={'indicator-path' + (isOpen || isShownOpen ? ' zol-active' : '')}>

        {isShownOpen ?
            null :
            <span className="indicator-path-toggle" onClick={() => {setIsOpen(! isOpen);}}>
                <strong>{props.type === 'ST' ? indicator.st_outcome_num : indicator.output_num}</strong>
                <em>
                    {props.type === 'ST' ?
                            indicator.st_outcome_title :
                            indicator.output_short_title || indicator.output_title}
                </em>
            </span>
        }
        {isOpen || isShownOpen ?
            path.map((dimension, level) => (
                <span className={'indicator-path-item indicator-path-level' + (level + 1)} key={dimension}>
                    <IconDownRight/>
                    <em>{getText('filter-' + dimension.replace('_', '-') + '-one')}</em>
                    {indicator[dimension + '_num'] ?
                        <strong>{indicator[dimension + '_num']}</strong> :
                        null
                    }
                    <span>{indicator[dimension + '_title']}</span>
                </span>
            )) :
            null
        }
    </div>;
};

IndicatorPath.propTypes = {
    type: PropTypes.oneOf(['ST', 'IND']),
    indicator: PropTypes.object.isRequired
};

IndicatorPath.defaultProps = {
    type: 'IND'
};

export {IndicatorPath};
