import './select-grouped.scss';
import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {Dropdown} from './dropdown.js';
import {Modal} from './modal.js';
import {OptionsList} from './options-list.js';
import {Option} from './option.js';

/**
 * Selecting a parent always selects all children. The parent becomes checked automatically
 * So the value parameter only consist of values on the lowest level. For this value a unique key must be used
 * accross all parents. If you can have same children in multiple parents, you will need to create a unique
 * key by combining parent and child value.
 * value is array of keys:
 * [uniqueChildKey1, uniqueChildKey2, uniqueChildKey3]
 *
 * onChange: we get the same array of keys
 *
 * All options need to have the same depth and dimensions
 */
const UISelectGrouped = function(props){
    const [isActive, setIsActive] = useState(false);
    const [isModelActive, setIsModalActive] = useState(false);

    const toggleSelect = (event) => {
        if(event.target.className.includes('zol-select-grouped-toggle')){
            // If we press the toggle while a value is there and the dropdown is not active, we reset the
            // value and keep the dropdown inactive.
            if(! isActive && props.value.length > 0){
                props.onChange([]);
                return;
            }
        }
        if(props.isEnabled){
            setIsActive(! isActive);
        }
    };

    if(props.isOpenInModal){
        if(isActive){
            const modalProps = {
                title: props.placeholder,
                width: '100vh',
                height: '100vh',
                className: 'zol-select-grouped',
                onClose: () => {setIsActive(false);}
            };
            props.modalSetter(<Modal><OptionsList {...props}/></Modal>, modalProps);
            if(! isModelActive){
                setIsModalActive(true);
            }
        }else if(! isActive && isModelActive){
            props.modalSetter(null);
            setIsModalActive(false);
        }
    };

    const className = 'zol-select-grouped' + (props.isEnabled ? '' : ' zol-disabled') +
            (isActive ? ' zol-active' : '') + (props.value.length > 0 ? ' zol-has-value' : '');

    const getValueLabel = () => {
        const childDimension = props.dimensions[props.dimensions.length - 1];
        const valueLabels = props.options.filter((option) => {
            const childValue = option[childDimension];
            return props.value.includes(childValue);
        }).map((option) => (
            // TODO: the option label may use other values like isChecked or its children, in that case this
            // breaks. These values are added in OptionList and are not available at the moment on this level
            props.getOptionLabel({details: option}, childDimension, true)
        ));
        return props.getValueLabel(valueLabels, props.placeholder);
    };

    return <div className={className} onClick={toggleSelect}>

        <span className="zol-select-grouped-label">
            {getValueLabel(props.value, props.placeholder)}
        </span>
        <span className="zol-select-grouped-toggle"/>

        {isActive && ! props.isOpenInModal ?
            <Dropdown closeSelect={(e) => {setIsActive(false);}}>
                <OptionsList {...props}/>
            </Dropdown> :
            null
        }
    </div>;
};

UISelectGrouped.defaultProps = {
    isMultiSelect: true,
    getOptionLabel: (option, dimension, isResultAsText) => (option[dimension]),
    getValueLabel: (value, placeholder) => (
        value.length === 0 ? placeholder : value.join(', ')
    ),
    placeholder: 'Select',
    isSearchEnabled: false,
    isEnabled: true,
    getIsOptionEnabled: (option, dimension) => (true),
    isOpenInModal: false,
    Option,
    isOpenOnTitleClick: false
};

UISelectGrouped.propTypes = {
    dimensions: PropTypes.array.isRequired, // an array of the dimensions used from parent to child
    options: PropTypes.array.isRequired, // array of object containing all dimensions (parent values repeat)
    // The value currently selected. You are required to manage this outside the component
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired, // callback when the value changes
    // if dimensions is not the value that we want to show, we can use this function to lookup another value
    getOptionLabel: PropTypes.func,
    getValueLabel: PropTypes.func,
    isMultiSelect: PropTypes.bool, // whether we can select multiple values (default true)
    placeholder: PropTypes.string, // value displayed in the select when no options are checked
    isSearchEnabled: PropTypes.bool, // whether we want to include a search function
    isEnabled: PropTypes.bool, // whether the select is enables or not
    getIsOptionEnabled: PropTypes.func, // function to check whether we can select this option
    isOpenInModal: PropTypes.bool, // whether we want to open it as dropdown or in a modal
    modalSetter: PropTypes.func, // function to set the modal, typically you would use the UIModalManager
    Option: PropTypes.elementType, // The component to render an option
    // clicking the title of the group by default opens the group, but with this options you can set the value
    isOpenOnTitleClick: PropTypes.bool
};

export {UISelectGrouped};
