import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';

class ModelSrfRpView extends ModelAuthenticatedXhr {
    static modelName = 'ModelSrfRpView';

    static getSelect(input){
        const select = {
            select: input.select ?
                input.select :
                'rp_region_id, rp_region_title, rp_region_code, rp_region_name, rp_priority_id, ' +
                        'rp_priority_num, rp_priority_title',
            filter: input.filter ?
                input.filter :
                '',
            language: input.language ?
                input.language :
                'en'
        };
        return select;
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf-rp-view',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getSelect(input){
        return ModelSrfRpView.getSelect(input);
    }

    getData(){
        return {status: this.status, data: this.data || []};
    }
}

export {ModelSrfRpView};
