import './select-grouped.scss';
import React from 'react';

const Modal = function(props){
    return <div className="zol-select-grouped-modal">
        {props.children}
    </div>;
};

Modal.defaultProps = {};

Modal.propTypes = {};

export {Modal};
