import React from 'react';
import {useStateContext} from '../../helpers/state-context.js';
import {AdminUserList} from '../../components/admin/user/list.js';
import {AdminUserNew} from '../../components/admin/user/new.js';
import {ModelConsumer} from '../../helpers/model/model-consumer.js';
import {ModelSrfAdmin} from '../../model/srf_admin.js';

const AdminUser = function(props){
    const [state] = useStateContext(['type', 'subpage', 'id', 'category']);
    if(state.subpage === 'new'){
        return <>
            <AdminUserList/>
            <AdminUserNew/>
        </>;
    }else if(state.subpage === 'edit' && state.id !== null){
        return <>
            <AdminUserList/>
            <ModelConsumer model={ModelSrfAdmin} selectionCriteria={{id: state.id}}>
                {(resultSet) => <AdminUserNew initialValues={resultSet.data[0]}/>}
            </ModelConsumer>
        </>;
    }
    return <AdminUserList/>;
};

export {AdminUser};
