import './select-grouped.scss';
import React from 'react';
import {PropTypes} from 'prop-types';
import {useClickOutsideElement} from '../../../helpers/use_click_outside_element.js';

const Dropdown = function(props){
    const measuredRef = useClickOutsideElement(props.closeSelect);

    return <div className="zol-select-grouped-dropdown" ref={measuredRef}>
        {props.children}
    </div>;
};

Dropdown.defaultProps = {};

Dropdown.propTypes = {
    closeSelect: PropTypes.func.isRequired
};

export {Dropdown};
