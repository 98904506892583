import React from 'react';
import PropTypes from 'prop-types';
import {useStateContext} from '../../../../helpers/state-context/use_state_context.js';
import * as d3Shape from 'd3-shape';

const DonutSegmentLabel = function(props){
    const [state] = useStateContext();
    const {item, isLevel1, arc, donutRadius, radius} = props;
    const isMobile = (state.renderSize === 'small');
    const isTablet = (state.renderSize === 'medium');

    const numStyle = {
        textAnchor: 'middle',
        fill: '#fff',
        fontSize: isLevel1 ?
            (isMobile) ? 30 : 50 :
            (isMobile) ? 16 : 30,
        fontWeight: isLevel1 ? 'bold' : 'normal'
    };
    const centroid = arc.centroid(item);

    // If nothing is selected we display the short titles of objectives;
    if(!state[props.value] && !isMobile && !isTablet && isLevel1){
        let innerRadius = null;
        let outerRadius = null;
        let offset = null;

        let text = item.data.objective_short_title || item.data.title;
        text = text?.match(/.{1,20}([\s|-]|$)/g);
        const multiLineOffset = text.length - 1;

        let textAlign = 'middle';
        if(props.numItems === 4){
            const textLineCenter = donutRadius * (radius + 0.28);
            const lineHeight = 38;
            if(item.data.id === 1){
                centroid[0] -= 65;
                centroid[1] -= 40;
                outerRadius = textLineCenter;
                offset = '16%';
                textAlign = 'start';
            }else if(item.data.id === 2){
                centroid[0] -= 65;
                centroid[1] += 60;
                outerRadius = textLineCenter - 0.5 * (1 + multiLineOffset) * lineHeight;
                offset = '67%';
                textAlign = 'start';
            }else if(item.data.id === 3){
                centroid[0] += 65;
                centroid[1] += 60;
                outerRadius = textLineCenter - 0.8 * (1 + multiLineOffset) * lineHeight;
                offset = '83%';
                textAlign = 'end';
            }else if(item.data.id === 4){
                centroid[0] += 65;
                centroid[1] -= 40;
                outerRadius = textLineCenter;
                offset = '33%';
                textAlign = 'end';
            }
            innerRadius = outerRadius - 1;
        }else if(props.numItems === 7){
            const textLineCenter = donutRadius * (radius + 0.28);
            const lineHeight = 18;
            if(item.data.num === 1){
                centroid[0] -= 12;
                centroid[1] += 20;
                offset = '25%';
                outerRadius = textLineCenter + 0.5 * (1 + multiLineOffset) * lineHeight;
            }else if(item.data.num === 2){
                centroid[0] -= 20;
                centroid[1] += 10;
                outerRadius = textLineCenter + 0.5 * (1 + multiLineOffset) * lineHeight;
                offset = '25%';
            }else if(item.data.num === 3){
                centroid[0] -= 20;
                centroid[1] -= 10;
                outerRadius = textLineCenter - 0.8 * (1 + multiLineOffset) * lineHeight;
                offset = '75%';
            }else if(item.data.num === 4){
                centroid[1] -= 20;
                outerRadius = textLineCenter - 0.58 * (1 + multiLineOffset) * lineHeight;
                offset = '75%';
            }else if(item.data.num === 5){
                centroid[0] += 20;
                centroid[1] -= 10;
                outerRadius = textLineCenter - 0.8 * (1 + multiLineOffset) * lineHeight;
                offset = '75%';
            }else if(item.data.num === 6){
                centroid[0] += 20;
                centroid[1] += 10;
                outerRadius = textLineCenter + 0.5 * (1 + multiLineOffset) * lineHeight;
                offset = '25%';
            }else if(item.data.num === 7){
                centroid[0] += 12;
                centroid[1] += 20;
                outerRadius = textLineCenter + 0.5 * (1 + multiLineOffset) * lineHeight;
                offset = '25%';
            }
            innerRadius = outerRadius - 1;
        }
        const textArc = d3Shape.arc()
                .innerRadius(innerRadius)
                .outerRadius(outerRadius)
                .padAngle(.04);
        return <>
            <defs>
                <path id={`objective-${item.data.id}-${props.frozen}`}
                        d={textArc(item)} fill={'transparant'}/>
            </defs>
            <text transform={`translate(${centroid})`} style={numStyle}>
                <tspan style={{fontSize: 50}} dx={0} dy={10}>
                    {item.data.num || item.data.id}
                </tspan>
            </text>
            <text className={'srf-objective-title'}>
                <textPath xlinkHref={`#objective-${item.data.id}-${props.frozen}`} startOffset={offset}
                        textAnchor={textAlign}>
                    {text?.map((text, index) => (
                        <tspan key={index} x={0} dy={18}>
                            {text.trim()}
                        </tspan>
                    ))}
                </textPath>
            </text>
        </>;
    }
    const rotation = props.frozen ? '' : ` rotate(${-state.rotate})`;

    return <text transform={`translate(${centroid})` + rotation} dy={`0.35em`} style={numStyle}>
        {item.data.num || item.data.id}
    </text>;
};

DonutSegmentLabel.defaultProps = {
    numItems: 4
};

DonutSegmentLabel.propTypes = {
    item: PropTypes.object,
    arc: PropTypes.func,
    frozen: PropTypes.bool,
    isLevel1: PropTypes.bool,
    donutRadius: PropTypes.number,
    radius: PropTypes.number,
    onlyLongTerm: PropTypes.bool,
    value: PropTypes.string,
    numItems: PropTypes.number
};

export {DonutSegmentLabel};
