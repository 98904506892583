import React, {useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {useSize} from '../../../helpers/use_size.js';

const UISwitchItem = function(props){
    const [width, measuredRef] = useSize((w, h) => (w), 0);

    const updateSize = props.updateSize;
    useEffect(() => {
        updateSize(props.maxWidth ? Math.min(props.maxWidth, width) : width);
    }, [updateSize, width]);

    const styleItem = {minWidth: props.minWidth};
    if(props.maxWidth > 0){
        styleItem.width = props.maxWidth;
    }

    return <span ref={measuredRef}
            className={'zol-ui-switch-item' + (props.isActive ? ' zol-active' : '')}
            style={styleItem}
            onClick={props.onClick}>

        {props.children}

    </span>;
};

UISwitchItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    updateSize: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    minWidth: PropTypes.number.isRequired,
    maxWidth: PropTypes.number
};

UISwitchItem.defaultProps = {
    style: {},
    maxWidth: 0 // zero means disabled
};

export {UISwitchItem};
