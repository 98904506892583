import React from 'react';
import PropTypes from 'prop-types';
import {ModelConsumer} from '../../../helpers/model/model-consumer.js';
import {ItemBlock} from '../../item-block/index.js';
import {useLinkFilter} from '../../../helpers/srf/use_link_filter.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {UIButton} from '../../ui/button/button.js';
import {OverviewMobileDetails} from './details.js';
import {ModelSrf} from '../../../model/srf.js';
import {ModelSrfLinkView} from '../../../model/srf-link-view.js';
import {ModelSrfStOutcomeIndicator} from '../../../model/srf_st_outcome_indicator.js';
import {useDonutColor} from '../../../helpers/srf/use_donut_color.js';
import {LinkedRegionalPriority} from '../../linked/regional-priority.js';
import {LinkedGcm} from '../../linked/gcm.js';
import {LinkedSdg} from '../../linked/sdg.js';
import {Output} from '../../output/index.js';
import {Indicators} from '../../short-term-indicator/indicators.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const models = {
    indicator: ModelSrfStOutcomeIndicator,
    output: ModelSrf,
    rp: ModelSrfLinkView,
    sdg: ModelSrfLinkView,
    gcm: ModelSrfLinkView
};

const ShortTermOutcomeMobile = function(props){
    const [state, dispatch] = useStateContext();
    const {getText} = useTranslation();
    const {objectiveColor} = useDonutColor();
    const {item} = props;
    const filter = useLinkFilter();
    const stLinks = filter.links.filter((link) => (
        link.item_type === 'srf_st_outcome' && item.id === link.fk_item_id
    ));

    const titlesMany = {
        indicator: getText('filter-st-outcome-indicator-many-short'),
        output: getText('filter-output-many'),
        rp: getText('filter-rp-many'),
        sdg: getText('filter-sdg-many'),
        gcm: getText('filter-gcm-many')
    };

    const titlesOne = {
        indicator: getText('filter-st-outcome-indicator-one-short'),
        output: getText('filter-output-one'),
        // rp: getText('filter-rp-one'),
        sdg: getText('filter-sdg-one'),
        gcm: getText('filter-gcm-one')
    };

    const opacity = (filter.hasValue) ? (stLinks.length > 0) ? 1 : .4 : 1;

    const details = {indicator: {filter: 'fk_srf_st_outcome_id eq ' + item.id},
        output: {select: 'output_num as num',
            filter: 'st_outcome_id eq ' + item.id},
        rp: {select: 'rp_priority_id, rp_region_id',
            filter: `rp_region_id ne null and item_type eq 'srf_st_outcome' ` +
                `and fk_item_id eq ${item.id}`},
        sdg: {select: 'sdg_goal_id, sdg_target_id',
            filter: `sdg_target_id ne null and item_type eq 'srf_st_outcome' ` +
                `and fk_item_id eq ${item.id}`},
        gcm: {select: 'gcm_objective_id, gcm_objective_num',
            filter: `gcm_objective_id ne null and item_type eq 'srf_st_outcome' ` +
                `and fk_item_id eq ${item.id}`}};

    if(props.type === 'overview'){
        return <ItemBlock
                details={
                    <UIButton className={'zol-blue'}
                            style={{borderRadius: '25px', padding: '8px 15px', margin: 0}}
                            onClick={() => dispatch({type: 'setSTOutcome', value: item.id})}>
                        {getText('st-outcome-overview-details')}
                    </UIButton>
                }
                type={'short-term-mobile-overview'}
                title={item.num}
                text={item.short_title}
                style={{opacity}}>
            <div className={`st-mobile-details-menu`}>
                {Object.keys(titlesOne).map((key) => (
                    <ModelConsumer key={key} model={models[key]} selectionCriteria={details[key]}>
                        {(resultSet) => {
                            const count = resultSet.data?.length;
                            const titles = (count > 1) ? titlesMany : titlesOne;
                            return <OverviewMobileDetails
                                    title={titles[key]}
                                    count={count}
                                    type={props.type}
                                    onClick={(key) => {
                                        dispatch({type: 'topic', value: key});
                                        dispatch({type: 'setSTOutcome', value: item.id});
                                    }}
                                    selected={state.topic} id={key}/>;
                        }}
                    </ModelConsumer>
                ))}
            </div>
        </ItemBlock>;
    }else{
        return <ItemBlock
                type={'short-term-mobile'}
                title={getText('filter-st-outcome-one') + ' ' + item.num}
                text={item.title}
                style={{opacity}}>
        <div className={`st-mobile-details-menu`}
                style={state.topic ?
                    {borderRadius: 0} : null}>
            {Object.keys(titlesOne).map((key) => (
                <ModelConsumer key={key} model={models[key]} selectionCriteria={details[key]}>
                    {(resultSet) => {
                        const count = resultSet.data?.length;
                        const titles = (count > 1) ? titlesMany : titlesOne;
                        return <OverviewMobileDetails
                                count={count}
                                title={titles[key]}
                                type={props.type}
                                selected={state.topic} id={key}
                                onClick={(key) => {
                                    if(state.topic !== key){
                                        dispatch({type: 'topic', value: key});
                                    }
                                }}/>;
                    }}
                </ModelConsumer>
            ))}
        </div>
        {state.topic && props.type !== 'overview' ?
            <div style={{backgroundColor: `rgb(${objectiveColor?.join(',')})`,
                borderRadius: '0px 0px 20px 20px', padding: '20px 10px 10px'}}>
                <div className={'st-mobile-details-list'}>
                    {state.topic === 'indicator' ?
                        <Indicators styleBox={{padding: '20px 0', margin: '0 10px'}}
                                styleTitle={{padding: 0, textAlign: 'left'}}/> : null}
                    {state.topic === 'output' ? <Output/> : null}
                    {state.topic === 'rp' ? <LinkedRegionalPriority/> : null}
                    {state.topic === 'sdg' ? <LinkedSdg/> : null}
                    {state.topic === 'gcm' ? <LinkedGcm/> : null}
                </div>
            </div> : null}
    </ItemBlock>;
    }
};

ShortTermOutcomeMobile.defaultProps = {
    type: ''
};

ShortTermOutcomeMobile.propTypes = {
    type: PropTypes.string,
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        num: PropTypes.string.isRequired,
        short_title: PropTypes.string,
        title: PropTypes.string
    })
};

export {ShortTermOutcomeMobile};
