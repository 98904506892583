import React, {useState} from 'react';
import {UIModal} from '../../ui/modal/modal.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {ModelSrfLink} from '../../../model/srf_link.js';
import {useLinkData} from '../../../helpers/srf/use_link_data.js';
import {StructuredSet} from '../../../helpers/data-wrangling/structured-set.js';
import {UITree} from '../../ui/tree/index.js';
import {ModelSrfSdgView} from '../../../model/srf-sdg-view.js';
import {ModelSrfRpView} from '../../../model/srf-rp-view.js';
import {ModelSrfGcmObjective} from '../../../model/srf_gcm_objective.js';
import {list} from './level_mapping.js';
import {UIButton} from '../../ui/button/button.js';

const AdminCcpEditLinks = function(){
    const [state, dispatch] = useStateContext();
    const [searchValue, setSearchValue] = useState('');
    const {sdgList, gcmList, rpList} = useLinkData();
    const level = list.find((i) => i.id === state.category);
    const resultSet = useModelFeedback(level.model, {id: state.id});
    const item = resultSet.data?.length > 0 ? resultSet.data[0] : null;
    const resultSetSdg = useModelFeedback(ModelSrfSdgView);
    const resultSetRp = useModelFeedback(ModelSrfRpView);
    const resultSetGcm = useModelFeedback(ModelSrfGcmObjective, {select: 'id, title'});
    const resultSetLinks = useModelFeedback(ModelSrfLink, {
        filter: `item_type eq '${state.category}' and fk_item_id eq ${state.id}`
    });
    const [newItems, setNewItems] = useState([]);
    const [removedItems, setRemovedItems] = useState([]);
    if(resultSetLinks.feedback || resultSetSdg.feedback || resultSetRp.feedback || resultSetGcm.feedback){
        return resultSetLinks.feedback || resultSetSdg.feedback || resultSetRp.feedback ||
                resultSetGcm.feedback;
    }

    const gcmStructure = {
        value: 'id',
        name: (row) => (row.id + ': ' + row.title),
        linkType: () => ('gcm_objective')
    };

    const sdgStructure = {
        value: 'sdg_goal_id',
        name: 'sdg_goal_title',
        isCheckable: () => (false),
        defaultVisible: () => (searchValue.length > 2),
        children: [
            {
                value: 'sdg_target_id',
                name: (row) => (row.sdg_target_num + ': ' + row.sdg_target_title),
                linkType: () => ('sdg_target')
            }
        ]
    };

    const rpStructure = {
        value: 'rp_region_id',
        name: 'rp_region_title',
        isCheckable: () => (false),
        defaultVisible: () => (searchValue.length > 2),
        children: [
            {
                value: 'rp_priority_id',
                name: (row) => (
                    row.rp_region_code + ' > ' + (`${row.rp_priority_num || ''} : `) + row.rp_priority_title
                ),
                linkType: () => ('rp_priority')
            }
        ]
    };

    const filterLinks = (list, type) => (
        list.filter((item) => {
            if(searchValue.length > 2){
                if(type === 'sdg'){
                    return [item.sdg_goal_title, item.sdg_target_title]
                            .some((t) => t?.toLowerCase().includes(searchValue.toLowerCase()));
                }else if(type === 'gcm'){
                    return item.title?.toLowerCase().includes(searchValue.toLowerCase());
                }else if(type === 'regional_priorities'){
                    return [item.rp_region_title, item.rp_priority_title]
                            .some((t) => t?.toLowerCase().includes(searchValue.toLowerCase()));
                }
                return false;
            }
            return true;
        })
    );

    const sdgOptions = (sdgList?.length > 0) ?
        StructuredSet.fromArray(filterLinks(resultSetSdg.data, 'sdg'), sdgStructure).getData() :
        [];
    const gcmOptions = (gcmList?.length > 0) ?
        StructuredSet.fromArray(filterLinks(resultSetGcm.data, 'gcm'), gcmStructure).getData() :
        [];
    const rpOptions = (rpList?.length > 0) ?
        StructuredSet.fromArray(filterLinks(resultSetRp.data, 'regional_priorities'), rpStructure).getData() :
        [];

    const data = [
        {
            value: 'sdg',
            name: 'Sdgs',
            children: sdgOptions,
            isCheckable: false,
            visible: state.category === 'srf_objective',
            defaultVisible: searchValue.length > 2
        }
    ];
    if(!['srf_objective', 'srf_lt_outcome'].includes(state.category)){
        data.push(...[
            {
                value: 'gcm',
                name: 'GCM Objectives',
                children: gcmOptions,
                isCheckable: false,
                defaultVisible: searchValue.length > 2
            },
            {
                value: 'regional_priorities',
                name: 'Regional Priorities',
                children: rpOptions,
                isCheckable: false,
                defaultVisible: searchValue.length > 2
            }
        ]);
    }

    const onCheck = (value, node) => {
        const link = {
            link_type: node.linkType,
            fk_link_id: parseInt(node.value),
            item_type: state.category,
            fk_item_id: parseInt(state.id)
        };
        const foundLink = resultSetLinks.data.find((value) => (
            node.value === value.fk_link_id && node.linkType === value.link_type
        ));
        if(foundLink && foundLink.id){
            // Add removed item;
            setRemovedItems((prev) => {
                const list = [...prev];
                if(list.includes(foundLink.id)){
                    return list.filter((i) => i !== foundLink.id);
                }else{
                    return [...prev, foundLink.id];
                }
            });
        }else{
            setNewItems((prev) => {
                const list = [...prev];
                const hasLink = (item) => (
                    item.link_type === link.link_type && item.fk_link_id === link.fk_link_id
                );
                if(list.some(hasLink)){
                    return list.filter((i) => !hasLink(i));
                }else{
                    return [...prev, link];
                }
            });
        }
    };

    const onSave = () => {
        // We go through all items and apply the changes: Either remove or add links;
        // TODO: create endpoint to remove multiple ids or add multiple links at once.
        for(const id of removedItems){
            ModelSrfLink.deleteItem(id);
        }
        for(const link of newItems){
            ModelSrfLink.createItem(link);
        }
        setNewItems([]);
        setRemovedItems([]);
    };

    const onClose = () => {
        if(newItems.length > 0 || removedItems.length > 0){
            const confirmDiscard = window.confirm('Are you sure you want to close this modal. ' +
                'There are unsaved changes');
            if(confirmDiscard){
                return dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
            }
        }else{
            dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
        }
    };

    const num = item?.num || item?.id || '';
    return <UIModal onClose={onClose}
            title={`Update links ${num}`}>
        <input type="text"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                style={{marginBottom: '10px', width: '100%', padding: '10px 0'}}
                placeholder="Find link"
                className="dropdown-search-input"/>
        <div style={{overflow: 'auto', maxHeight: 'calc(100vh - 300px)'}}>
            <UITree isCheckable={true}
                    isSearchable={true}
                    isItemChecked={(node) => {
                        const foundLink = resultSetLinks.data.find((value) => (
                            node.value === value.fk_link_id && node.linkType === value.link_type
                        ));
                        // If the item is in removeditems we show the item as false.
                        if(removedItems.includes(foundLink?.id)){
                            return false;
                        }
                        // If the item is in newItems we show it as true
                        if(newItems.some((item) => (
                            item.link_type === node.linkType && item.fk_link_id === node.value
                        ))){
                            return true;
                        }
                        return !! foundLink;
                    }}
                    data={data}
                    value={resultSetLinks.data}
                    onCheck={onCheck}/>
        </div>
        <UIButton disabled={removedItems.length === 0 && newItems.length === 0} onClick={onSave}>
            Save
        </UIButton>
    </UIModal>;
};

export {AdminCcpEditLinks};
