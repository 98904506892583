import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as IconUp} from './icons/up.svg';
import {ReactComponent as IconDown} from './icons/down.svg';
import styles from '../../../assets/variables.module.scss';

const UITableSort = function(props){
    const defaultSort = [
        {label: <IconUp/>, value: props.id + ' desc'},
        {label: <IconDown/>, value: props.id + ' asc'}
    ];

    const list = (props.sort === true) ? defaultSort : props.sort;
    return <span className="zol-table-header-sort">
        {list.map((sort) => {
            const isCurrent = (props.order === sort.value);
            return <span key={sort.value}
                    style={{
                        cursor: 'pointer',
                        margin: '-5px 0',
                        color: isCurrent ? styles.colorPrimary : styles.colorMedium
                    }}
                    onClick={() => {
                        const newOrder = isCurrent ? '' : sort.value;
                        props.onSelect(newOrder);
                    }}>
                {sort.label}
            </span>;
        })}
    </span>;
};

UITableSort.propTypes = {
    id: PropTypes.string,
    sort: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    order: PropTypes.string,
    onSelect: PropTypes.func
};

export {UITableSort};
