import React from 'react';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelSrfLinkView} from '../../model/srf-link-view.js';
import {useStateContext} from '../../helpers/state-context.js';
import {StructuredSet} from '../../helpers/data-wrangling/structured-set.js';
import {sdgMapping} from '../../assets/icons-sdg.js';
import './style.scss';
import {sortFilter} from '../../helpers/srf/sort_filter.js';
import {useTranslation} from '../../helpers/use_translation.js';

const structure = {
    id: 'sdg_goal_id',
    title: 'sdg_goal_title',
    children: [
        {
            id: 'sdg_target_id',
            num: 'sdg_target_num',
            title: 'sdg_target_title'
        }
    ]
};

const LinkedSdg = function(props){
    const [state] = useStateContext();
    const {getText} = useTranslation();
    const resultSet = useModelFeedback(ModelSrfLinkView, {
        select: 'sdg_target_id, sdg_target_num, sdg_target_title, sdg_goal_id, sdg_goal_title',
        filter: `sdg_target_id ne null and item_type eq 'srf_st_outcome' and fk_item_id eq ${state.st_id}`,
        language: state.language
    });

    const list = StructuredSet.fromArray(sortFilter(resultSet.data, 'sdg'), structure).getData();

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    if(list.length === 0){
        return <p>{getText('linked-filter-empty', {filterKey: getText('filter-sdg-one')})}</p>;
    }

    return list.map((item, key) => {
        const SdgIcon = sdgMapping[item.id];
        return <div key={key} className="zol-st-sdg">
            <h3 style={{borderTop: key === 0 ? null : '3px solid #EBF0F7'}}>
                {item.title}
            </h3>
            <ul>
                {item?.children.map((child, childKey) =>
                    <li key={childKey}>
                        <SdgIcon style={{width: 45}}/>
                        <div>
                            <span className="zol-st-sdg-num">SDG {child.num}</span>
                            <span>{child.title}</span>
                        </div>
                    </li>
                )}
            </ul>
        </div>;
    });
};

export {LinkedSdg};
