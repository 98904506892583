import './index.scss';
import React from 'react';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelOeeIndicator} from '../../model/oee_indicator.js';
import {useStateContext} from '../../helpers/state-context.js';
import {OeeIndicator} from './indicator.js';
import {useDonutData} from '../chart/donut/use_donut_data.js';
import {getHighlightedText} from '../search/get_highlighted_text.js';
import {IndicatorLine} from '../item-block/indicator-line.js';
import {useTranslation} from '../../helpers/use_translation.js';

const OEE = function(props){
    const [state] = useStateContext();
    const {getText} = useTranslation();
    const resultSet = useDonutData(state.page);
    const lang = state.language === 'en' ? '' : '_' + state.language;
    const resultSetIndicators = useModelFeedback(ModelOeeIndicator,
            {
                select: 'id, fk_oee_output_id, num, title' + lang + ' as title',
                filter: state.output ? `fk_oee_output_id eq ${state.output}` : null,
                order: 'num'
            });

    const isMobileOrTablet = state.renderSize === 'small' || state.renderSize === 'medium';

    if(state.output === null){
        return null;
    }
    if(resultSet.feedback || resultSetIndicators.feedback){
        return resultSet.feedback || resultSetIndicators.feedback;
    };
    const selectedItem = resultSet.find((it) => it.data.id === parseInt(state.output));
    const donutHover = (state.hover === null) ? 1 : .1;

    const data = (resultSetIndicators.data || []).sort((a, b) => {
        const aNum = a.num.split('.');
        const bNum = b.num.split('.');
        return aNum[0] === bNum[0] && aNum.length > 1 && bNum.length > 1 ?
            parseInt(aNum[1]) - parseInt(bNum[1]) :
            a.num < b.num ? -1 : 1;
    });

    return <div style={{opacity: donutHover}}>
        <div className={'zol-priority-details'}>
            <h2 className={'zol-text-uppercase'}>
                {getHighlightedText(selectedItem?.data.title, state.searchText)}
            </h2>
            <h3>
                {getHighlightedText(selectedItem?.data.description, state.searchText)}
            </h3>
        </div>
        <div className={'zol-oee-indicators'}>
            <IndicatorLine boxes={data.slice(0, 1)}
                    title={getText('filter-output-many')}
                    firstRequirement={(state) => state.output}/>

            {data.map((indicator, key) => (
                <div key={key}>
                    <OeeIndicator item={indicator}
                            outputNum={key + 1}
                            searchText={state.searchText}
                            language={state.language}
                            isMobileOrTablet={isMobileOrTablet}/>
                </div>
            ))}
        </div>
    </div>;
};

export {OEE};
