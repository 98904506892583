export const sortFilter = (data, by) => {
    if(by === 'regional'){
        return [...data]?.sort((a, b) => {
            // Sort by region name > priority num
            const compareRegion = a.rp_region
                    ?.localeCompare(b.rp_region, undefined, {numeric: true});
            const comparePriority = a.rp_priority_num
                    ?.localeCompare(b.rp_priority_num, undefined, {numeric: true});

            return compareRegion || comparePriority;
        });
    }else if(by === 'sdg'){
        return [...data]?.sort((a, b) => {
            // Sort by goal > target num
            const compareGoal = a.sdg_goal?.localeCompare(b.sdg_goal, undefined, {numeric: true});
            const compareTarget = a.sdg_target_num.toString()
                    .localeCompare(b.sdg_target_num.toString(), undefined, {numeric: true});

            return compareGoal || compareTarget;
        });
    }else if(by === 'gcm'){
        return [...data]?.sort((a, b) => {
            // Sort by id > title
            const compareTitle = a.title?.localeCompare(b.title, undefined, {numeric: true});
            const compareId = a.id.toString()
                    .localeCompare(b.id.toString(), undefined, {numeric: true});

            return compareId || compareTitle;
        });
    }
    console.warn(`Couldn't sort filters, ${by} filters doesn't exist!`);
    return data;
};
