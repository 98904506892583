import {CONFIG} from '../../config.js';
import {ModelAuthenticatedXhr} from '../authenticated-xhr.js';

class ModelAuthUser extends ModelAuthenticatedXhr {
    static modelName = 'ModelAuthUser';

    static getSelect(input){
        return input;
    }

    static createItem(data, onSuccess = () => {}, onError = () => {}){
        const userData = {
            email: data.email,
            password: data.password
        };

        this.saveData([ModelAuthUser], CONFIG.api_endpoint + 'srf-admin', 'POST',
                userData,
                (response) => {
                    if(response.success){
                        const user = response.result ? (response.result[0] || null) : null;
                        onSuccess(user);
                    }else{
                        onError(response.error || 'Unknown error');
                    }
                });
    }

    static updateItem(id, data, onSuccess = () => {}, onError = () => {}){
        this.saveData([ModelAuthUser], CONFIG.api_endpoint + 'srf-admin/' + id, 'PATCH',
                data,
                (response) => {
                    if(response.success){
                        const user = response.result ? (response.result[0] || null) : null;
                        onSuccess(user);
                    }else{
                        // todo: post status to global message channel
                        const error = response.error || 'Unknow error';
                        console.error(error);
                        onError(error);
                    }
                });
    }

    static deleteItem(id, onSuccess = () => {}){
        this.saveData([ModelAuthUser], CONFIG.api_endpoint + 'srf-admin/' + id, 'DELETE',
                null,
                (response) => {
                    if(response.success){
                        const user = response.result ? (response.result[0] || null) : null;
                        onSuccess(user);
                    }else{
                        // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                });
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf-admin',
            method: 'GET'
        });

        this.cacheEnabled = false;
    }

    getEndpoint(){
        return this.endpoint + (this.select.id ? '/' + this.select.id : '');
    }

    getSelect(input){
        return ModelAuthUser.getSelect(input);
    }

    getIsAdmin(){
        if(this.status === ModelAuthUser.Status.SUCCESS){
            isAdmin = this.data.length > 0;
            return {status: this.status, isAdmin};
        }
        return {status: this.status, isAdmin: false};
    }
}

export {ModelAuthUser};
