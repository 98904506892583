import './index.scss';
import React from 'react';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelCcpOutput} from '../../model/ccp_output.js';
import {ModelCcpIndicator} from '../../model/ccp_indicator.js';
import {useStateContext} from '../../helpers/state-context.js';
// import {CcpOutput} from './output.js';
import {OeeIndicator} from '../oee/indicator.js';
import {useDonutData} from '../chart/donut/use_donut_data.js';
import {getHighlightedText} from './../search/get_highlighted_text.js';
import {IndicatorLine} from '../item-block/indicator-line.js';
import {useTranslation} from '../../helpers/use_translation.js';

const CrossCuttingPriority = function(props){
    const [state] = useStateContext();
    const {getText} = useTranslation();
    const resultSet = useDonutData(state.page);
    const lang = state.language === 'en' ? '' : '_' + state.language;
    const resultSetOutputs = useModelFeedback(ModelCcpOutput,
            {
                select: 'id, ccp_id, title' + lang + ' as title',
                filter: state.priority ? `ccp_id eq ${state.priority}` : ''
            });

    const resultSetIndicators = useModelFeedback(ModelCcpIndicator,
            {
                select: 'id, output_id, num, title' + lang + ' as title',
                filter: state.priority ? `output_id eq ${state.priority}` : '',
                order: 'num'
            });

    const isMobileOrTablet = state.renderSize === 'small' || state.renderSize === 'medium';

    if(state.priority === null){
        return null;
    }
    if(resultSet.feedback || resultSetOutputs.feedback || resultSetIndicators.feedback){
        return resultSet.feedback || resultSetOutputs.feedback || resultSetIndicators.feedback;
    };
    const selectedItem = resultSet.find((it) => it.data.id === parseInt(state.priority));
    // const numOutputs = resultSetOutputs.data.length || 0;
    const donutHover = (state.hover === null) ? 1 : .1;

    const data = resultSetOutputs.data || [];
    const description = data.map((item) => (item.title)).join(' \n');
    const indicators = (resultSetIndicators.data || []).sort((a, b) => {
        const aNum = a.num.split('.');
        const bNum = b.num.split('.');
        return aNum[0] === bNum[0] && aNum.length > 1 && bNum.length > 1 ?
            parseInt(aNum[1]) - parseInt(bNum[1]) :
            a.num < b.num ? -1 : 1;
    });

    return <div style={{opacity: donutHover}}>
        <div className={'zol-priority-details'}>
            <h2 className={'zol-text-uppercase'}>
                {getHighlightedText(selectedItem?.data.title, state.searchText)}
            </h2>
            <h3>
                {getHighlightedText(description, state.searchText)}
            </h3>
        </div>
        <div className={'zol-ccp-outcomes'}>
            <IndicatorLine boxes={isMobileOrTablet ? data.slice(0, 1) : data}
                    title={getText('filter-output-many')}
                    firstRequirement={(state) => state.priority}/>

            {/* {data.map((output, key) => (
                <CcpOutput key={key}
                        item={output}
                        outputNum={key + 1}
                        searchText={state.searchText}
                        n={numOutputs}
                        language={state.language}
                        isMobileOrTablet={isMobileOrTablet}/>
            ))} */}
            {indicators.map((indicator, key) => (
                 <div key={key}>
                    <OeeIndicator item={indicator}
                            type="ccp"
                            outputNum={key + 1}
                            searchText={state.searchText}
                            language={state.language}
                            isMobileOrTablet={isMobileOrTablet}/>
                </div>
            ))}
        </div>
    </div>;
};

export {CrossCuttingPriority};
