
const getEasing = function(fact, easing){
    if(easing === 'linear'){
        return fact;
    }else if(easing === 'cubic' || easing === 'easeout'){
        return Math.sqrt(fact);
    }else if(easing === 'easein'){
        return fact * fact;
    }else if(easing === 'exp' || easing === 'expout'){
        return 1 - Math.pow(2, -10 * fact);
    }
    return fact;
};

export {getEasing};
