import React, {useEffect} from 'react';
import {UIModal} from '../../ui/modal/modal.js';
import {FormElement} from '../../../helpers/form/element.js';
import {UIButton} from '../../ui/button/button.js';
import PropTypes from 'prop-types';
import {useForm} from '../../../helpers/form/use_form.js';
import {useStateContext} from '../../../helpers/state-context/use_state_context.js';
import {UIInput} from '../../ui/input/input.js';
import {UIInputSelect} from '../../ui/input/select.js';
import {list} from './level_mapping.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const AdminLinkNew = function(props){
    const [state, dispatch] = useStateContext(['page', 'id', 'category']);
    const selected = list.find((item) => item.id === state.category);
    const {getLanguages} = useTranslation();
    const languages = getLanguages().filter((l) => l.value !== 'en');

    const formConfig = [
        {
            title: 'Num / Code',
            name: 'num',
            element: UIInput,
            type: 'wide',
            required: false
        },
        {
            title: 'Title',
            name: 'title',
            element: UIInput,
            required: true,
            type: 'wide'
        },
        ...languages.map((lang) => {
            return {
                title: 'Title ' + lang.title?.toLowerCase(),
                name: 'title_' + lang.value,
                required: true,
                type: 'wide',
                element: UIInput
            };
        }),
        {
            title: 'Region name',
            name: 'region_name',
            element: UIInput,
            required: false,
            type: 'wide'
        },
        ...languages.map((lang) => {
            return {
                title: 'Region name ' + lang.title?.toLowerCase(),
                name: 'region_name_' + lang.value,
                element: UIInput,
                required: false,
                type: 'wide'
            };
        }),
        {
            title: 'Parent',
            name: 'parent',
            element: UIInputSelect,
            options: {},
            type: 'wide'
        }
    ];

    const Form = useForm(formConfig);

    useEffect(() => {
        if(props.initialValues !== null){
            const values = {
                title: props.initialValues.title,
                num: props.initialValues.num || props.initialValues.code || '',
                region_name: props.initialValues.region_name
            };
            for(const language of languages){
                values['title_' + language.value] = props.initialValues['title_' + language.value];
                values['region_name_' + language.value] = props.initialValues['region_name_' +
                language.value];
            }
            if(selected.foreign_key){
                values.parent = props.initialValues[selected.foreign_key];
            }
            Form.setValues(values);
        }
    }, [Form, props.initialValues]);

    const submitForm = () => {
        const values = Form.getValues();
        if(Form.validate()){
            const result = {
                title: values.title
            };
            for(const language of languages){
                result['title_' + language.value] = values['title_' + language.value];
            }
            if(state.category === 'rp_region'){
                result.code = values.num;
                result.region_name = values.region_name;
                for(const language of languages){
                    result['region_name_' + language.value] = values['region_name_' + language.value];
                }
            }
            if(['sdg_target', 'rp_priority'].includes(state.category)){
                result.num = values.num;
            }
            if(['rp_priority'].includes(state.category)){
                result[selected.foreign_key] = parseInt(values.parent);
            }

            for(const language of languages){
                result['title_' + language.value] = values['title_' + language.value];
            }
            if(props.initialValues){
                selected.model.updateItem(state.id, result, () => {
                    dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
                });
            }else{
                selected.model.createItem(result, () => {
                    dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
                });
            }
        }
    };

    const renderElements = (config, options = []) => {
        options = options.reduce((obj, item) => {
            obj[item.id] = (item?.num || item?.id || '') + '. ' + item.title;
            return obj;
        }, {});
        return formConfig.map((element) => {
            const opts = element.name === 'parent' ? {options} : {};
            if(state.category === 'sdg_target' && element.name === 'parent'){
                opts.disabled = true;
            }
            // Hide 'num' fields on all tabs except sdg_target tab
            if((!['rp_region', 'sdg_target', 'rp_priority'].includes(state.category) &&
                    element.name === 'num') ||
                // Hide region_name on all tabs except rp_region
                (state.category !== 'rp_region' && element.name?.includes('region_name')) ||
                // Hide parent
                (!['sdg_target', 'rp_priority'].includes(state.category) &&
                        element.name === 'parent')){
                return null;
            }
            return <FormElement key={element.name} {...element} {...opts}/>;
        }
        );
    };

    return <UIModal onClose={() => dispatch({type: 'setValues', value: {subpage: 'list', id: null}})}
            title={`${(props.initialValues === null) ? 'Add new' : 'Edit'} item`} closeOnClickOutside={false}>
        <Form.Component>
            {renderElements(formConfig, props.parentValues)}
            <br/>
            <UIButton onClick={submitForm} style={{marginRight: 5}}>
                {(props.initialValues === null) ? 'New' : 'Edit'}
            </UIButton>
        </Form.Component>
    </UIModal>;
};

AdminLinkNew.defaultProps = {
    initialValues: null,
    parentValues: []
};

AdminLinkNew.propTypes = {
    initialValues: PropTypes.object,
    parentValues: PropTypes.array
};

export {AdminLinkNew};
