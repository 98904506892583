import React, {useEffect, useState} from 'react';
import {useStateContext} from '../../helpers/state-context.js';
import {useTranslation} from '../../helpers/use_translation.js';
import {UIButton} from '../ui/button/button.js';

const SavedItemsButton = function(props){
    const [{savedIndicators}, dispatch] = useStateContext(['savedIndicators']);
    const [itemsSaved, setItemsSaved] = useState(savedIndicators.length);
    const [isShowNote, setIsShowNote] = useState(false);
    const [noteOpacity, setNoteOpacity] = useState(1);
    const {getText} = useTranslation();

    useEffect(() => {
        let isNoteSet = isShowNote;
        if(itemsSaved === 0 && savedIndicators.length > 0){
            setIsShowNote(true);
            isNoteSet = true;
        }
        setItemsSaved(savedIndicators.length);
        let timerId = null;
        if(isNoteSet){
            timerId = setTimeout(() => {
                setNoteOpacity(0);
                timerId = setTimeout(() => {
                    setIsShowNote(false);
                    setNoteOpacity(1);
                }, 1500);
            }, 5000);
        }

        return () => {
            clearTimeout(timerId);
        };
    });

    const openSavedItems = () => {
        dispatch({type: 'page', value: 'saved'});
    };

    return <div className={'saved-items-button' + (itemsSaved > 0 ? ' zol-active' : '')}
            onClick={openSavedItems}>
        {getText('saved-indicators-link-title')} ({itemsSaved})

        {isShowNote ?
            <div className="saved-items-note" style={{opacity: noteOpacity}}>
                <h3>{getText('saved-indicators-note-title')}</h3>
                <p>{getText('saved-indicators-note-line1')}</p>
                <p>
                    <strong>{getText('saved-indicators-note-line2a')}</strong>&nbsp;
                    {getText('saved-indicators-note-line2b')}&nbsp;
                    <strong><em>{getText('saved-indicators-note-line2c')}</em></strong>&nbsp;
                    {getText('saved-indicators-note-line2d')}
                </p>
                <UIButton onClick={(e) => {e.stopPropagation(); setIsShowNote(false);}}>
                    {getText('saved-indicators-note-button')}
                </UIButton>
            </div> :
            null
        }
    </div>;
};

export {SavedItemsButton};
