import {useContext} from 'react';
import {deepFreeze} from '../functions/deep_freeze.js';
import {ReactStateContext} from './react-state-context.js';

const getObjectValueByPath = function(obj, path){
    const value = {};
    for(const key of Object.keys(obj)){
        if(key === path ||
                (key.length > path.length && key.substring(0, path.length) === path &&
                key.substring(path.length, 1) === '.')){
            value[key] = obj[key];
        }
    }
    return value;
};

/*  the StateContext should be used as a Map, meaning just key-value pairs, we don't use strucuted tree
    data.

    To filter the state you can set the argument 'watch', which contains a single namespace or an array
    of namespaces, e.g.: useStateContext(['page', 'item_id']).
    @todo for the time being watch only limits the properties returned by this method. It will still trigger
    a render update when any other property is changed

    We can group different values together by using a dot-separated notation. For example if we have
    different filter values that we would like to access all together, but also in isolation, we can store
    it like this:
    {
        'filter.region': 'Africa',
        'filter.country': 'Egypt',
        'filter.year': 2019
    }
    If we use useStateContext we can configure the watched namespace to: 'filter.country', to only get the
    country value. Or we can configure the namespace to: 'filter', to get all properties from this group.
    Note that the returned properties include the 'filter.' part.
*/
const useStateContext = function(watch = null){
    const contextValue = useContext(ReactStateContext);

    const dispatcher = (action, updateContext = true) => {
        contextValue.reducer(contextValue, action, updateContext);
    };

    let returnedState = {};
    if(watch !== null){
        if(! (watch instanceof Array)){
            watch = [watch];
        }
        for(const path of watch){
            const foundItems = getObjectValueByPath(contextValue.state, path);
            if(Object.keys(foundItems).length === 0){
                // console.warn('Component expects StateContext value for \'' + path +
                //          '\' but no value is set');
                // Instead of throwing this warning, we return a null value for now.
                // TODO: it seems that occasionally when the statecontext structure changes we initially don't
                // have it avaiable. So we watch something that isn't there yet, but it becomes available
                // anyway.
                foundItems[path] = null;
            }
            returnedState = {...returnedState, ...foundItems};
        }
    }else{
        returnedState = {...contextValue.state};
    }

    // make immutable
    deepFreeze(returnedState);

    return [returnedState, dispatcher];
};

export {useStateContext};
