import {parseUrl} from './parse_url.js';
import {compileUrl} from './compile_url.js';

const urlRootReducer = function(state, action){
    if(action.type === 'urlParse'){
        const nextState = {...state};
        nextState['url-state-context'] = {
            path: [],
            query: [],
            hash: [],
            parsed: parseUrl(action.value)
        };
        return nextState;
    }else if(action.type === 'urlPush'){
        const nextState = {...state};
        // nextState['url-state-context'] = action.value;
        const url = compileUrl(action.value);
        // we're pushing a new url. The state has already been updates, however we also need to update the
        // url-state-context property that holds the current details of the URL.
        // TODO: See if we do or do not need this:
        nextState['url-state-context'] = {
            path: [],
            query: [],
            hash: [],
            parsed: parseUrl(window.location.origin + url)
        };
        if(url !== window.location.href){ // only update when something actually changed
            window.history.pushState(action.value, 'Page', url);
        }

        return nextState;
    }

    return state;
};

export {urlRootReducer};
