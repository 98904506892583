import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {ReactComponent as IconFilter} from './icons/filter.svg';
import {UIInput} from '../input/input.js';

const UITableFilter = function(props){
    const [filterOpen, setFilterOpen] = useState(false);
    const [name] = useState('tableFilter' + Math.round(9999 * Math.random()));
    const [filterValue, setFilterValue] = useState('');

    const styleIcon = {
        width: '18px',
        height: '18px',
        fill: filterOpen ? 'rgb(106, 144, 203)' : 'rgb(160, 160, 160)',
        marginLeft: '5px',
        cursor: 'pointer'
    };

    const onChange = (value) => {
        setFilterValue(value);
        props.onChange(value);
    };

    return <span>
        <IconFilter style={styleIcon} onClick={() => {setFilterOpen(!filterOpen);}}/>

        {filterOpen ?
            <UIInput name={name} value={filterValue} type="reset" onChange={onChange}
                    focusOnMount={true} style={{display: 'block'}}/> :
            null
        }
    </span>;
};

UITableFilter.propTypes = {
    onChange: PropTypes.func.isRequired
};

export {UITableFilter};
