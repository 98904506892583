import React from 'react';
import {PropTypes} from 'prop-types';
import {useStateContext} from '../../../helpers/state-context.js';
import {useLinkData} from '../../../helpers/srf/use_link_data.js';
import {UISelectGrouped} from '../../../components/ui/select-grouped/index.js';
import {useScrollPosition} from '../../../helpers/scroll.js';
import './filter.scss';

const HeaderFilter = function(props){
    const {/* rpList, */sdgList, gcmList} = useLinkData(true);
    const [state, dispatch] = useStateContext(['setModal', 'reg_priorities', 'sdg', 'gcm']);
    const {y: scrollY} = useScrollPosition();

    const labelKeys = {
        rp_region_id: 'rp_region',
        rp_priority_id: 'rp_priority',
        sdg_goal_id: 'sdg_goal',
        sdg_target_id: 'sdg_target',
        id: 'title' // this is for GCM
    };

    const getValueLabelCallback = (unit) => (
        (value, placeholder) => (
            value.length === 0 ?
                placeholder :
                (value.length > 1 ? `${value.length} ${unit} selected` : value.join(', '))
        )
    );

    const filterIconStyle = {
        paddingBottom: Math.max(0, 90 - scrollY),
        backgroundPosition: 'center ' + Math.round(45 - scrollY) + 'px'
    };

    return <div className={'zol-header-filter-bar' + (props.isMobile ? ' zol-mobile-filters' : '')}>
        <span className={'header-filter-text'}>
            Find by
        </span>

        {/* <UISelectGrouped dimensions={['rp_region_id', 'rp_priority_id']}
                options={rpList}
                value={state.reg_priorities}
                onChange={(value) => {dispatch({type: 'reg_priorities', value});}}
                getOptionLabel={(option, dimension, isResultAsText) => {
                    const label = option.details[labelKeys[dimension]];
                    if(dimension === 'rp_priority_id'){
                        return isResultAsText ?
                            `[${option.details.rp_region_code}] ${option.details.rp_priority_num}: ${label}` :
                            <>
                                <strong>
                                    [{option.details.rp_region_code}] {option.details.rp_priority_num}
                                </strong>
                                : {label}
                            </>;
                    }
                    return label;
                }}
                isOpenInModal={props.isMobile}
                modalSetter={state.setModal}
                getValueLabel={getValueLabelCallback('priorities')}
                placeholder="Regional priorities"
                isOpenOnTitleClick={true}
                isSearchEnabled={true}/> */}

            <span className="filter-sdg-container" style={filterIconStyle}>
                <UISelectGrouped dimensions={['sdg_goal_id', 'sdg_target_id']}
                        options={sdgList}
                        value={state.sdg}
                        onChange={(value) => {dispatch({type: 'sdg', value});}}
                        getOptionLabel={(option, dimension, isResultAsText) => {
                            console.log(option);
                            const label = option.details[labelKeys[dimension]];
                            if(dimension === 'sdg_target_id'){
                                return isResultAsText ?
                                    `${option.details.sdg_target_num}: ${label}` :
                                    <>
                                        <strong>{option.details.sdg_target_num}</strong>
                                        : {label}
                                    </>;
                            }
                            return <span className="filter-sdg-goal-option">
                                <strong>SDG {option.value}</strong>: {label}
                            </span>;
                        }}
                        isOpenInModal={props.isMobile}
                        modalSetter={state.setModal}
                        getValueLabel={getValueLabelCallback('SDG targets')}
                        placeholder="SDG Targets"
                        isOpenOnTitleClick={true}
                        isSearchEnabled={true}/>
            </span>

            <span className="filter-gcm-container" style={filterIconStyle}>
                <UISelectGrouped dimensions={['id']}
                        options={gcmList}
                        value={state.gcm}
                        onChange={(value) => {dispatch({type: 'gcm', value});}}
                        getOptionLabel={(option, dimension, isResultAsText) => {
                            const label = option.details[labelKeys[dimension]];
                            return isResultAsText ?
                                `${option.details.id}: ${label}` :
                                <span style={{fontWeight: 'normal', fontSize: '90%'}}>
                                    <strong>{option.details.id}</strong>
                                    : {label}
                                </span>;
                        }}
                        isOpenInModal={props.isMobile}
                        modalSetter={state.setModal}
                        getValueLabel={getValueLabelCallback('GCM objectives')}
                        placeholder="GCM Objectives"
                        isOpenOnTitleClick={true}
                        isSearchEnabled={true}/>
            </span>

            {props.isMobile ?
                <span className={'apply-filter'}
                        title="Apply"
                        onClick={() => {
                            props.closeFilter();
                        }}>
                    Apply
                </span> :
                null
            }

        <span className={'reset-filter'}
                title="Reset all filters"
                onClick={() => {
                    // TODO: Define a action in the reducer to
                    // reset the filter and dispatch that as a single
                    // action here.
                    dispatch({type: 'reg_priorities', value: []});
                    dispatch({type: 'sdg', value: []});
                    dispatch({type: 'gcm', value: []});
                    dispatch({type: 'rotate', value: 0});
                    dispatch({type: 'setLTOutcome', value: null});
                    props.closeFilter();
                }}>
            {props.isMobile ? 'Reset filters' : 'RESET'}
        </span>
    </div>;
};

HeaderFilter.defaultProps = {
    closeFilter: () => {}
};

HeaderFilter.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    closeFilter: PropTypes.func
};

export {HeaderFilter};
