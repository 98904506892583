import React from 'react';
import PropTypes from 'prop-types';
import {UITableSort} from './sort.js';
import {UITableFilter} from './filter.js';

const UITableHeader = function(props){
    const {columns, order, setOrder, offsetTop, scrollOffset} = props;

    const totalWidth = columns.reduce((sum, col) => (sum + col.width), 0);
    const styleHeader = {
        // position fixed gives better performance, but we can't get it to work with scrolling enabled
        position: offsetTop === 0 ? 'static' : (props.isScrolling ? 'absolute' : 'fixed'),
        top: offsetTop < 0 ? offsetTop + 'px' : (props.isScrolling ? offsetTop + 'px' : '0px'),
        zIndex: offsetTop !== 0 ? '1' : '0',
        width: totalWidth,
        overflowX: offsetTop === 0 ? (props.isScrolling ? 'auto' : 'inherrit') :
                (props.isScrolling ? 'hidden' : 'visible'),
        overflowY: props.isScrolling ? 'hidden' : 'visible',
        height: offsetTop === 0 ? 'auto' : props.contentHeight
    };

    return <thead className="zol-table-header" style={styleHeader}>
        <tr>
            {columns.map((column, colIndex) => {
                const className = 'zol-table-header-item' +
                        (column.rotateHeader ? ' zol-table-header-item-rotated' : '');
                return <th key={colIndex}
                        className={className}
                        style={{
                            position: 'relative',
                            left: column.isFixed ? scrollOffset : '',
                            width: column.width,
                            wordBreak: 'break-word',
                            zIndex: column.isFixed ? 1 : 0,
                            ...(column.headerStyle || {})
                        }}>

                    <span className="zol-table-header-item-label">
                        {column.renderTitle ? column.renderTitle(column.title) : column.title}
                    </span>

                    {column.sort ?
                        <UITableSort sort={column.sort}
                                id={column.id}
                                order={order}
                                onSelect={(value) => setOrder(value)}/> :
                        null
                    }
                    {column.filter ?
                        <UITableFilter onChange={(value) => (props.setFilter(column.id, value))}/> :
                        null
                    }
                </th>;
            })}

        </tr>
    </thead>;
};

UITableHeader.defaultProps = {
    setFilter: (column, filterValue) => {},
    setOrder: () => {},
    isScrolling: false,
    offsetTop: 0,
    contentHeight: 50,
    scrollOffset: 0
};

UITableHeader.propTypes = {
    order: PropTypes.string,
    setOrder: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    setFilter: PropTypes.func,
    offsetTop: PropTypes.number,
    isScrolling: PropTypes.bool,
    contentHeight: PropTypes.number,
    scrollOffset: PropTypes.number
};

export {UITableHeader};
