import './main.scss';
import React from 'react';
import {ViewMain} from './main.js';

const ViewAdmin = function(props){
    return <ViewMain>
        <div className="zol-admin">
            {props.children}
        </div>
    </ViewMain>;
};

export {ViewAdmin};
