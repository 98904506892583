import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';
import {ModelSrfStOutcome} from './srf_st_outcome.js';

let srfStOutcomeModel = null;
ModelSrfStOutcome.addConsumer(ModelSrfStOutcome, {}, (model) => {
    if(model.status === ModelSrfStOutcome.Status.SUCCESS){
        srfStOutcomeModel = model;
    }
});

class ModelSrfStOutcomeIndicator extends ModelAuthenticatedXhr {
    static modelName = 'ModelSrfStOutcomeIndicator';

    static getSelect(input){
        return input;
    }

    static createItem(data, onSuccess = () => {}){
        this.saveData(ModelSrfStOutcomeIndicator, CONFIG.api_endpoint + 'srf-st-outcome-indicator',
                'POST',
                data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                        // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static updateItem(id, data, onSuccess = () => {}){
        this.saveData(ModelSrfStOutcomeIndicator, CONFIG.api_endpoint + 'srf-st-outcome-indicator/' + id,
                'PATCH',
                data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static deleteItem(id, onSuccess = () => {}){
        this.saveData(ModelSrfStOutcomeIndicator, CONFIG.api_endpoint + 'srf-st-outcome-indicator/' + id,
                'DELETE',
                null,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                });
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf-st-outcome-indicator',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getDataFromResponse(data){
        try {
            const response = JSON.parse(data);
            const stOutcomes = srfStOutcomeModel?.data?.reduce((obj, item) => {
                if(!obj[item.id]){
                    obj[item.id] = item.num;
                }
                return obj;
            }, {});
            // // Add correct st-outcome num to the indicaotr;
            for(const indicator of response.result){
                if(stOutcomes[indicator.fk_srf_st_outcome_id]){
                    indicator.parent = stOutcomes[indicator.fk_srf_st_outcome_id];
                }
            }
            return response.result === undefined ?
                response.value || [] :
                response.result;
        } catch (e){
            return super.getDataFromResponse(data);
        }
    }

    getEndpoint(){
        return this.endpoint + (this.select.id ? '/' + this.select.id : '');
    }

    getSelect(input){
        return ModelSrfStOutcomeIndicator.getSelect(input);
    }
}

export {ModelSrfStOutcomeIndicator};
