import React from 'react';
import './home.scss';
import {LongTermOutcome} from '../components/long-term-outcome/index.js';
import {StateContext} from '../helpers/state-context/state-context.js';
import {UrlStateContext} from '../helpers/url-state-context/url-state-context.js';
import {ViewMain} from '../view/main.js';
import {Feedback} from '../components/feedback/index.js';
import {ExportContainer} from '../components/export/container.js';
import {ExportButtons} from '../components/export/buttons.js';
import {FilterOverlay} from '../components/filter-overlay.js';
import {getSimpleReducer} from '../helpers/state-context.js';
import {Introduction} from '../components/introduction/index.js';
import {ChartDonut} from '../components/chart/donut/index.js';
import {InitItemSetter} from '../helpers/srf/init-item-setter.js';

const initialState = {
    lt_id: null,
    st_id: null,
    output: null
};
const simpleReducer = getSimpleReducer(Object.keys(initialState));
const reducer = (state, action) => {
    const nextState = {...state};
    if(action.type === 'setLTOutcome' || action.type === 'lt_id'){
        // Reset short term and outputs when a long term item is selected;
        nextState.lt_id = action.value;
        nextState.st_id = null;
        nextState.output = null;
        return nextState;
    }else if(action.type === 'setSTOutcome' || action.type === 'st_id'){
        // reset outputs when short outcome is selected.
        nextState.st_id = action.value;
        nextState.output = null;
        return nextState;
    }

    return simpleReducer(state, action);
};

const initialState2 = {
    // TODO: hover and rotate state can be moved to the donut component as this isn't used elsewhere.
    hover: null,
    rotate: 0,
    reg_priorities: [],
    sdg: [],
    gcm: [],
    searchText: null,
    topic: 'output',
    exportImage: '',
    tourEnabled: null
};

const PageObjectives = function(props){
    return <UrlStateContext initialState={initialState}
            hash={['lt_id', 'st_id', 'output']} reducer={reducer}>
        <StateContext initialState={initialState2}>
            <InitItemSetter/>
            <Introduction type={'objectives'}>
                <ViewMain type={'objectives'}>
                    <ExportContainer id='zol-export' extension='pdf'>
                        <ChartDonut has1Level={false}/>
                        <div className="zol-details-page" id="zol-details-page">
                            <LongTermOutcome/>
                        </div>
                        <ExportButtons enableFullLayout={true}/>
                        <Feedback/>
                    </ExportContainer>
                </ViewMain>
                <FilterOverlay/>
            </Introduction>
        </StateContext>
    </UrlStateContext>;
};

export {PageObjectives};
