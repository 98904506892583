import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {UISwitchItem} from './item.js';
import './switch.scss';
import {useStateContext} from '../../../helpers/state-context.js';

/*
    A switch between two or more values, arranged vertically for SRF purposes
*/
const UISwitchVertical = function(props){
    const [width, setWidth] = useState(20);
    const [{hover}] = useStateContext(['hover']);

    const updateSize = (newWidth) => {
        if(newWidth > width){
            setWidth(newWidth);
        }
    };

    const index = props.options.reduce(
            (index, item, rowNr) => (item.value === props.value ? rowNr : index), 0);

    const styleHighlight = {
        top: (index * 100 / props.options.length) + '%',
        right: 0,
        left: 0,
        height: (100 / props.options.length) + '%'
    };

    const styleSwitch = {
        width,
        opacity: hover ? 0.2 : 1
    };

    return <div className={'zol-ui-switch-vertical ' + props.className} style={styleSwitch}>
        <span style={styleHighlight} className="zol-ui-switch-highlight"/>
        {props.options.map((item) => (
            <UISwitchItem key={item.value}
                    isActive={props.value === item.value}
                    minWidth={width}
                    onClick={() => {props.onChange(item.value);}}
                    updateSize={updateSize}>

                {item.label}

            </UISwitchItem>
        ))}
    </div>;
};

UISwitchVertical.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
    })).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

UISwitchVertical.defaultProps = {
    className: ''
};

export {UISwitchVertical};
