import React from 'react';
import PropTypes from 'prop-types';
import {useModelFeedback} from '../../../../helpers/model/use_model_feedback.js';
import {ModelSrfLinkView} from '../../../../model/srf-link-view.js';
import {sdgMapping} from '../../../../assets/icons-sdg.js';
import {setTooltip} from '../../../../helpers/srf/set_tooltip.js';
import {StructuredSet} from '../../../../helpers/data-wrangling/structured-set.js';
import {useStateContext} from '../../../../helpers/state-context.js';

const DonutAnnotationSdgs = function(props){
    const {divRef} = props;
    const [state] = useStateContext();
    const resultSet = useModelFeedback(ModelSrfLinkView, {

        select: 'sdg_goal_id, sdg_goal_title, sdg_target_id, sdg_target_title, sdg_target_num',
        filter: `sdg_target_id ne null and item_type eq '${props.itemType}' and ` +
                `fk_item_id eq ${props.itemId}`,
        language: state.language
    });
    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const isMobile = ['small'].includes(state.renderSize);

    const data = StructuredSet.fromArray(resultSet.data, {
        sdg_goal_id: 'sdg_goal_id',
        sdg_goal_title: 'sdg_goal_title',
        sdg_targets: [{
            sdg_goal: 'sdg_goal_title',
            sdg_target_id: 'sdg_target_id',
            sdg_target_num: 'sdg_target_num',
            sdg_target: 'sdg_target_title'
        }]
    }).getData();

    return <div ref={divRef} className={'donut-annotation-sdgs'}>
        {data.map((sdg) => {
            const SdgIcon = sdgMapping[sdg.sdg_goal_id];
            return <div key={sdg.sdg_goal_id}
                    onClick={(e) => {setTooltip(e, 'sdg', sdg.sdg_targets, isMobile);}}
                    onMouseMove={(e) => {
                        if(! isMobile){
                            setTooltip(e, 'sdg', sdg.sdg_targets, isMobile);
                        }
                    }}
                    onMouseOut={(e) => {setTooltip(e, 'sdg', null);}}
                    className="donut-annotation-sdg-icon">
                <SdgIcon style={{width: 20}}/>
            </div>;
        })}
    </div>;
};

DonutAnnotationSdgs.propTypes = {
    itemId: PropTypes.number.isRequired,
    itemType: PropTypes.string.isRequired,
    divRef: PropTypes.func,
    objective: PropTypes.object
};

export {DonutAnnotationSdgs};
