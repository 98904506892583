import React, {useState, useEffect} from 'react';
import {useStateContext} from '../../helpers/state-context.js';
import {Indicators} from './indicators.js';
import {useIndicatorData} from './use_indicator_data.js';
import {ReactComponent as PlusSvg} from '../../assets/icons/plus.svg';
import {useTranslation} from '../../helpers/use_translation.js';

const ShortTermIndicator = function(){
    const [state] = useStateContext(['st_id', 'renderSize', 'topic', 'searchText']);
    const [expanded, setExpanded] = useState(false);
    const {getText} = useTranslation();

    useEffect(() => {
        if(state.topic === 'st_outcome_indicator'){
            setExpanded(true);
            // dispatch({type: 'topic', value: 'output'});
        }
    }, [state.topic]);

    const {dataLength} = useIndicatorData();
    if(dataLength === 0){
        return null;
    }

    const title = (dataLength > 1) ?
        getText('filter-st-outcome-indicator-many') :
        getText('filter-st-outcome-indicator-one');

    return <div className="zol-show-toggle-container page-break">
        <div className="zol-show-toggle" onClick={() => {setExpanded((prev) => !prev);}}>
            <p className="indicator-number">{dataLength} {title}</p>
            <p className="show-hide-button pdf-export-hide">
                {expanded ? `${getText('hide-list')} ` : `${getText('show-list')} `}
                <PlusSvg transform={expanded ? 'rotate(45)' : null}/>
            </p>
        </div>
        {expanded ?
            <Indicators/> :
            null
        }
    </div>;
};

export {ShortTermIndicator};
