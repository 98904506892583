import {useState, useCallback, useEffect} from 'react';
import {objectsEqual} from './functions/objects_equal.js';
import {SimpleEvent} from './simple-event.js';

const useSize = function(parseValueCallback = (w, h) => {return {width: w, height: h};},
        initialSize = {width: 200, height: 200}){
    const [size, setSize] = useState(initialSize);
    const [element, setElement] = useState(null);

    useEffect(() => {
        let observer = null;

        if(element === null){
            return;
        }

        const onResize = () => {
            const width = element.clientWidth || element.offsetWidth || initialSize.width;
            const height = element.clientHight || element.offsetHeight || initialSize.height;
            const newSize = parseValueCallback(width, height);
            if(! objectsEqual(newSize, size)){
                setSize(newSize);
            }
        };

        if(window.hasOwnProperty('ResizeObserver')){
            observer = new ResizeObserver(onResize);
            observer.observe(element);
        }else{
            observer = new SimpleEvent(window, 'resize', onResize);
        }
        onResize();

        return () => {
            if(window.hasOwnProperty('ResizeObserver') && observer !== null){
                observer.disconnect();
            }else if(observer !== null){
                // Remove simple event
                observer.remove();
            }
        };
    }, [element, size, parseValueCallback]);

    const measuredRef = useCallback((node) => {
        if(node !== null){
            setElement(node);
        }
    }, []);

    return [size, measuredRef, element];
};

export {useSize};
