import React from 'react';
import PropTypes from 'prop-types';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {DeleteOutlined, EditOutlined, LinkOutlined} from '@ant-design/icons';
import {UITable} from '../ui/table/table.js';
import {useStateContext} from '../../helpers/state-context.js';
import styles from '../../assets/variables.module.scss';
// import {useTranslation} from '../../helpers/use_translation.js';

const AdminList = function(props){
    const [state, dispatch] = useStateContext(['subpage', 'category', 'id', 'type']);
    // const {getLanguages} = useTranslation();

    const resultSet = useModelFeedback(props.model);
    if(resultSet.feedback){
        return resultSet.feedback;
    }
    // const languages = getLanguages().filter((l) => l.value !== 'en');

    const columns = [
        {
            title: 'ID',
            key: 'id',
            width: 1,
            value: (row) => row.id
        },
        {
            title: 'Parent num/code',
            key: 'parent',
            width: 1,
            value: (row) => row.parent || '-'
        },
        {
            title: 'Num / Code',
            key: 'num',
            width: 2,
            value: (row) => row.num || row.code || '-'
        },
        {
            title: 'Title',
            key: 'title',
            width: 5,
            value: (row) => row.title || '-'
        },
        // ...languages.map((lang) => {
        //     return {
        //         title: 'Title ' + lang.title?.toLowerCase(),
        //         key: 'title_' + lang.value,
        //         width: 5,
        //         value: (row) => (row['title_' + lang.value]) || '-'
        //     };
        // }),
        {
            title: 'Region',
            key: 'region_name',
            width: 3,
            value: (row) => row.region_name || '-'
        },
        // ...languages.map((lang) => {
        //     return {
        //         title: 'Region ' + lang.title?.toLowerCase(),
        //         key: 'region_name_' + lang.value,
        //         width: 3,
        //         value: (row) => (row['region_name_' + lang.value]) || '-'
        //     };
        // }),
        {
            title: 'Short title',
            key: 'short_title',
            width: 4,
            value: (row) => row.short_title || '-'
        },
        // ...languages.map((lang) => {
        //     return {
        //         title: 'Short title ' + lang.title?.toLowerCase(),
        //         key: 'short_title_' + lang.value,
        //         width: 4,
        //         value: (row) => (row['short_title_' + lang.value]) || '-'
        //     };
        // }),
        {
            title: 'Action',
            key: 'action',
            value: (row) => row,
            width: 1,
            renderCell: (_, record) => <>
                <EditOutlined style={{
                    cursor: 'pointer', marginRight: 10, fontSize: 20, color: styles.colorBlueText
                }}
                        onClick={() => {
                            dispatch({type: 'setValues', value: {subpage: 'edit', id: record.id}});
                        }}/>
                {['srf_lt_outcome', 'srf_st_outcome', 'srf_objective'].includes(state.category) ?
                    <LinkOutlined style={
                            {
                                cursor: 'pointer',
                                marginRight: 10,
                                fontSize: 20,
                                color: styles.colorBlueText
                            }}
                            onClick={() => (
                                dispatch({
                                    type: 'setValues',
                                    value: {subpage: 'edit-link', id: record.id}
                                })
                            )}/> :
                    null
                }

                {! ['gcm_objective', 'sdg_goal', 'sdg_target', 'srf_indicator_guidance']
                        .includes(state.category) ?
                    <DeleteOutlined style={{cursor: 'pointer', fontSize: 20, color: styles.colorBlueText}}
                            onClick={() => {
                                // TODO: have nicer messages than the standard dialogues
                                if(window.confirm(`Are you sure you want to delete this (${record.id})?`)){
                                    const model = props.editModel || props.model;
                                    model.deleteItem(record.id);
                                }
                            }}/> :
                    null
                }
            </>
        }
    ];

    const availableData = resultSet.data.length > 0 ? Object.keys(resultSet.data[0]) : [];

    const filteredColumns = columns.filter((column) => {
        if(availableData.includes(column.key) || column.key === 'action'){
            // hide 'id' column on tabs from Four Objectives aside from Objectives tab
            if(state.type === 'srf' && state.category !== 'srf_objective' && column.key === 'id'){
                return false;
            };
            return true;
        }else{
            return false;
        };
    });

    // const filteredColumns = columns.filter((column) => {
    //     // Hide 'num' column on Objectives tab
    //     if((state.category === 'srf_objective' && column.key === 'num') ||
    //         (state.category === 'srf_st_outcome_indicator' && column.key === 'num') ||
    //         // Hide 'id' column on Lt Outcomes tab
    //         (state.category !== 'srf_objective' && column.key === 'id') ||
    //         // Hide parent on all tabs except src indicator
    //         ((state.category !== 'srf_indicator' && state.category !== 'srf_st_outcome_indicator') &&
    //                 column.key === 'parent') ||
    //         // Hide 'num' column on Indicators tab
    //         (state.category === 'srf_indicator' && column.key === 'num') ||
    //         // Hide 'num' on all link tabs except sdg_target, rp region and rp priority
    //         (state.type === 'link' &&
    //                 !['sdg_target', 'rp_region', 'rp_priority'].includes(state.category) &&
    //                 column.key === 'num') ||
    //         // Hide 'region_name' on all link tabs except rp_region and rp_priority
    //         (!['rp_priority', 'rp_region'].includes(state.category) && column.key === 'region_name') ||
    //         (!['rp_region'].includes(state.category) && column.key.includes('region_name_')) ||
    //         // Hide short_title on all tabs except lt_outcome, st_outcome and srf_output;
    //         (!['srf_lt_outcome', 'srf_st_outcome', 'srf_output', 'srf_objective']
    //                  .includes(state.category)) &&
    //             column.key.includes('short_title')){
    //         return false;
    //     }
    //     return true;
    // });

    const data = [...resultSet.data].sort((a, b) => (
        (a.num || a.code || a.id) > (b.num || b.code || b.id) ? 1 : -1
    ));
    // Handle sorting
    if(state.category === 'rp_priority'){
        data.sort((a, b) => {
            // Sort links tab by: 'RP Region name' > 'RP priority num';
            const regionNameSort = a.region_name?.localeCompare(b.region_name);
            if(regionNameSort){
                return regionNameSort;
            }
            const numA = a?.num || '';
            const numB = b?.num || '';
            return numA.localeCompare(numB, 'en', {numeric: true});
        });
    }
    if(state.category === 'srf_st_outcome_indicator' || state.category === 'srf_indicator'){
        // Sort indicators tab by 'ST Outcome num' > 'Output num'
        // Sort st outcome indicators tab by 'ST Outcome num'
        data.sort((a, b) => {
            const parentSort = (a.parent || '0').localeCompare(b.parent || '0', 'en', {numeric: true});
            return parentSort;
        });
    }

    return <UITable columns={filteredColumns} data={data}/>;
};

AdminList.propTypes = {
    select: PropTypes.string,
    model: PropTypes.func.isRequired,
    editModel: PropTypes.func
};

export {AdminList};
