import {ModelXhr} from '../helpers/model/xhr.js';

class ModelAuthenticatedXhr extends ModelXhr {
    static getAuthorization(){
        const expirationTime = parseInt(sessionStorage.getItem('auth_token_exp'));
        if((new Date()).getTime() >= expirationTime){
            return null;
        }else{
            return sessionStorage.getItem('auth_token') ?
                    {
                        type: 'Bearer',
                        access_token: sessionStorage.getItem('auth_token')
                    } :
                    null;
        };
    }

    constructor(selectData, settings = {}){
        super(selectData, settings);
    }

    getAuthorization(){
        return ModelAuthenticatedXhr.getAuthorization();
    }
}

export {ModelAuthenticatedXhr};
