import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';

class ModelOeeOutput extends ModelAuthenticatedXhr {
    static modelName = 'ModelOeeOutput';

    static getSelect(input){
        return input;
    }

    static createItem(data, onSuccess = () => {}){
        this.saveData(ModelOeeOutput, CONFIG.api_endpoint + 'oee_output', 'POST', data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                        // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static updateItem(id, data, onSuccess = () => {}){
        this.saveData(ModelOeeOutput, CONFIG.api_endpoint + 'oee_output/' + id, 'PATCH',
                data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                        // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static deleteItem(id, onSuccess = () => {}){
        this.saveData(ModelOeeOutput, CONFIG.api_endpoint + 'oee_output/' + id, 'DELETE',
                null,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                });
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'oee_output',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getEndpoint(){
        return this.endpoint + (this.select.id ? '/' + this.select.id : '');
    }

    getSelect(input){
        return ModelOeeOutput.getSelect(input);
    }

    getData(){
        const result = [];
        if(this.status === ModelOeeOutput.Status.SUCCESS && this.data){
            result.push(...this.data);
        }
        return {status: this.status, data: result};
    }
}

export {ModelOeeOutput};
