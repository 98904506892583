// Clock cycle: use requestAnimationFrame if available, otherwise fallback to setTimeout
const requestAnimationFrame = (function(){
    return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            function(callback){
                return window.setTimeout(callback, 1000 / 30);
            };
})();

export {requestAnimationFrame};
