const executeCallback = function(callback, args = []){
    if(! (args instanceof Array)){
        args = [args];
    }
    if(callback instanceof Array){
        for(let i = 2; i < callback.length; i++){
            args.push(callback[i]);
        }
        if(!callback[1]){
            console.warn('invalid callback found');
            console.log(callback);
        }
        if(! callback[0]){
            console.warn('object of callback not defined');
        }
        return callback[1].apply(callback[0], args);
    }else{
        return callback.apply(window, args);
    }
};

export {executeCallback};
