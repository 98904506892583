import './index.scss';
import React, {useState} from 'react';
import {ReactComponent as IomLogo} from '../../assets/iom_logo.svg';
import {useStateContext} from '../../helpers/state-context.js';
import {Search} from '../../components/search/index.js';
import {ReactComponent as FilterOutlined} from '../../assets/icons/funnel-outline.svg';
import {LanguagesSelection} from '../../components/languages.js';
import {ReactComponent as QuestionMarkIcon} from '../../assets/icons/question-circle.svg';
import {HeaderFilter} from './filter/index.js';
import {useClickOutsideElement} from '../../helpers/use_click_outside_element.js';
import {SavedItemsButton} from '../../components/saved-items/button.js';
import {useTranslation} from '../../helpers/use_translation.js';

function Header(props){
    const [state, dispatch] = useStateContext(['page', 'renderSize', 'sdg', 'reg_priorities', 'gcm']);
    const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
    const isMobile = ['small', 'medium'].includes(state.renderSize);
    const {getText} = useTranslation();

    // By setting zol-main as the parent element, we do not trigger a click when it is fired from the Modal
    const measuredRef = useClickOutsideElement(() => {
        if(isMobileFilterOpen){
            setIsMobileFilterOpen(false);
        }
    }, document.getElementsByClassName('zol-main')?.[0]);

    const enableTour = () => {
        // reset all filters first and move to the correct page
        // TODO: Define a action in the reducer to
        // reset the filter and dispatch that as a single
        // action here.
        dispatch({type: 'reg_priorities', value: []});
        dispatch({type: 'sdg', value: []});
        dispatch({type: 'gcm', value: []});
        dispatch({type: 'rotate', value: 0});
        dispatch({type: 'setLTOutcome', value: null});
        dispatch({type: 'page', value: 'objectives'});

        dispatch({type: 'tourEnabled', value: true});
    };

    const filterCount = ['sdg', 'reg_priorities', 'gcm']
            .reduce((count, dim) => (count + (state[dim] && state[dim].length > 0 ? 1 : 0)), 0);

    return <div id={'zol-header'}>
        <div id={'zol-header-row-1'}>
            <div className="srf-logo">
                <IomLogo onClick={() => {dispatch({type: 'page', value: 'objectives'});}}/>
            </div>

            {(['admin', 'sign-in'].includes(state.page)) ?
                null :
                <div className="header-buttons">
                    <LanguagesSelection/>
                    <SavedItemsButton/>
                </div>
            }

            {isMobile || ['admin', 'sign-in'].includes(state.page) ? null : <Search/>}
        </div>

        <div id={'zol-header-row-2'}>
            {state.tourStarted === true || isMobile || state.page !== 'objectives' ?
                null :
                <div className={'zol-introduction-button'}
                        onClick={enableTour}>
                    <QuestionMarkIcon/>
                    {getText('introduction-button')}
                </div>
            }
            {isMobile ?
                <div ref={measuredRef}>
                    <div id={'zol-header-filter'} className={'row-2-search'}>
                        <Search/>
                        {state.page === 'objectives' ?
                            <span className="filter-toggle">
                                <FilterOutlined onClick={() => {setIsMobileFilterOpen((prev) => !prev);}}/>
                                {filterCount > 0 ? <em>{filterCount}</em> : null}
                                <strong>{getText('header-filter-icon')}</strong>
                            </span> :
                            null
                        }
                    </div>
                    {isMobileFilterOpen && state.page === 'objectives' ?
                        <HeaderFilter isMobile={true}
                                closeFilter={() => {setIsMobileFilterOpen(false);}}/> :
                        null
                    }
                </div> :
                (state.page === 'objectives' ?
                    <div id="zol-header-filter">
                        <HeaderFilter isMobile={false}/>
                    </div> :
                    null
                )
            }
        </div>
    </div>;
}

export {Header};
