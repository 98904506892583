import {CONFIG} from '../../config.js';
import {Model} from '../../helpers/model/model.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAuthToken extends ModelXhr {
    static modelName = 'ModelAuthToken';

    static getSelect(input){
        return {};
    }

    static hasToken = () => {
        const token = sessionStorage.getItem('auth_token');
        const tokenExpire = sessionStorage.getItem('auth_token_exp');
        return !!(token && tokenExpire);
    }

    static hasValidToken(){
        const hasToken = ModelAuthToken.hasToken();
        const tokenExpire = sessionStorage.getItem('auth_token_exp');
        const isTokenExpired = (new Date().getTime()) > parseInt(tokenExpire);
        // Check if token and token expire exists and tokenExpire is not expired yet;
        return (hasToken && !isTokenExpired);
    }

    static createItem(username, password, onSuccess = () => {}, onFailure = () => {}){
        this.saveData(ModelAuthToken, CONFIG.api_endpoint + 'oauth2_token', 'POST',
                {
                    grant_type: 'password',
                    username,
                    password
                },
                (response) => {
                    if(! response.success){
                        onFailure(response.error);
                        return;
                    }
                    if(response.access_token){
                        sessionStorage.setItem('auth_token', response.access_token);
                        // get timestamp for 4 hours from now;
                        const date = new Date();
                        date.setHours(date.getHours() + 4);
                        sessionStorage.setItem('auth_token_exp', date.getTime());
                        Model.flushAllMemory();
                        onSuccess();
                        return;
                    }
                    onFailure('An unknown error occured.');
                },
                {
                    contentType: 'param'
                });
    }

    static signOut(){
        sessionStorage.removeItem('auth_token');
        sessionStorage.removeItem('auth_token_exp');
        Model.flushAllMemory();
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'oauth2_token',
            method: 'POST'
        });

        this.cacheEnabled = false;
    }

    getSelect(input){
        return ModelAuthToken.getSelect(input);
    }
}

export {ModelAuthToken};
