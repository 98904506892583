import React from 'react';
import {useStateContext} from '../../helpers/state-context.js';
import {AdminLinkNew} from '../../components/admin/link/new.js';
import {list as linkList} from '../../components/admin/link/level_mapping.js';
import {ModelConsumer} from '../../helpers/model/model-consumer.js';
import {AdminLinkList} from '../../components/admin/link/list.js';

const AdminLink = function(props){
    const [state] = useStateContext(['type', 'subpage', 'id', 'category']);
    if(state.subpage === 'new'){
        return <>
            <AdminLinkList/>
            <AdminLinkNew/>
        </>;
    }else if(state.subpage === 'edit' && state.id !== null){
        const selected = linkList.find((item) => item.id === state.category);
        return <>
            <AdminLinkList/>
            <ModelConsumer model={selected.model} selectionCriteria={{id: state.id}}>
                {(resultSet) => (
                    <AdminLinkNew initialValues={resultSet.data[0]} parentValues={resultSet.parentValues}/>
                )}
            </ModelConsumer>
        </>;
    }
    return <AdminLinkList/>;
};

export {AdminLink};
