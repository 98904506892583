import React from 'react';
import PropTypes from 'prop-types';
import {ExportContainer} from '../export/container.js';
import {ChartDonut} from '../chart/donut/index.js';
import {UIButton} from '../ui/button/button.js';
import {PictureOutlined} from '@ant-design/icons';
import {UIModal} from '../ui/modal/modal.js';
import {useStateContext} from '../../helpers/state-context.js';
import './style.scss';
import {useTranslation} from '../../helpers/use_translation.js';

const FullLayoutChartModal = function(props){
    const [, dispatch] = useStateContext();
    const {getText} = useTranslation();
    const {setPopupOpen} = props;
    return <div className="zol-full-layout-modal">
        <UIModal onClose={() => setPopupOpen(false)}
                width={'94%'}
                title={getText('full-layout-title')}>
            <ExportContainer extension='jpeg' id='zol-export-layout'>
                <div className="circle-full-layout">
                    <ChartDonut frozen={true}
                            id={'zol-export-layout'}
                            onlyLongTerm={true}
                            height={800}
                            width={1400}/>
                </div>
            </ExportContainer>

            <div className="zol-full-layout-buttons">
                <UIButton className={'zol-primary zol-srf-export'}
                        style={{marginRight: 10}}
                        title={getText('full-layout-save-button-description')}
                        onClick={() =>
                            dispatch({
                                type: 'exportImage',
                                value: 'zol-export-layout'
                            })}>
                    <PictureOutlined style={{fontSize: 16, marginRight: 5}}/>
                    {getText('full-layout-save-button')}
                </UIButton>
                <UIButton className={'zol-primary zol-srf-export'}
                        title={getText('full-layout-close-button-description')}
                        onClick={() => {
                            setPopupOpen(false);
                        }}>
                    {getText('full-layout-close-button')}
                </UIButton>
            </div>
        </UIModal>
    </div>;
};

FullLayoutChartModal.defaultProps = {
    setPopupOpen: () => {}
};

FullLayoutChartModal.propTypes = {
    setPopupOpen: PropTypes.func.isRequired
};

export {FullLayoutChartModal};
