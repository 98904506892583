import {useState, useEffect} from 'react';
import {SimpleEvent} from '../../../helpers/simple-event.js';

const useStickyHeader = function(tableElement, header, isEnabled){
    const [offsetHeader, setOffsetHeader] = useState(0);
    const [offsetContent, setOffsetContent] = useState(0);

    useEffect(() => {
        if(tableElement === null || ! isEnabled){
            return;
        }

        const handleFixedHeader = () => {
            const offset = tableElement.getBoundingClientRect();
            const headerElements = header || tableElement.getElementsByClassName('zol-table-header-item');
            // I think maybe due to border collapse property the offsetHeight seems to only include half the
            // border width. Adding 1px for now
            const headerHeight = headerElements.length > 0 ? headerElements[0].offsetHeight + 1 : 0;
            // if the header takes more than 70% of the screen we are not keeping it fixed
            if(headerHeight > 0.7 * window.innerHeight){
                if(offsetHeader !== 0){
                    setOffsetHeader(0);
                    setOffsetContent(0);
                }
            // The header is outside the view (at the top), now we want to keep it fixed as long as the
            // table bottom is not yet near the top of the view.
            }else if(offset.top < 0 && offset.bottom - headerHeight > 0.3 * window.innerHeight){
                setOffsetHeader(-1 * offset.top);
                setOffsetContent(headerHeight);

            // The bottom of the table is getting near the top. Now we want to slide out the header too
            }else if(offset.top < 0){
                const newOffset = -1 * (0.3 * window.innerHeight - offset.bottom + headerHeight);
                // Only update the values if the header is still in view.
                if(-1 * offsetHeader < headerHeight || -1 * newOffset < headerHeight){
                    setOffsetHeader(newOffset);
                    setOffsetContent(headerHeight);
                }
            }else{
                setOffsetHeader(0);
                setOffsetContent(0);
            }
        };

        const event = new SimpleEvent(window, 'scroll', handleFixedHeader);
        const event2 = new SimpleEvent(window, 'resize', handleFixedHeader);

        return () => {
            event.remove();
            event2.remove();
        };
    });

    return [offsetHeader, offsetContent];
};

export {useStickyHeader};
