import React from 'react';
import PropTypes from 'prop-types';
import {useStateContext} from '../../../../helpers/state-context/use_state_context.js';
import styles from '../../../../assets/variables.module.scss';
import {DonutSegmentLabel} from './label.js';
import * as d3Shape from 'd3-shape';

// This component renders each segment of the donut. This includes the shape, label ontop of the shape and
// also if the segment is selected it will draw a triangle at the end of it.
const DonutSegment = function(props){
    const {item, segmentColor, donutRadius, radius, isLevel1, isLastChild, isFirstChild, type} = props;
    const [state, dispatch] = useStateContext();
    const isMobile = (state.renderSize === 'small');

    const rotate = 180 - (item.startAngle + item.endAngle) / 2 / Math.PI * 180;
    const isSelected = parseInt(state[props.value]) === item.data.id &&
        !props.frozen && type !== 'objectives';
    const arc = isLevel1 ?
        d3Shape.arc()
                .innerRadius(donutRadius * radius)
                .outerRadius(donutRadius * (radius + 0.4))
                .padAngle(.04) :
        (isLastChild) ?
            d3Shape.arc()
                    .innerRadius(donutRadius * radius)
                    .outerRadius(donutRadius)
                    .endAngle(item.endAngle - .02) :
            (isFirstChild) ?
                d3Shape.arc()
                        .innerRadius(donutRadius * radius)
                        .outerRadius(donutRadius)
                        .startAngle(item.startAngle + .02) :
                d3Shape.arc()
                        .innerRadius(donutRadius * radius)
                        .outerRadius(donutRadius);

    return <g style={{cursor: isSelected || props.frozen ? 'default' : 'pointer'}}
            className={'donut-segment-layer'}
            onMouseOver={() => {
                if(!props.frozen && !isSelected && !isMobile){
                    return dispatch({type: 'hover', value: {id: item.data.id, type}});
                }
            }}
            onMouseOut={() => {
                if(!isMobile){
                    dispatch({type: 'hover', value: null});
                }
            }}
            onClick={() => {
                if(!(isLevel1 && !props.level1Clickable) && !props.frozen && !isSelected){
                    if(type === 'objectives'){
                        dispatch({type: 'setLTOutcome', value: item.data.id});
                    }else{
                        dispatch({type: props.value, value: item.data.id});
                    }
                }else if(isLevel1 && ! props.level1Clickable && !props.frozen && ! isSelected){
                    // Click on objective, select first LT outcome
                    if(item.children && item.children.length > 0){
                        dispatch({type: 'setLTOutcome', value: item.children[0].data.id});
                    }
                }
            }}>
        {isSelected && !(isLevel1 && !props.level1Clickable) ?
            <path fill={styles.colorBackground}
                    transform={`rotate (${-rotate})`}
                    d={props.level1Clickable ?
                        `M-${isMobile ? 15 : 25} ${donutRadius * (radius + 0.4) - 5}
                            L0 ${donutRadius * (radius + 0.4) * 1.1}
                            L${isMobile ? 15 : 25} ${donutRadius * (radius + 0.4) - 5}` :
                        `M-${isMobile ? 15 : 25} ${donutRadius - 5} L0 ${donutRadius * 1.1}
                            L${isMobile ? 15 : 25} ${donutRadius - 5}`}
                    filter={'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))'}/> : null}
        <path fill={segmentColor}
                d={arc(item)}
                strokeWidth={2}
                stroke={'white'}/>
        <DonutSegmentLabel item={item}
                isLevel1={isLevel1}
                arc={arc}
                value={props.value}
                numItems={props.numItems}
                onlyLongTerm={props.onlyLongTerm}
                frozen={props.frozen}
                donutRadius={donutRadius}
                radius={radius}/>
        {isSelected && !(isLevel1 && !props.level1Clickable) ?
            <path fill={segmentColor}
                    transform={`rotate (${-rotate})`}
                    d={props.level1Clickable ?
                        `M-${isMobile ? 15 : 25} ${donutRadius * (radius + 0.4) - (isMobile ? 1.7 : 2)}
                            L0 ${donutRadius * (radius + 0.4) * 1.1}
                            L${isMobile ? 15 : 25} ${donutRadius * (radius + 0.4) - (isMobile ? 1.7 : 2)}` :
                        `M-${isMobile ? 15 : 25} ${donutRadius - (isMobile ? 1.7 : 2)}
                            L0 ${donutRadius * 1.1}
                            L${isMobile ? 15 : 25} ${donutRadius - (isMobile ? 1.7 : 2)}`}
                    strokeWidth={2}
                    stroke={'white'}/> : null}
    </g>;
};

DonutSegment.defaultProps = {
    isFirstChild: false,
    isLastChild: false,
    level1Clickable: false,
    value: 'lt_id',
    numItems: 4
};

DonutSegment.propTypes = {
    type: PropTypes.string,
    item: PropTypes.object.isRequired,
    donutRadius: PropTypes.number,
    frozen: PropTypes.bool,
    isLastChild: PropTypes.bool,
    isFirstChild: PropTypes.bool,
    isLevel1: PropTypes.bool,
    radius: PropTypes.number,
    onlyLongTerm: PropTypes.bool,
    segmentColor: PropTypes.string,
    level1Clickable: PropTypes.bool,
    value: PropTypes.string,
    numItems: PropTypes.number
};

export {DonutSegment};
