import React, {useState, useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {StateContext, StateConsumer} from '../state-context.js';
import {urlRootReducer} from './url_reducer.js';
import {parseUrl} from './parse_url.js';
import {InnerUrlStateContext} from './inner-url-state-context.js';
import {SimpleEvent} from '../simple-event.js';
import {compileUrl} from './compile_url.js';
import {useStateContext} from '../state-context.js';

/*
Usage:
Simply include the component and attach some properties of the state to a part of the url, either path, query
or the hash. A new UrlStateContext inside a parent UrlStateContext will add to the existing URL. The
UrlStateContext also sets up a StateContext using the initialState and reducer props. So you also set up a
StateContext when using UrlStateContext and can access its values normally with a StateConsumer,
useStateContext() or withStateContext().

const PageComponent = function(props){
    const [state, dispatch] = useStateContext(['page]);
    return getPage(state.page, props.children);
}

<UrlStateContext path={['page']}>
    <PageComponent>
        <UrlStateContext path={['id']} query={['action', 'confirm']} hash={['anchor']}>
            <StateConsumer watch={['id]}>
                {(state, dispatch) => (<div>
                    <span class="button"
                            onClick={() => {dispatch({type: 'id', value: '2'}); }}>
                        Item 2
                    </span>
                    <Component item={state.id} />
                </div>)}
            </StateConsumer>
        </UrlStateContext>
    </PageComponent>
</UrlStateContext>

*/
let rootUrlStateDispatcher = () => {};

// compiles a URL based on the path, query and hash values provided and returns an action to dispatch in the
// UrlStateContext
const navigateUrlStateContext = (dispatch, path = [], query = {}, hash = []) => {
    const nextState = {path, query, hash};
    const url = compileUrl(nextState);
    window.history.pushState(nextState, 'Page', url);
    dispatch({type: 'urlParse', value: window.location.origin + url});
};

const UrlStateContext = function(props){
    // check whether we need to add the global context or if it's already there
    const [currentUrlState] = useStateContext('url-state-context');
    const [isRootNode] = useState(! currentUrlState['url-state-context']);

    useEffect(() => {
        const event = isRootNode ?
                new SimpleEvent(window, 'popstate', () => {
                    rootUrlStateDispatcher({type: 'urlParse', value: window.location.href});
                }) :
                null;

        return () => {
            // runs just before unmount and next useEffect cycle
            if(isRootNode){
                event.remove();
                rootUrlStateDispatcher = () => {};
            }
        };
    }, [isRootNode]);

    const content = <InnerUrlStateContext {...props}/>;

    if(isRootNode){
        return <StateContext
                initialState={{
                    'url-state-context': {
                        path: [],
                        query: [],
                        hash: [],
                        parsed: parseUrl(window.location.href)
                    }
                }}
                reducer={urlRootReducer}>
            <StateConsumer watch={['url-state-context']}>
                {(_, dispatch) => {
                    rootUrlStateDispatcher = dispatch;
                    return content;
                }}
            </StateConsumer>
        </StateContext>;
    }

    return content;
};

UrlStateContext.defaultProps = {
    path: [],
    query: [],
    hash: [],
    initialState: {}
};

UrlStateContext.propTypes = {
    path: PropTypes.array,
    query: PropTypes.array,
    hash: PropTypes.array,
    initialState: PropTypes.object,
    reducer: PropTypes.func
};

export {UrlStateContext, navigateUrlStateContext};
