import React, {useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {ComponentError} from '../../helpers/elements/component-error.js';
import {Loader} from '../../helpers/elements/loader.js';
import {useStateContext} from '../../helpers/state-context.js';
import {useModel} from '../../helpers/model/use_model.js';
import {ModelAuthUser} from '../../model/auth/user.js';

const AuthAccessValidator = function(props){
    const [, dispatch] = useStateContext(['page']);
    const resultSet = useModel(ModelAuthUser, {id: 'me'});

    useEffect(() => {
        if(resultSet.status === ModelAuthUser.Status.FAILED){
            // Could not fetch the user, redirect to sign in
            dispatch({type: 'page', value: 'sign-in'});
        }
    }, [resultSet.status, dispatch]);

    if(resultSet.status === ModelAuthUser.Status.SUCCESS){
        const user = resultSet.data[0];
        const valid = props.validateUser(user);
        if(! valid){
            if(props.showError){
                return <ComponentError title={props.errorMessage} message={props.errorMessage}/>;
            }
            return null;
        }

        return props.children;
    }

    return <Loader/>;
};

AuthAccessValidator.propTypes = {
    validateUser: PropTypes.func,
    errorTitle: PropTypes.string,
    errorMessage: PropTypes.string,
    showError: PropTypes.bool
};

AuthAccessValidator.defaultProps = {
    validateUser: (user) => (user != null),
    errorTitle: 'No permission',
    errorMessage: 'You don\'t have permission to access the requested resource',
    showError: true
};

export {AuthAccessValidator};
