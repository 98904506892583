import {UIButton} from '../button/button.js';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from '../../../helpers/use_translation.js';

const buttonStyle = {
    display: 'inline-block',
    padding: '8px 20px',
    cursor: 'pointer',
    color: 'white',
    borderRadius: 9
};

const buttonPrimary = {
    backgroundColor: '#0033A0'
};

const buttonLight = {
    backgroundColor: '#C0C3C9'
};

const IntroductionStep = function(props){
    const {setTour, setStep, steps, step} = props;
    const current = props.steps[step];
    const {getText} = useTranslation();
    const getTextL = (key) => (getText(key, false, props.language));
    return <>
        <span>{current.content}</span>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 5}}>
            <UIButton className={'zol-light'}
                    onClick={() => setTour(false)}
                    style={{...buttonLight, ...buttonStyle, marginRight: 5, padding: '4px 10px'}}>
                {getTextL('introduction-start-button-skip')}
            </UIButton>
            <div>
                {(step > 0 && (steps.length - 1) >= step) ?
                    <UIButton onClick={() => setStep((p) => p - 1)}
                            style={{...buttonStyle, ...buttonLight, marginRight: 5}}>
                        {getTextL('introduction-start-button-previous')}
                    </UIButton> :
                    null
                }
                {((props.steps.length - 1) === step) ?
                    <UIButton onClick={() => setTour(false)}
                            style={{...buttonStyle, ...buttonPrimary}}>
                        {getTextL('introduction-start-button-finish')}
                    </UIButton> :
                    <UIButton onClick={() => setStep((p) => p + 1)}
                            style={{...buttonStyle, ...buttonPrimary}}>
                        {getTextL('introduction-start-button-next')}
                    </UIButton>
                }
            </div>
        </div>
    </>;
};

IntroductionStep.propTypes = {
    setTour: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
    steps: PropTypes.array,
    step: PropTypes.number,
    language: PropTypes.string.isRequired
};

export {IntroductionStep};
