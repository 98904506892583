import React, {useEffect} from 'react';
import {PageObjectives} from './page/objectives.js';
import {PageCcp} from './page/ccp.js';
import {PageOee} from './page/oee.js';
import {PageSaved} from './page/saved.js';
import {useStateContext} from './helpers/state-context.js';
import {PageAdmin} from './page/admin/index.js';
import {PageSignIn} from './page/sign-in.js';
import {AuthAccessValidator} from './components/auth/access-validator.js';
import {ModelAuthToken} from './model/auth/token.js';

function App(){
    const [state, dispatch] = useStateContext(['page', 'renderSize', 'savedIndicators']);

    // save the state value 'savedIndicators' in the sessionStorage to make it persistent during this session
    useEffect(() => {
        sessionStorage.setItem('srf-saved-indicators', (state.savedIndicators || []).join(','));
    }, [state.savedIndicators]);

    useEffect(() => {
        let timerId = null;
        // Check if auth token is expired every 5 minutes if there is an token available.
        if(ModelAuthToken.hasToken()){
            const checkTokenExpired = () => {
                if(!ModelAuthToken.hasValidToken()){
                    // Reset session if token is expired
                    ModelAuthToken.signOut();
                    if(state.page === 'admin'){
                        // Redirect to sign-in page if you're currently on admin;
                        dispatch({type: 'page', value: 'sign-in'});
                    }
                }
            };
            timerId = setInterval(checkTokenExpired, 5 * 60 * 1000);
            checkTokenExpired();
        }

        return () => {clearInterval(timerId);};
    }, [state.page]);

    let currentPage = <PageObjectives/>;
    if(state.page === 'admin'){
        currentPage = <AuthAccessValidator>
            <PageAdmin/>
        </AuthAccessValidator>;
    }else if(state.page === 'sign-in'){
        currentPage = <PageSignIn/>;
    }else if(state.page === 'oee'){
        currentPage = <PageOee/>;
    }else if(state.page === 'ccp'){
        currentPage = <PageCcp/>;
    }else if(state.page === 'saved'){
        currentPage = <PageSaved/>;
    }

    return currentPage;
}

export {App};
