import {getElementPagePosition} from '../../../helpers/functions/get_element_page_position.js';

export const getStepPosition = (node, anchor) => {
    const pos = getElementPagePosition(node);
    const width = node.getBoundingClientRect().width;
    const height = node.getBoundingClientRect().height;
    let left = false;
    let top = false;
    if(anchor === 'left'){
        left = pos.left + width;
        top = pos.top + (height / 2);
    }else if(anchor === 'top'){
        left = pos.left + (width / 2);
        top = pos.top + height;
    }else if(anchor === 'right'){
        left = pos.left;
        top = pos.top + (height / 2);
    }else if(anchor === 'bottom'){
        left = pos.left + (width / 2);
        top = pos.top;
    }
    return [left, top];
};
