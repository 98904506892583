import React from 'react';
import {PropTypes} from 'prop-types';
import {useModel} from './use_model.js';
import {Loader} from '../elements/loader.js';
import {insertModelFeedback} from './insert_model_feedback.js';
import {ErrorBoundary} from '../elements/error_boundry.js';

/*  ModelConsumer takes a function as a child, it sends one argument, the data returned by the model.
 *  At least one prop is required, the model. Additinally you can provide your own callback function to
 *  retrieve the data from the model.
*/
const ModelConsumer = function(props){
    const callbackName = props.model.modelName + ': ' + props.callback.toString();
    if(typeof props.selectionCriteria === 'function'){
        console.error('Using a callback function for selectionCriteria in ModelConsumer is no longer' +
                'supported. Use ModelAndStateConsumer instead.');
    }
    const resultSet = useModel(props.model, props.selectionCriteria, props.callback);

    // It's possible the useModel returns data from previous props of this same ModelConsumer, in that case we
    // should ignore the data and consider the model to be inactive
    if(resultSet._callbackName !== callbackName){
        return <Loader size={50}/>;
    }

    if(props.processStatus){
        const feedback = insertModelFeedback(resultSet).feedback;

        if(feedback !== null && resultSet._previousResultSet &&
                resultSet._previousResultSet.status === props.model.Status.SUCCESS){
            // The model is loading new data, but we can still show the previous data instead of clearing out
            // the screen. This also keeps the child component(s) of this Consumer mounted, which improves
            // performance, allows transitions and provides an overal better user experience.
            return <ErrorBoundary>
                {props.children(resultSet._previousResultSet)}
                <Loader size={50}/>
            </ErrorBoundary>;
        }else if(feedback !== null){
            return feedback;
        }
    }

    return <ErrorBoundary>
        {props.children(resultSet)}
    </ErrorBoundary>;
};

ModelConsumer.defaultProps = {
    callback: (model) => (model.getData()),
    selectionCriteria: null,
    processStatus: true,
    newInstance: true
};

ModelConsumer.propTypes = {

    model: PropTypes.func.isRequired,
    callback: PropTypes.func,
    selectionCriteria: PropTypes.object,
    children: PropTypes.func.isRequired,
    processStatus: PropTypes.bool
};

export {ModelConsumer};
