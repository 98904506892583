import './modal.scss';
import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {UIModal} from './modal.js';
import {StateContext} from '../../../helpers/state-context.js';

const UIModalManager = function(props){
    const [modalContent, setModalContent] = useState(null);
    const [modalProps, setModalProps] = useState({
        visible: false,
        onClose: () => {}
    });

    const [setModal] = useState(() => ((content, properties = {}) => {
        setTimeout(() => {
            setModalContent(content);
            const nextProps = {
                visible: content !== null,
                ...props.defaultModalProps,
                ...properties,
                onClose: () => {
                    setModal(null, {visible: false});
                    if(properties.onClose){
                        properties.onClose();
                    }
                }
            };
            setModalProps(nextProps);
        }, 0);
    }));

    return <StateContext initialState={{setModal}}>
        {props.children}
        {modalProps.visible ?
            <UIModal {...modalProps}>
                {modalContent}
            </UIModal> :
            null
        }
    </StateContext>;
};

UIModalManager.propTypes = {
    stateName: PropTypes.string,
    // Note that changes to this prop during the lifecycle of the component will not be taken into account
    defaultModalProps: PropTypes.object
};

UIModalManager.defaultProps = {
    stateName: 'setModal',
    defaultModalProps: {}
};

export {UIModalManager};
