import {ModelOeeOutput} from '../../../model/oee_output.js';
import {ModelOeeIndicator} from '../../../model/oee_indicator.js';

export const list = [
    {
        id: 'oee_output',
        title: 'Outputs',
        model: ModelOeeOutput
    },
    {
        id: 'oee_indicator',
        title: 'Indicators',
        model: ModelOeeIndicator,
        foreign_key: 'fk_oee_output_id'
    }
];
