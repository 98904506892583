import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';

class ModelSrfLinkView extends ModelAuthenticatedXhr {
    static modelName = 'ModelSrfLinkView';

    static getSelect(input){
        const select = {
            select: input.select ?
                input.select :
                'item_type, fk_item_id, link_type, fk_link_id',
            filter: input.filter ?
                input.filter :
                '',
            language: input.language ?
                input.language :
                'en',
            order: input.order || ''
        };
        return select;
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf-link-view',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getSelect(input){
        return ModelSrfLinkView.getSelect(input);
    }

    getData(){
        return {status: this.status, data: this.data || []};
    }
}

export {ModelSrfLinkView};
