import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';

class ModelCcp extends ModelAuthenticatedXhr {
    static modelName = 'ModelCcp';

    static getSelect(input){
        return input;
    }

    static createItem(data, onSuccess = () => {}){
        this.saveData(ModelCcp, CONFIG.api_endpoint + 'ccp', 'POST', data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                        // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }), {contentType: 'params'};
    }

    static updateItem(id, data, onSuccess = () => {}){
        this.saveData(ModelCcp, CONFIG.api_endpoint + 'ccp/' + id, 'PATCH',
                data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                        // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static deleteItem(id, onSuccess = () => {}){
        this.saveData(ModelCcp, CONFIG.api_endpoint + 'ccp/' + id, 'DELETE',
                null,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                });
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'ccp',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getEndpoint(){
        return this.endpoint + (this.select.id ? '/' + this.select.id : '');
    }

    getSelect(input){
        return ModelCcp.getSelect(input);
    }

    getData(){
        let result = [];
        if(this.status === ModelCcp.Status.SUCCESS){
            if(this.data){
                result = [...this.data];
            }
        }
        return {status: this.status, data: result};
    }
}

export {ModelCcp};
