import {UIButton} from '../button/button.js';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from '../../../helpers/use_translation.js';

const IntroductionModal = function(props){
    const {setTour} = props;
    const {getText} = useTranslation();
    return <div className={'zol-introduction-modal'}>
        <h3>{getText('introduction-start-title')}</h3>
        <p>{getText('introduction-start-description')}</p>
        <div className={'modal-buttons'}>
            <UIButton className={'zol-light'} onClick={() => setTour(false)}>
                {getText('introduction-start-button-skip')}
            </UIButton>
            <UIButton style={{}} onClick={() => setTour(true)}>
                {getText('introduction-start-button-start')}
            </UIButton>
        </div>
    </div>;
};

IntroductionModal.propTypes = {
    setTour: PropTypes.func.isRequired
};

export {IntroductionModal};
