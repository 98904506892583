import {useModelFeedback} from '../model/use_model_feedback.js';
import {ModelSrfLinkView} from '../../model/srf-link-view.js';
import {getLinkFilter} from './get_link_filter.js';
import {useStateContext} from '../state-context.js';
import {sortFilter} from './sort_filter.js';

const useLinkData = function(returnPreviousWhileLoading = false){
    const [state] = useStateContext(['gcm', 'reg_priorities', 'sdg', 'language']);

    const filterGcm = getLinkFilter(state, ['gcm']);
    const resultSet = {};
    resultSet.gcm = useModelFeedback(ModelSrfLinkView,
            {
                select: 'gcm_objective_id as id, gcm_objective_num as num, gcm_objective_title as title',
                filter: 'gcm_objective_id ne null' + (filterGcm ? ' and ' + filterGcm : ''),
                language: state.language
            });

    const filterSdg = getLinkFilter(state, ['sdg']);
    resultSet.sdg = useModelFeedback(ModelSrfLinkView,
            {
                select: 'sdg_target_id, sdg_target_num, sdg_target_title as sdg_target, ' +
                        'sdg_goal_id, sdg_goal_title as sdg_goal',
                filter: 'sdg_target_id ne null' + (filterSdg ? ' and ' + filterSdg : ''),
                language: state.language
            });

    const filterRp = getLinkFilter(state, ['reg_priorities']);
    resultSet.rp = useModelFeedback(ModelSrfLinkView,
            {
                select: 'rp_priority_id, rp_priority_num, rp_priority_title as rp_priority, rp_region_id, ' +
                        'rp_region_title as rp_region, rp_region_code, rp_region_name',
                filter: 'rp_priority_id ne null' + (filterRp ? ' and ' + filterRp : ''),
                order: 'rp_region,rp_priority_num',
                language: state.language
            });

    let status = ModelSrfLinkView.Status.SUCCESS;
    let feedback = null;
    const result = ['rp', 'sdg', 'gcm'].reduce((obj, key) => {
        const data = resultSet[key].status === ModelSrfLinkView.Status.SUCCESS ?
                resultSet[key].data :
                (resultSet[key]._previousResultSet ? resultSet[key]._previousResultSet.data : []);
        obj[key + 'List'] = sortFilter(data, key.replace('rp', 'regional'));
        if(resultSet[key].status !== ModelSrfLinkView.Status.SUCCESS){
            status = status === ModelSrfLinkView.Status.FAILED ?
                    ModelSrfLinkView.Status.FAILED : resultSet[key].status;
            feedback = resultSet[key].feedback;
        }
        return obj;
    }, {});

    if(feedback && ! returnPreviousWhileLoading && status !== ModelSrfLinkView.Status.FAILED){
        return feedback;
    }
    return result;
};

export {useLinkData};
