import './index.scss';
import React, {useState} from 'react';
import {useStateContext} from '../../helpers/state-context.js';
import {SearchInput} from '../../components/search/input.js';
import {useSearchData} from '../../components/search/use_search_data.js';
import {SearchDropdown} from '../../components/search/dropdown.js';
import {useTranslation} from '../../helpers/use_translation.js';

function Search(props){
    const [{searchText, renderSize, initItem}, dispatch] =
            useStateContext(['searchText', 'renderSize', 'initItem']);
    const {getText} = useTranslation();
    const [isShowResults, setIsShowResults] = useState(false);
    const searchResults = useSearchData(searchText);
    const isMobile = ['small', 'medium'].includes(renderSize);

    const handleSearch = (inputValue) => {
        const value = inputValue.length > 2 ? inputValue : null;
        if(initItem === null){
            setIsShowResults(value ? true : false);
            dispatch({type: 'searchText', value});
        }
    };

    const toggleSearchResult = (isMakeVisible) => {
        setIsShowResults(isMakeVisible && searchText ? true : false);
    };

    return <div className={'search-container'} onClick={() => {toggleSearchResult(true);}}>

        <SearchInput onSearch={handleSearch}
                value={initItem?.searchText || searchText || ''}
                isActive={isShowResults}
                placeholder={getText('header-search-placeholder')}/>

        {isShowResults ?
            <SearchDropdown options={searchResults}
                    isMobile={isMobile}
                    onClose={() => {toggleSearchResult(false);}}/> :
            null
        }
    </div>;
}

export {Search};
