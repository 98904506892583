import React, {useEffect} from 'react';
import './home.scss';
import './saved.scss';
import {ViewMain} from '../view/main.js';
import {useModelFeedback} from '../helpers/model/use_model_feedback.js';
import {ExportContainer} from '../components/export/container.js';
import {IndicatorPath} from '../components/output/indicator-path.js';
import {IndicatorItem} from '../components/output/indicator-item.js';
import {StateContext, StateConsumer, useStateContext} from '../helpers/state-context.js';
import {ModelSrf} from '../model/srf.js';
import {ReactComponent as IconBack} from '../assets/icons/arrow-back-outline.svg';
import {UIButton} from '../components/ui/button/button.js';
import {FileTextOutlined} from '@ant-design/icons';
import {useTranslation} from '../helpers/use_translation.js';

const PageSaved = function(props){
    const [{savedIndicators, language}] = useStateContext(['savedIndicators', 'language']);
    const stIndicators = savedIndicators.filter((i) => (typeof i === 'string' && i.substring(0, 2) === 'st'))
            .map((i) => (parseInt(i.substring(2))));
    const indicators = savedIndicators.filter((i) => (typeof i === 'number'));
    const resultSet = useModelFeedback(ModelSrf,
            {
                select: 'objective_id as objective_num, objective_title, ' +
                        'lt_outcome_num, lt_outcome_title, ' +
                        'st_outcome_num, st_outcome_title, output_num, output_title, ' +
                        'indicator_title as title, indicator_id as id, indicator_num as num',
                filter: indicators.length === 0 ?
                    'indicator_id eq 0' :
                    'indicator_id eq ' + indicators.join(' or indicator_id eq '),
                language
            });
    const resultSetST = useModelFeedback(ModelSrf,
            {
                select: 'objective_id as objective_num, objective_title, ' +
                        'lt_outcome_num, lt_outcome_title, ' +
                        'st_outcome_num, st_outcome_title, ' +
                        'st_indicator_id, st_indicator_id as id, ' +
                        'st_indicator_is_core_kpi as is_core_kpi, ' +
                        'st_indicator_num as num, st_indicator_title as title',
                filter: stIndicators.length === 0 ?
                    'st_indicator_id eq 0' :
                    'st_indicator_id eq ' + stIndicators.join(' or st_indicator_id eq '),
                language
            });
    const {getText} = useTranslation();

    // Scroll to top of page when initially opened
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const resFeedback = resultSet.feedback || resultSetST.feedback;
    const feedback = resFeedback ?
        <div style={{height: '100px'}}>
            {resFeedback}
        </div> :
        null;
    const items = resFeedback ?
        [] :
        [...resultSet.data, ...resultSetST.data].sort((a, b) => (
            a.num.substring(0, 3) === b.num.substring(0, 3) ?
                (isNaN(a.num.substring(3, 4)) === isNaN(b.num.substring(3, 4)) ?
                    (a.num < b.num ? -1 : 1) :
                    isNaN(a.num.substring(3, 4)) ? -1 : 1
                ) :
                (a.num < b.num ? -1 : 1)
        ));

    const goHome = () => {
        window.history.back();
        // dispatch({type: 'page', value: 'objectives'});
    };

    return <ViewMain type={'saved'}>
        <span className="zol-button zol-btn-home" onClick={goHome}>
            <IconBack/> {getText('saved-indicators-back-home')}
        </span>
        <StateContext initialState={{exportImage: ''}}>
            <ExportContainer id='zol-export' extension='pdf' scale={0.7}>
                <div className="zol-page-saved">

                    <h1>{getText('saved-indicators-page-title')}</h1>

                    {items.map((item) => (
                        <div key={item.id} className="saved-indicator">
                            <IndicatorPath indicator={item} type={item.st_indicator_id ? 'ST' : 'IND'}/>
                            <IndicatorItem id={item.id}
                                    title={item.title}
                                    num={item.num}
                                    isCoreKpi={item.is_core_kpi}
                                    type={item.st_indicator_id ? 'ST' : 'IND'}/>
                        </div>
                    ))}

                    {feedback === null ?
                        (items.length === 0 ?
                            <p>{getText('saved-indicators-none-saved')}</p> :
                            null
                        ) :
                        feedback
                    }

                    <StateConsumer watch={['exportImage']}>
                        {(state, dispatch) => (
                            <UIButton className={'zol-primary zol-srf-export'}
                                    title={getText('saved-indicators-button-export-hint')}
                                    onClick={() => {
                                        dispatch({type: 'exportImage', value: 'zol-export'});
                                    }}>
                                <FileTextOutlined style={{fontSize: 16, marginRight: 5}}/>
                                {getText('saved-indicators-button-export')}
                            </UIButton>
                        )}
                    </StateConsumer>
                </div>
            </ExportContainer>
        </StateContext>
    </ViewMain>;
};

export {PageSaved};
