import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {StateContext, StateConsumer} from '../state-context.js';
import {validateFormElement} from './validation.js';

const getAvailableNames = function(elements){
    const names = [];
    for(const element of elements){
        names.push(element.name);
        if(element.children){
            names.push(...getAvailableNames(element.children));
        }
    }
    return names;
};

const getReducer = (callback, elements) => ((state, action) => {
    const availableNames = getAvailableNames(elements);

    // Set one value
    if(action.type === '__form-setValue'){
        const nextValues = {...state['__form-values']};
        if(availableNames.includes(action.name)){
            nextValues[action.name] = action.value;
        }

        callback(nextValues);
        // if something changes, always create a new object
        return {
            ...state,
            '__form-values': nextValues
        };

    // Set multiple values at once
    }else if(action.type === '__form-setValues'){
        if(typeof action.value !== 'object'){
            console.error('Dispatch action.value is expected to be an object for type __form-setValues');
            return {...state};
        }
        const nextValues = {};
        for(const name of availableNames){
            if(action.value[name]){
                nextValues[name] = action.value[name];
            }else{
                nextValues[name] = null;
            }
        }

        callback(nextValues);
        // if something changes, always create a new object
        return {
            ...state,
            '__form-values': nextValues
        };

    // Set all error messages at once
    }else if(action.type === '__form-setErrors'){
        const errors = {};
        for(const name of availableNames){
            if(action.value[name]){
                errors[name] = action.value[name];
            }else{
                errors[name] = null;
            }
        }

        return {
            ...state,
            '__form-errors': errors
        };
    }

    // by default return the same object: nothing changes
    return state;
});

const setValues = function(instance, values, reValidate = false){
    instance.__dispatcher({type: '__form-setValues', value: values});

    if(reValidate){
        validate(instance, null, values, true);
    }
};

const setValue = function(instance, name, value, reValidate = false){
    instance.__dispatcher({type: '__form-setValue', name, value});

    const newValue = {};
    newValue[name] = value;
    if(reValidate){
        validate(instance, [name], {...instance.__values, ...newValue}, true);
    }
};

const setError = function(instance, name, error){
    const errors = {...instance.__errors};
    errors[name] = [error];
    instance.__dispatcher({type: '__form-setErrors', value: errors});
};

const getProcessedValues = function(instance, elements, values = null){
    if(values === null){
        values = instance.__values;
    }
    const nextValues = {...values};
    for(const element of elements){
        if(element.processValue){
            nextValues[element.name] = element.processValue(nextValues[element.name], nextValues);
        }
        if(element.children){
            // recursive processing of children
            const childValues = getProcessedValues(instance, element.children, values);
            for(const child of element.children){
                nextValues[child.name] = childValues[child.name];
            }
        }
    }
    return nextValues;
};

const validateElements = function(elements, values, names, errors){
    let formValid = true;
    for(const element of elements){
        if(names === null || names.includes(element.name)){
            const elementErrors = validateFormElement(element, values);
            if(elementErrors.length > 0){
                errors[element.name] = elementErrors;
                formValid = false;
            }else{
                errors[element.name] = null;
            }
        }
        if(element.children){
            formValid = formValid && validateElements(element.children, values, names, errors);
        }
    }
    return formValid;
};

// validates the form values
// when names is provided you can select a subset of form elements to validate
// when values are provided, these values will be used instead of the current formState. Note that the
// elements values will be updates, but not the state.
const validate = function(elements, instance, names = null, values = null, setFeedback = true){
    names = names === null ? null : (names instanceof Array ? names : [names]);
    values = values === null ? instance.__values : values;
    values = getProcessedValues(instance, elements, values);
    const errors = {};
    const formValid = validateElements(elements, values, names, errors);
    if(setFeedback){
        instance.__dispatcher({type: '__form-setErrors', value: {...errors}});
    }

    return formValid;
};

const useForm = function(formElements){
    const [elements] = useState(formElements);

    const initialFormValues = {};
    for(const element of elements){
        initialFormValues[element.name] = element.value;
    }

    const [pendingActions] = useState([]);
    const [instance] = useState({
        __values: initialFormValues,
        __errors: {},
        __dispatcher: (action) => {pendingActions.push(action);}
    });

    instance.validate = instance.validate ||
            ((names = null, values = null, setFeedback = true) => (
                validate(elements, instance, names, values, setFeedback)
            ));
    instance.setValue = instance.setValue ||
            ((name, value, reValidate = false) => setValue(instance, name, value, reValidate));
    instance.setValues = instance.setValues ||
            ((values, reValidate = false) => setValues(instance, values, reValidate));
    instance.getProcessedValues = instance.getProcessedValues ||
            ((values = null) => getProcessedValues(instance, elements, values));
    instance.setError = instance.setError ||
            ((name, error) => setError(instance, name, error));
    // todo: need the option of getting processed values
    instance.getValues = instance.getValues ||
            (() => {return {...instance.__values};});
    instance.getValue = instance.getValue ||
            ((name) => (instance.__values[name] || null));
    instance.getErrors = instance.getErrors ||
            (() => {return {...instance.__errors};});

    const initialState = {
        '__form-values': initialFormValues,
        '__form-errors': {}
    };

    instance.Component = instance.Component || (
        (props) => (
            <StateContext initialState={initialState}
                    reducer={getReducer(props.onChange, elements)}>
                <StateConsumer watch={['__form-values', '__form-errors']}>
                    {(state, dispatch) => {
                        instance.__values = state['__form-values'];
                        instance.__errors = state['__form-errors'];
                        instance.__dispatcher = dispatch;
                        // When the dispatcher was not yet set, but actions were dispatched from useForm, they
                        // are stored in pendingActions. Now we can dispatch them. Setting a timeout so that
                        // they will be dispatch after the component is rendered.
                        setTimeout(() => {
                            while(pendingActions.length > 0){
                                const actions = pendingActions.splice(0, 1);
                                dispatch(actions[0]);
                            }
                        }, 0);
                        if(typeof props.children === 'function'){
                            return props.children(instance);
                        }
                        return props.children;
                    }}
                </StateConsumer>
            </StateContext>
        )
    );
    instance.Component.defaultProps = {
        onChange: () => {}
    };
    instance.Component.propTypes = {
        onChange: PropTypes.func
    };

    return instance;
};

export {useForm};
