import {ModelSrf} from '../../model/srf.js';
import {ModelOeeOutput} from '../../model/oee_output.js';
import {ModelOeeIndicator} from '../../model/oee_indicator.js';
import {ModelCcp} from '../../model/ccp.js';
import {ModelCcpOutput} from '../../model/ccp_output.js';
import {ModelCcpIndicator} from '../../model/ccp_indicator.js';
import {ModelSrfLinkView} from '../../model/srf-link-view.js';
import {useModel} from '../../helpers/model/use_model.js';
import {useLinkData} from '../../helpers/srf/use_link_data.js';
import {useStateContext} from '../../helpers/state-context.js';

const selectSearchItems = {
    lt_outcome: 'lt_outcome_id, lt_outcome_title as title, lt_outcome_num as num',
    st_outcome: 'st_outcome_id, st_outcome_title as title, lt_outcome_id, st_outcome_num as num',
    st_indicator: 'st_outcome_id, st_indicator_title as title, lt_outcome_id, st_indicator_num as num',
    output: 'output_title as title, st_outcome_id, lt_outcome_id, output_num as num',
    indicator: 'indicator_title as title, output_num, st_outcome_id, lt_outcome_id, indicator_num as num'
};

const otherFrameworks = {
    oee_output: [ModelOeeOutput, 'title', 'description'],
    oee_indicator: [ModelOeeIndicator, 'title'],
    ccp: [ModelCcp, 'title'],
    ccp_output: [ModelCcpOutput, 'title'],
    ccp_indicator: [ModelCcpIndicator, 'title']
};

const linkSearchItems = {
    gcmList: {
        search_keys: ['id', 'title'],
        id: 'id',
        type: 'gcm_objective'
    },
    sdgList: {
        search_keys: ['sdg_goal', 'sdg_target_num', 'sdg_target'],
        id: 'sdg_target_id',
        type: 'sdg_target'
    },
    rpList: {
        search_keys: ['rp_region', 'rp_priority_num', 'rp_priority'],
        id: 'rp_priority_id',
        type: 'rp_priority'
    }
};

// TODO: This can be simplified by using the srf-link-view endpoint more effectively
function useSearchData(searchText){
    searchText = searchText?.replace(/'/g, '');
    const [{language}] = useStateContext(['language']);
    const resultSetLinks = useModel(ModelSrfLinkView,
            {select: 'sdg_target_id as sdg_target, rp_priority_id as rp_priority, ' +
            'gcm_objective_id as gcm_objective'});
    const data = {
        lt_outcome: [],
        st_outcome: [],
        output: [],
        st_indicator: [],
        indicator: [],
        gcmList: [],
        sdgList: [],
        rpList: []
    };
    Object.keys(otherFrameworks).forEach((item) => {data[item] = {};});
    // DATA SEARCH
    // Note that when no search text is provided it will try to match on the value 'null', which will not
    // match anything, so we are not wasting too much bandwith there.
    for(const item of Object.keys(selectSearchItems)){
        const isNum = `${item}_num li '${searchText}' or `;
        const isTitle = (item === 'oee_output' ? '' : item + '_') + `title li '${searchText}'`;
        // Calling hook here is allowed as the for loop will always run over the same items, so hook are
        // guaranteed to execute in the same order
        /* eslint-disable-next-line */
        const searchData = useModel(ModelSrf, {
            select: selectSearchItems[item],
            filter: isNum + isTitle,
            language
        });
        if(searchData.status === ModelSrf.Status.SUCCESS){
            data[item] = searchData.data.map((i) => {
                const node = {...i, topic: item};
                const prefix = i.num ? `${i.num} >` : '';
                node.title = prefix + i.title;
                return node;
            });
        }
    };

    for(const [type, options] of Object.entries(otherFrameworks)){
        const lang = (language === 'en' ? '' : '_' + language);
        const [model, ...fields] = options;
        const num = ['ccp_output', 'ccp_indicator'].includes(type) ? 'id as num' : 'num';
        const idsFields = {
            oee_indicator: 'fk_oee_output_id',
            ccp_output: 'id as output_id, ccp_id',
            ccp_indicator: 'output_id'
        };
        const id = idsFields[type] ? idsFields[type] + ' as id' : 'id';
        /* eslint-disable-next-line */
        const result = useModel(model, {
            select: 'title' + lang + ', ' + num + ', ' + id,
            filter: fields.map((field) => (field + lang + ' li \'' + searchText + '\'')).join(' or ')
        });
        data[type] = result.status === model.Status.SUCCESS ?
                result.data.map((i) => {return {...i, topic: type};}) : [];
        if(type === 'ccp_indicator'){
            // get the ccp priority id as the id of the indicator
            data[type].forEach((ind) => {
                const out = data.ccp_output.find((out) => (out.output_id === ind.id));
                if(out){
                    ind.id = out.id;
                }
            });
        }
    }

    // LINKS SEARCH
    const linksFull = useLinkData();
    const linksActive = (resultSetLinks.status === ModelSrf.Status.SUCCESS) ? resultSetLinks.data : [];
    for(const link of Object.keys(linkSearchItems)){
        const linkItem = linkSearchItems[link];
        const linkData = linksFull[link] || [];
        const list = linkData
                ?.filter((item) => {
                    // Check if the link is active at any place;
                    const searchActive = linksActive.some((activeLinkItem) => (
                        activeLinkItem[linkItem.type] === item[linkItem.id]
                    ));
                    // check if one of the search link titles includes search value;
                    const searchFound = linkItem.search_keys.some((key) => (
                        item[key]?.toString().toLowerCase().includes(searchText?.toLowerCase())
                    ));

                    return (searchFound && searchActive);
                })
                ?.map((item) => {
                    return {
                        id: item[linkItem.id],
                        title: linkItem.search_keys.reduce((title, searchKey, key) => (
                            title + ((key !== 0) ? ' > ' : '') + item[searchKey]
                        ), ''),
                        topic: link
                    };
                });
        data[link] = list;
    }
    delete data.rpList;

    return data;
}

export {useSearchData};
