import React from 'react';
import {CONFIG} from '../../config.js';
import {parseUrl} from '../../helpers/url-state-context/parse_url.js';

const AdminDownloads = function(props){
    const files = ['objectives', 'oee', 'ccp'];
    const languages = {Engish: '', French: '_fr', Spanish: '_es'};

    const url = parseUrl(window.location.href);
    const status = url.query.hasOwnProperty('status') ? url.query.status : null;

    return <div>
        {status === 'error' ?
            <h2 className="zol-error">File upload failed</h2> :
            null
        }
        {status === 'success' ?
            <h2 className="zol-success">File changed</h2> :
            null
        }
        {files.map((file) => (
            Object.keys(languages).map((lang) => (
                <form key={file + lang}
                        action={CONFIG.api_endpoint + 'srf-admin/downloads/' + file + languages[lang]}
                        method="POST"
                        encType="multipart/form-data">
                    <br/>
                    <h4>Excel download {file} in {lang}</h4>
                    <a href={CONFIG.api_endpoint + 'srf-admin/downloads/' + file + languages[lang]}
                            target="_blank" rel="noreferrer">
                        Current file
                    </a>
                    <br/><br/>
                    <input type="hidden" name="token" value={sessionStorage.getItem('auth_token')}/>
                    <input type="file" name="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                    <br/><br/>
                    <input type="submit" value="Replace current file"/>
                </form>
            ))
        ))}
    </div>;
};

export {AdminDownloads};
