import React from 'react';
import {UIButton} from '../ui/button/button.js';
import {ModelAuthToken} from '../../model/auth/token.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelAuthUser} from '../../model/auth/user.js';
import {useStateContext} from '../../helpers/state-context.js';

const AdminHeader = function(props){
    const [, dispatch] = useStateContext();
    const resultSet = useModelFeedback(ModelAuthUser, {id: 'me'});

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const logout = () => {
        ModelAuthToken.signOut();
        dispatch({type: 'page', value: 'sign-in'});
    };

    const user = resultSet.data[0].email;
    return <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h1>Admin</h1>
        <div>
            <span style={{marginRight: 10, fontWeight: 'bold'}}>{user}</span>
            <UIButton onClick={logout}>
                Log out
            </UIButton>
        </div>
    </div>;
};

export {AdminHeader};
