import {ReactComponent as IconSdg1} from './sdgs/sdg1.svg';
import {ReactComponent as IconSdg2} from './sdgs/sdg2.svg';
import {ReactComponent as IconSdg3} from './sdgs/sdg3.svg';
import {ReactComponent as IconSdg4} from './sdgs/sdg4.svg';
import {ReactComponent as IconSdg5} from './sdgs/sdg5.svg';
import {ReactComponent as IconSdg6} from './sdgs/sdg6.svg';
import {ReactComponent as IconSdg7} from './sdgs/sdg7.svg';
import {ReactComponent as IconSdg8} from './sdgs/sdg8.svg';
import {ReactComponent as IconSdg9} from './sdgs/sdg9.svg';
import {ReactComponent as IconSdg10} from './sdgs/sdg10.svg';
import {ReactComponent as IconSdg11} from './sdgs/sdg11.svg';
import {ReactComponent as IconSdg12} from './sdgs/sdg12.svg';
import {ReactComponent as IconSdg13} from './sdgs/sdg13.svg';
import {ReactComponent as IconSdg14} from './sdgs/sdg14.svg';
import {ReactComponent as IconSdg15} from './sdgs/sdg15.svg';
import {ReactComponent as IconSdg16} from './sdgs/sdg16.svg';
import {ReactComponent as IconSdg17} from './sdgs/sdg17.svg';

export const sdgMapping = {
    1: IconSdg1,
    2: IconSdg2,
    3: IconSdg3,
    4: IconSdg4,
    5: IconSdg5,
    6: IconSdg6,
    7: IconSdg7,
    8: IconSdg8,
    9: IconSdg9,
    10: IconSdg10,
    11: IconSdg11,
    12: IconSdg12,
    13: IconSdg13,
    14: IconSdg14,
    15: IconSdg15,
    16: IconSdg16,
    17: IconSdg17
};
