class FormatString {
    static toTitleCase(phrase){
        if(!phrase || typeof phrase !== 'string'){
            return phrase;
        }

        return phrase
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join('-');
    }

    static removeTrailingCharacters(string){
        return string.replace(/[A-Za-z]+$/, '');
    }

    static truncate(string, length, ellipsis = '...'){
        if(string && string.length > length){
            return string.substring(0, length).trim() + ellipsis;
        }
        return string;
    }
};

export {FormatString};
