import React from 'react';
import PropTypes from 'prop-types';
import {useStateContext} from '../../helpers/state-context.js';
import {useTranslation} from '../../helpers/use_translation.js';

const ShortTermOutcomeNavigation = function(props){
    const {stOutcomes, type} = props;
    const {getText} = useTranslation();
    const [state, dispatch] = useStateContext();

    const shortTermCount = stOutcomes.length;
    const shortTermIndex = stOutcomes.findIndex((item) => (
        item.id === parseInt(state.st_id)
    ));

    const isActiveArrow = (type) => {
        if(shortTermCount < 2){
            return false;
        }else if(type === 'left'){
            if(shortTermIndex !== 0){
                return true;
            }else{
                return false;
            }
        }else if(type === 'right'){
            if(shortTermIndex !== (shortTermCount - 1)){
                return true;
            }else{
                return false;
            }
        }
    };

    if(type === 'back'){
        return <div className={`zol-navigation-arrow-active zol-navigation-arrow-up pdf-export-hide`}
                onClick={() => {
                    dispatch({type: 'setSTOutcome', value: null});
                }}>
            <span className="zol-arrow zol-arrow-up">&#8593;</span>
            <p>{getText('st-outcome-navigation-back')}</p>
        </div>;
    }else{
        const posArrow = 50 - 0.5 * 30;
        // the height of the top line (above the horizontal line) minus half of the circle
        return <>
            <div className={`zol-navigation-arrow${isActiveArrow('left') ?
                '-active' : '-inactive'} zol-navigation-arrow-left pdf-export-hide`}
                    style={{marginTop: posArrow + 'px'}}
                    onClick={() => {
                        if(isActiveArrow('left')){
                            const newItem = stOutcomes[shortTermIndex - 1];
                            dispatch({type: 'setSTOutcome', value: newItem.id});
                        }else{
                            return null;
                        }
                    }}>
                <span className="zol-arrow">&#8249;</span>
                {isActiveArrow('left') ?
                    <p>{stOutcomes[shortTermIndex - 1]?.num}</p> : null}
            </div>
            <div className={`zol-navigation-arrow${isActiveArrow('right') ?
                '-active' : '-inactive'} zol-navigation-arrow-right pdf-export-hide`}
                    style={{marginTop: posArrow + 'px'}}
                    onClick={() => {
                        if(isActiveArrow('right')){
                            const newItem = stOutcomes[shortTermIndex + 1];
                            dispatch({type: 'setSTOutcome', value: newItem.id});
                        }else{
                            return null;
                        }
                    }}>
                <span className="zol-arrow">&#8250;</span>
                {isActiveArrow('right') ?
                    <p>{stOutcomes[shortTermIndex + 1]?.num}</p> : null}
            </div>
        </>;
    };
};

ShortTermOutcomeNavigation.defaultProps = {
    stOutcomes: []
};

ShortTermOutcomeNavigation.propTypes = {
    stOutcomes: PropTypes.array,
    type: PropTypes.string.isRequired
};

export {ShortTermOutcomeNavigation};
