import React from 'react';
import {PropTypes} from 'prop-types';
import {useDonutColor} from '../../../helpers/srf/use_donut_color.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const OverviewMobileDetails = function(props){
    const {getText} = useTranslation();
    const {objectiveColor} = useDonutColor();

    const selectedStyle = (props.type !== 'overview' && props.selected == props.id) ?
        {backgroundColor: `rgb(${objectiveColor?.join(',')})`,
            borderRadius: '20px 20px 0 0',
            color: 'white'} :
        {};

    return <div className={`st-mobile-details-menu-item`}
            style={selectedStyle}
            onClick={() => props.onClick(props.id)}>
        <span className={`st-mobile-details-menu-item-count`}>
            {props.count || getText('st-outome-overview-count-empty')}
        </span>
        <span className={`st-mobile-details-menu-item-title`}>
            {props.title}
        </span>
    </div>;
};

OverviewMobileDetails.defaultProps = {
    title: '',
    id: '',
    clickable: false,
    onClick: () => {},
    selected: '',
    type: ''
};

OverviewMobileDetails.propTypes = {
    count: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
    clickable: PropTypes.bool,
    onClick: PropTypes.func,
    selected: PropTypes.string
};

export {OverviewMobileDetails};
