import React, {useEffect} from 'react';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {ModelSrfAdmin} from '../../../model/srf_admin.js';
import {UITable} from '../../ui/table/table.js';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {useStateContext} from '../../../helpers/state-context.js';
import {UIButton} from '../../ui/button/button.js';

const AdminUserList = function(props){
    const [, dispatch] = useStateContext();
    const resultSet = useModelFeedback(ModelSrfAdmin);
    useEffect(() => {
        dispatch({type: 'category', value: null});
    }, []);
    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const columns = [
        {
            title: 'Email',
            key: 'email',
            width: 10,
            value: (row) => row.email
        },
        {
            title: 'Action',
            key: 'action',
            value: (row) => row,
            width: 1,
            renderCell: (_, record) => <>
                <EditOutlined style={{cursor: 'pointer', marginRight: 10, fontSize: 20}}
                        onClick={() => {
                            dispatch({type: 'setValues', value: {subpage: 'edit', id: record.id}});
                        }}/>

                <DeleteOutlined style={{cursor: 'pointer', fontSize: 20}}
                        onClick={() => {
                            // TODO: have nicer messages than the standard dialogues
                            if(window.confirm(`Are you sure you want to delete this user(${record.email})?`)){
                                ModelSrfAdmin.deleteItem(record.id);
                            }
                        }}/>
            </>
        }
    ];
    return <>
        <UIButton className={'zol-light'}
                onClick={() => {dispatch({type: 'subpage', value: 'new'});}}
                style={{margin: '20px 0', float: 'right', padding: '10px 20px'}}>
            New user
        </UIButton>
        <UITable columns={columns} data={resultSet.data}/>
    </>;
};

export {AdminUserList};
