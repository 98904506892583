import React from 'react';
import PropTypes from 'prop-types';
import {useStateContext} from '../../helpers/state-context.js';
import {getHighlightedText} from '../search/get_highlighted_text.js';
import {ReactComponent as RightArrowIcon} from '../../assets/icons/icon-arrow.svg';
// import {ReactComponent as IconBook} from '../../assets/icons/book.svg';
import {ReactComponent as IconStar} from '../../assets/icons/star.svg';
import {ReactComponent as IconTrash} from '../../assets/icons/trash-2-outline.svg';
// import {IndicatorDetails} from './indicator-details.js';
import './output.scss';
import {useTranslation} from '../../helpers/use_translation.js';
import {ReactComponent as IconCoreKpi} from '../../assets/icons/core-kpi.svg';

const IndicatorItem = function(props){
    const [state, dispatch] = useStateContext(['searchText', 'setModal', 'savedIndicators', 'page']);
    const {getText} = useTranslation();

    const toggleSavedIndicator = () => {
        const nextIndicatorIds = [...state.savedIndicators];
        const id = props.type === 'ST' ? 'st' + props.id : props.id;
        const pos = nextIndicatorIds.indexOf(id);
        if(pos === -1){
            nextIndicatorIds.push(id);
        }else{
            nextIndicatorIds.splice(pos, 1);
        }
        dispatch({type: 'savedIndicators', value: nextIndicatorIds});
    };
    const removeIndicator = () => {
        if(window.confirm(getText('saved-indicators-confirm-remove'))){
            toggleSavedIndicator();
        }
    };

    // const openIndicatorDetails = () => {
    //     const modalProps = {
    //         title: getText('indicator-details-title') + ' > ' + props.num,
    //         width: '90vw',
    //         height: '100vh',
    //         className: 'zol-indicator-details'
    //     };
    //     state.setModal(<IndicatorDetails id={props.id}/>, modalProps);
    // };
    const isIndicatorSaved = state.savedIndicators.includes(props.id);
    const isPageSaved = state.page === 'saved';

    return <div className={props.className}>
        <span className={'toggle-box-bullit-point' + (props.isCoreKpi ? ' core-kpi' : '')}>
            <RightArrowIcon/>
            {props.isCoreKpi ? <IconCoreKpi title="This is a core KPI"/> : null}
        </span>
        <span className="toggle-box-title">
            <strong className={props.isCoreKpi ? ' core-kpi' : ''}>{props.num}</strong><br/>
            {getHighlightedText(props.title, state.searchText)}
            <div className="indicator-status">
                {/* <span className="indicator-open-details"
                        onClick={() => {openIndicatorDetails();}}>
                    <IconBook/>
                    {getText('indicator-details-open')}
                </span> */}
                {isPageSaved ?
                    <span className={'indicator-remove'} onClick={() => {removeIndicator();}}>
                        {getText('saved-indicators-remove')}
                        <IconTrash/>
                    </span> :
                    <span className={'indicator-saved' + (isIndicatorSaved ? ' zol-active' : '')}
                            onClick={() => {toggleSavedIndicator();}}>
                        {getText(isIndicatorSaved ? 'saved-indicators-saved' : 'saved-indicators-save')}
                        <IconStar/>
                    </span>
                }
            </div>
        </span>
    </div>;
};

IndicatorItem.defaultProps = {
    className: 'toggle-box-item',
    type: 'IND',
    isCoreKpi: false
};

IndicatorItem.propTypes = {
    id: PropTypes.number.isRequired,
    num: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    type: PropTypes.oneOf(['ST', 'IND']),
    isCoreKpi: PropTypes.bool
};

export {IndicatorItem};
