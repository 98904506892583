import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';

class ModelSrfSdgView extends ModelAuthenticatedXhr {
    static modelName = 'ModelSrfSdgView';

    static getSelect(input){
        const select = {
            select: input.select ?
                input.select :
                'sdg_goal_id, sdg_goal_title, sdg_target_id, sdg_target_num, sdg_target_title',
            filter: input.filter ?
                input.filter :
                '',
            language: input.language ?
                input.language :
                'en'
        };
        return select;
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf-sdg-view',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getSelect(input){
        return ModelSrfSdgView.getSelect(input);
    }

    getData(){
        return {status: this.status, data: this.data || []};
    }
}

export {ModelSrfSdgView};
