import './index.scss';
import React from 'react';
import {IndicatorLine} from '../item-block/indicator-line.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelSrf} from '../../model/srf.js';
import {useStateContext} from '../../helpers/state-context.js';
import {getHighlightedText} from './../search/get_highlighted_text.js';
import {ShortTermOutcome} from '../short-term-outcome/index.js';
import {ShortTermOutcomeOverview} from '../short-term-outcome/overview/index.js';
import {ShortTermOutcomeMobile} from '../short-term-outcome/mobile/index.js';
import {DonutAnnotationSdgs} from '../chart/donut/annotation/sdgs.js';
import {useTranslation} from '../../helpers/use_translation.js';

const LongTermOutcome = function(props){
    const [state] = useStateContext();
    const {getText} = useTranslation();
    const resultSetSelectedLt = useModelFeedback(ModelSrf,
            {select: 'lt_outcome_id, lt_outcome_title, lt_outcome_num as lt_num',
                filter: 'lt_outcome_id eq ' + state.lt_id, language: state.language});
    const resultSet = useModelFeedback(ModelSrf,
            {select: 'st_outcome_id as id, st_outcome_num as num, st_outcome_short_title as short_title, ' +
                    'st_outcome_title as title',
            filter: 'lt_outcome_id eq ' + state.lt_id,
            order: 'num',
            language: state.language}
    );

    const isMobileOrTablet = state.renderSize === 'small' || state.renderSize === 'medium';

    if(state.lt_id === null){
        return null;
    }
    if(resultSet.feedback){
        return resultSet.feedback;
    };

    const selectedItem = resultSetSelectedLt.feedback ? [] : resultSetSelectedLt.data[0];
    const donutHover = (state.hover === null) ? 1 : .1;
    return <div style={{opacity: donutHover}}>
        <div id={'zol-long-term'} className={'zol-long-term-details'}>
            <h2 className={'zol-text-uppercase'}>
                {getText('filter-lt-outcome-one')} {selectedItem?.lt_num}
            </h2>
            <h3>{getHighlightedText(selectedItem?.lt_outcome_title, state.searchText)}</h3>
            <DonutAnnotationSdgs itemId={parseInt(state.lt_id)} itemType={'srf_lt_outcome'}/>
        </div>
        <div className={'zol-long-term zol-st-outcomes'}>
            {(state.st_id) ?
                <ShortTermOutcome isMobileOrTablet={isMobileOrTablet}/> :
                (isMobileOrTablet ?
                    <>
                        <IndicatorLine boxes={[1]} title={getText('filter-st-outcome-many')}/>
                        {resultSet.data.map((shortTerm, key) => (
                            <ShortTermOutcomeMobile
                                    type={'overview'}
                                    item={shortTerm}
                                    key={key}/>
                        ))}
                    </> :
                    <>
                        <IndicatorLine boxes={resultSet.data} title={getText('filter-st-outcome-many')}/>
                        {resultSet.data.map((shortTerm, key) => (
                            <ShortTermOutcomeOverview total={resultSet.data?.length}
                                    item={shortTerm} key={key}/>
                        ))}
                    </>)
            }
        </div>
    </div>;
};

export {LongTermOutcome};
