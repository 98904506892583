import React from 'react';
import {AdminOeeList} from '../../components/admin/oee/list.js';
import {ModelConsumer} from '../../helpers/model/model-consumer.js';
import {useStateContext} from '../../helpers/state-context.js';
import {AdminOeeNew} from '../../components/admin/oee/new.js';
import {list as resultList} from '../../components/admin/oee/level_mapping.js';

const AdminOee = function(props){
    const [state] = useStateContext(['type', 'subpage', 'id', 'category']);
    if(state.subpage === 'new'){
        return <>
            <AdminOeeList/>
            <AdminOeeNew/>
        </>;
    }else if(state.subpage === 'edit' && state.id !== null){
        const selected = resultList.find((item) => item.id === state.category);
        return <>
            <AdminOeeList/>
            <ModelConsumer model={selected.editModel || selected.model} selectionCriteria={{id: state.id}}>
                {(resultSet) => <AdminOeeNew initialValues={resultSet.data[0]}/>}
            </ModelConsumer>
        </>;
    }
    return <AdminOeeList/>;
};

export {AdminOee};
