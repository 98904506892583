import React, {useState, useEffect} from 'react';
import {SimpleEvent} from '../../../helpers/simple-event.js';
import {ReactComponent as IconLeft} from './icons/left.svg';
import {ReactComponent as IconRight} from './icons/right.svg';

const scrollButtonStyle = {
    backgroundColor: '#41448c',
    cursor: 'pointer',
    top: '50%',
    marginTop: '-15px',
    position: 'absolute',
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    boxShadow: '0 6px 10px 0 rgba(0,0,0,0.1)',
    border: 'none',
    outline: 'none',
    textAlign: 'center'
};

const useTableScroll = function(tableElement, isEnabled, width, offsetHeader){
    const [scrollOffset, setScrollOffset] = useState(0);

    // scroll the container to the left/right
    const tableScroll = (direction) => {
        if(tableElement && isEnabled){
            const offset = (direction === 'left' ? -1 : 1) * 100;
            let scrollLeft = Math.max(0, tableElement.scrollLeft + offset);
            if(scrollLeft + width > tableElement.scrollWidth){
                scrollLeft = tableElement.scrollWidth - width;
            }
            tableElement.scrollLeft = scrollLeft;
            setScrollOffset(scrollLeft);
        }
    };

    useEffect(() => {
        // This effect sets a scroll event on the scrollable element of the table, when we scroll we need
        // to re-evaluate if the navigation buttons should be enabled/disabled
        const onTableScroll = () => {
            setScrollOffset(tableElement.scrollLeft);
        };
        let event = null;
        if(tableElement !== null){
            event = new SimpleEvent(tableElement, 'scroll', onTableScroll);
        }
        return () => {
            // clean up
            if(event !== null){
                event.remove();
            }
        };
        // The funtions used internally are only dependant on tableElement, so its safe to leave out
        // other dependencies and prevent needless updates
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableElement]);

    // Determine the style of the navigation items at the top. Make them gray (disabled) if the user
    // cannot continue to scroll in that direction
    const getTableScrollStyle = (direction) => {
        const style = {...scrollButtonStyle};

        if(tableElement !== null){
            if(direction === 'left'){
                style.left = '10px';
                style.backgroundColor = scrollOffset === 0 ? 'gray' : style.backgroundColor;
            }else{
                style.paddingLeft = '7px';
                style.right = '10px';
                if(tableElement.scrollWidth - scrollOffset === tableElement.clientWidth){
                    style.backgroundColor = 'gray';
                }
            }
        }
        return style;
    };

    const styleNavButtons = {
        position: offsetHeader === 0 ? 'absolute' : 'fixed',
        top: '0px',
        minHeight: '30px',
        width,
        left: offsetHeader === 0 ? scrollOffset : '',
        zIndex: 1
    };

    return isEnabled ?
        [
            <div style={styleNavButtons} className="zol-table-navigation" key={1}>
                <button style={getTableScrollStyle('left')} onClick={() => {tableScroll('left');}}>
                    <IconLeft color="white" width="15px"/>
                </button>
                <button style={getTableScrollStyle('right')} onClick={() => {tableScroll('right');}}>
                    <IconRight color="white" width="15px"/>
                </button>
            </div>,
            scrollOffset
        ] :
        [null, 0];
};

export {useTableScroll};
