import './main.scss';
import PropTypes from 'prop-types';
import React from 'react';
import {Header} from './header/index.js';
import {UIModalManager} from '../components/ui/modal/manager.js';
import {UISwitch} from '../components/ui/switch/switch.js';
import {UISwitchVertical} from '../components/ui/switch/vertical.js';
import {useStateContext} from '../helpers/state-context.js';
import {useTranslation} from '../helpers/use_translation.js';

const ViewMain = function(props){
    const [state, dispatch] = useStateContext(['renderSize', 'gcm', 'sdg', 'reg_priorities']);
    const isMobile = ['small', 'medium'].includes(state.renderSize);
    const {getText} = useTranslation();

    const pageOptions = [
        {
            value: 'ccp',
            label: <span>
                {/* <strong>{getText('donut-short-title-ccp')}</strong> */}
                <em>{getText('donut-title-ccp')}</em>
            </span>
        },
        {
            value: 'objectives',
            label: <span>
                {/* <strong>{getText('donut-short-title-objectives')}</strong> */}
                <em>{getText('donut-title-objectives')}</em>
            </span>
        },
        {
            value: 'oee',
            label: <span>
                {/* <strong>{getText('donut-short-title-oee')}</strong> */}
                <em>{getText('donut-title-oee')}</em>
            </span>
        }
    ];

    // when we have filters a not is displayed and we move the switch a bit down on mobile
    const isFilterActive = [state.gcm, state.sdg, state.reg_priorities].some((i) => i?.length > 0);
    const styleSwitchMobile = {
        marginTop: isFilterActive ? 40 : 0
    };

    // Aligning the switch with the center of the circle
    const centerElements = document.getElementsByClassName('donut-center');
    let center = 0;
    if(centerElements.length > 0){
        const bbox = centerElements[0].getBoundingClientRect();
        center = bbox.top + (0.5 * (bbox.bottom - bbox.top)) + window.scrollY;
    }
    const styleSwitch = isMobile ? {} : {top: center - 150};

    const isIncludePageSwitch = ['objectives', 'ccp', 'oee'].includes(props.type);

    return <UIModalManager>
        <Header/>
        <div className={'zol-main ' + props.type}>

            {isMobile && isIncludePageSwitch ?
                <div className={'page-switch page-switch-mobile page-type-' + props.type}
                        style={styleSwitchMobile}>
                    <UISwitch value={props.type}
                            options={pageOptions}
                            onChange={(value) => dispatch({type: 'page', value})}/>
                </div> :
                null
            }

            {props.children}

            {isMobile || ! isIncludePageSwitch ?
                null :
                <div className={'page-switch page-switch-desktop page-type-' + props.type}
                        style={styleSwitch}>
                    <UISwitchVertical value={props.type}
                            options={pageOptions}
                            onChange={(value) => dispatch({type: 'page', value})}/>
                </div>
            }

        </div>
    </UIModalManager>;
};

ViewMain.defaultProps = {
    type: ''
};

ViewMain.propTypes = {
    type: PropTypes.string
};

export {ViewMain};
