import './dropdown.scss';
import {PropTypes} from 'prop-types';
import React, {useState} from 'react';
import {getColorPalette} from '../../helpers/color.js';
import {useStateContext} from '../../helpers/state-context/use_state_context.js';
import {getHighlightedText} from './get_highlighted_text.js';
import {useTranslation} from '../../helpers/use_translation.js';
import {useClickOutsideElement} from '../../helpers/use_click_outside_element.js';

const SearchDropdown = function(props){
    const [{searchText}, dispatch] = useStateContext();
    const [chosenTopic, setChosenTopic] = useState('all');
    const {getText} = useTranslation();

    const measuredRef = useClickOutsideElement(props.onClose);

    const levelToTitleMapping = {
        lt_outcome: getText('filter-lt-outcome-many-short'),
        st_outcome: getText('filter-st-outcome-many-short'),
        st_indicator: getText('filter-st-outcome-indicator-many-short'),
        output: getText('filter-output-many'),
        indicator: getText('filter-indicator-many'),
        gcmList: getText('filter-gcm-many'),
        rpList: getText('filter-rp-many'),
        sdgList: getText('filter-sdg-many'),
        oee_output: getText('donut-short-title-oee') + ' ' + getText('filter-output-many'),
        oee_indicator: getText('donut-short-title-oee') + ' ' + getText('filter-indicator-many'),
        ccp: getText('donut-short-title-ccp'),
        ccp_output: getText('donut-short-title-ccp') + ' ' + getText('filter-output-many'),
        ccp_indicator: getText('donut-short-title-ccp') + ' ' + getText('filter-indicator-many')
    };

    const topics = Object.keys(props.options);
    const openPage = (item, topic) => {
        props.onClose();
        if(topic){
            if(topic.substring(0, 3) === 'oee'){
                dispatch({type: 'page', value: 'oee'});
                dispatch({type: 'initItem', value: {output: item.id, searchText}});
                return;
            }else if(topic.substring(0, 3) === 'ccp'){
                dispatch({type: 'page', value: 'ccp'});
                dispatch({type: 'initItem', value: {priority: item.id, searchText}});
                return;
            }

            let t = '';
            if(topic === 'st_indicator'){
                t = 'st_outcome_indicator';
            }else if(topic === 'rpList'){
                t = 'rp';
            }else if(topic === 'sdgList'){
                t = 'sdg';
            }else if(topic === 'gcmList'){
                t = 'gcm';
            }else if(topic === 'output' || topic === 'indicator'){
                t = 'output';
            }
            dispatch({type: 'topic', value: t === '' ? null : t});
        }
        dispatch({type: 'hover', value: null});

        // If link has been clicked the according item is selected in the filter
        if(['rpList', 'gcmList', 'sdgList'].includes(topic)){
            dispatch({type: 'page', value: 'objectives'});
            const key = topic === 'rpList' ? 'reg_priorities' : (topic === 'sdgList') ? 'sdg' : 'gcm';
            dispatch({type: 'initItem', value: {[key]: [item.id], searchText}});
            return;
        }

        dispatch({type: 'page', value: 'objectives'});
        dispatch({type: 'initItem', value: {
            lt_id: item.lt_outcome_id || null,
            st_id: item.st_outcome_id || null,
            output: topic === 'output' ? (item.num || null) :
                    (topic === 'indicator' ? item.output_num || null : null),
            searchText
        }});

        // TODO: when on another page than 'objectives', topic is not available in the state context and
        // cannot be set. Either include it in the initItem property or preferably migrate to GlobalState
        if(topic === 'output' || topic === 'indicator'){
            dispatch({type: 'topic', value: 'output'});
        }else if(item.st_outcome_id){
            dispatch({type: 'topic', value: 'st_outcome_indicator'});
        }
    };
    const colors = getColorPalette(Object.keys(topics).length);
    const totalLength = Object.keys(props.options)
            .map((key) => (props.options[key].length))
            .reduce((p, c) => (p + c, 0));

    return <div className={'search-dropdown'} style={{maxHeight: window.innerHeight * 0.9 - 50}}
            ref={measuredRef}>
        <p className={'category-title'}>{getText('header-search-dropdown-category')}</p>

        <div className={'category-bar'}>
            <div className={'category-topic'}
                    onClick={() => setChosenTopic('all')}
                    style={(chosenTopic === 'all') ? {opacity: 1, fontWeight: 'bold'} : {opacity: 0.7}}>
                <span className={'category-bubble'}
                        style={{backgroundColor: 'blue'}}>
                    {totalLength}
                </span>
                <p>{getText('header-search-dropdown-category-all')}</p>
            </div>
            {topics.map((topic, idx) => {
                const chosenStyle = chosenTopic === topic ?
                        {opacity: 1, fontWeight: 'bold'} : {opacity: 0.7};
                const topicLength = props.options[topic].length;
                if(topicLength === 0){
                    return null;
                }
                const groupTitle = levelToTitleMapping[topic] || null;
                const groupColor = 'rgb(' + colors[idx].join(',') + ')';
                return <div key={idx} className={'category-topic'}
                        onClick={() => setChosenTopic(topic)}
                        style={chosenStyle}>
                    <span className={'category-bubble'}
                            style={{backgroundColor: groupColor}}>
                        {topicLength}
                    </span>
                    <p>{groupTitle}</p>
                </div>;
            })}
        </div>

        {topics.map((topic, idx) => {
            if(chosenTopic !== 'all' && chosenTopic !== topic){
                return null;
            }
            const group = props.options[topic];
            const groupTitle = levelToTitleMapping[topic] || null;
            const groupColor = 'rgb(' + colors[idx].join(',') + ')';
            const groupList = (group.length > 0) ?
                    group :
                    [{type: 'empty', title: getText('header-search-no-results')}];
            const header = <div className={'group-title'}>
                {groupTitle}
            </div>;

            return <div key={idx}>
                {header}
                <ul className={'group-list'}>
                    {groupList.map((item, key) => {
                        const emptyClass = (item?.type === 'empty') ? ' group-list-item-empty' : '';
                        return <li key={key}
                                className={`group-list-item ${emptyClass}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(item?.type !== 'empty'){
                                        openPage(item, item.topic);
                                    }
                                }}
                                style={{borderLeft: '5px solid ' + groupColor}}>
                            {getHighlightedText(item.title, searchText)}
                        </li>;
                    })}
                </ul>
            </div>;
        })}
    </div>;
};

SearchDropdown.propTypes = {
    options: PropTypes.shape({
        lt_outcome: PropTypes.array,
        st_outcome: PropTypes.array,
        output: PropTypes.array,
        indicator: PropTypes.array,
        gcmList: PropTypes.array,
        sdgList: PropTypes.array,
        rpList: PropTypes.array
    }),
    onClose: PropTypes.func,
    isMobile: PropTypes.bool
};

export {SearchDropdown};
