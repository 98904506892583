import React from 'react';
import PropTypes from 'prop-types';
import * as d3Shape from 'd3-shape';
import {useStateContext} from '../../../../helpers/state-context.js';
import {LeftOutlined, DownOutlined} from '@ant-design/icons';

const arrowStyle = {
    fill: 'rgba(255, 255, 255, .5)',
    cursor: 'pointer'
};

const DonutSegmentLongTerm = function(props){
    const {donutRadius, data, isMobileOrTablet, value} = props;
    const [state, dispatch] = useStateContext();
    const longTermData = props.has1Level ?
        data.reduce((arr, i) => {
            arr.push(i.data.id);
            return arr;
        }, []) :
        data.reduce((arr, objective) => {
            arr.push(...objective.children.map((i) => i.data.id));
            return arr;
        }, []);
    const donutHover = (state.hover === null) ? 1 : .1;
    const current = parseInt(state[value]);
    const currentIndex = longTermData.indexOf(current);
    const rightValue = (currentIndex === 0) ?
            longTermData[longTermData.length - 1] :
            longTermData[currentIndex - 1];
    const leftValue = (longTermData.length - 1 === currentIndex) ?
            longTermData[0] :
            longTermData[currentIndex + 1];
    const arrowWidth = (Math.PI / 2.35) - (Math.PI / 2);
    const arcArrowLeft = d3Shape.arc()
            .startAngle(Math.PI / 2 - (arrowWidth))
            .endAngle(Math.PI / 2 + (arrowWidth))
            .innerRadius(donutRadius)
            .outerRadius(donutRadius * (isMobileOrTablet ? 2.25 : 2));
    const arc = d3Shape.arc()
            .startAngle(-arrowWidth)
            .endAngle((Math.PI / 2))
            .innerRadius(donutRadius)
            .outerRadius(donutRadius * 2);
    const arcArrowRight = d3Shape.arc()
            .startAngle(0)
            .endAngle(-arrowWidth)
            .innerRadius(donutRadius)
            .outerRadius(donutRadius * (isMobileOrTablet ? 2.3 : 2));
    const centroidLeft = arcArrowLeft.centroid();
    const centroidRight = arcArrowRight.centroid();
    const rightXOffset = (isMobileOrTablet) ? 20 : 15;
    const rightYOffset = (isMobileOrTablet) ? 40 : -25;
    const leftXOffset = (isMobileOrTablet) ? 60 : 25;
    const leftYOffset = (isMobileOrTablet) ? 10 : 30;
    const rightTransform = `translate(${centroidRight[0] - rightXOffset},${centroidRight[1] + rightYOffset})`;
    const leftTransform = `translate(${centroidLeft[0] - leftXOffset}, ${centroidLeft[1] + leftYOffset})`;

    return <g style={{opacity: donutHover}} className={'donut-lt-outcome'}>
        <g onClick={() => dispatch({type: state.page === 'objectives' ?
                'setLTOutcome' : props.value, value: rightValue})}
                style={{cursor: 'pointer'}}
                className={'pdf-export-hide'}>
            <path d={arcArrowRight()} style={arrowStyle}/>
            <foreignObject transform={rightTransform}
                    width={50}
                    height={50}>
                <LeftOutlined style={{fontSize: (isMobileOrTablet) ? 26 : 40, color: '#4867b2'}}/>
            </foreignObject>
        </g>
        <g onClick={() => dispatch({type: state.page === 'objectives' ?
                'setLTOutcome' : props.value, value: leftValue})}
                style={{cursor: 'pointer'}}
                className={'pdf-export-hide'}>
            <path d={arcArrowLeft()} style={arrowStyle}/>
            <foreignObject transform={leftTransform}
                    width={50}
                    height={50}>
                <DownOutlined style={{fontSize: (isMobileOrTablet) ? 26 : 40, color: '#4867b2'}}/>
            </foreignObject>
        </g>
        <path d={arc()} fill={'#fff'}/>
    </g>;
};

DonutSegmentLongTerm.defaultProps = {
    isMobileOrTablet: false,
    data: [],
    has1Level: false
};

DonutSegmentLongTerm.propTypes = {
    donutRadius: PropTypes.number.isRequired,
    data: PropTypes.array,
    isMobileOrTablet: PropTypes.bool,
    has1Level: PropTypes.bool,
    value: PropTypes.string.isRequired
};

export {DonutSegmentLongTerm};
