import React, {useEffect} from 'react';
import {UIModal} from '../../ui/modal/modal.js';
import PropTypes from 'prop-types';
import {UIButton} from '../../ui/button/button.js';
import {FormElement} from '../../../helpers/form/element.js';
import {UIInput} from '../../ui/input/input.js';
import {UIInputTextarea} from '../../ui/input/textarea.js';
import {useForm} from '../../../helpers/form/use_form.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {list} from './level_mapping.js';
import {UIInputSelect} from '../../ui/input/select.js';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const AdminOeeNew = function(props){
    const [state, dispatch] = useStateContext(['page', 'id', 'category']);
    const {getLanguages} = useTranslation();
    const languages = getLanguages().filter((l) => l.value !== 'en');

    const selected = list.find((item) => item.id === state.category);
    const selectedModel = selected.editModel || selected.model;
    const formConfig = [
        {
            title: 'Num',
            name: 'num',
            element: UIInput,
            type: 'wide'
        },
        {
            title: 'Title',
            name: 'title',
            element: state.category === 'oee_output' ? UIInput : UIInputTextarea,
            required: true,
            type: 'wide'
        },
        ...languages.map((lang) => {
            return {
                title: 'Title ' + lang.title?.toLowerCase(),
                name: 'title_' + lang.value,
                required: true,
                type: 'wide',
                element: state.category === 'oee_output' ? UIInput : UIInputTextarea
            };
        })
    ];
    if(state.category === 'oee_output'){
        formConfig.push(
                {
                    title: 'Description',
                    name: 'description',
                    required: true,
                    type: 'wide',
                    element: UIInputTextarea
                },
                ...languages.map((lang) => {
                    return {
                        title: 'Description ' + lang.title?.toLowerCase(),
                        name: 'description_' + lang.value,
                        required: true,
                        type: 'wide',
                        element: UIInputTextarea
                    };
                }));
    }
    formConfig.push(
            {
                title: 'Parent',
                name: 'parent',
                element: UIInputSelect,
                options: {},
                type: 'wide'
            });

    const Form = useForm(formConfig);
    const previousIndex = list.findIndex((item) => item.id === state.category) -
        (state.category === 'srf_output' ? 2 : 1);
    const previousItem = list[(previousIndex < 0) ? 0 : previousIndex];
    const previousModel = previousItem.editModel || previousItem.model;
    const resultSet = useModelFeedback(previousModel);

    useEffect(() => {
        if(props.initialValues !== null){
            const values = {
                title: props.initialValues.title,
                description: props.initialValues.description,
                id: props.initialValues.id || '',
                num: props.initialValues.num || ''
            };
            for(const language of languages){
                values['title_' + language.value] = props.initialValues['title_' + language.value];
                values['description_' + language.value] =
                        props.initialValues['description_' + language.value];
            }

            if(selected.foreign_key){
                values.parent = props.initialValues.parent_id || props.initialValues.parent ||
                        props.initialValues[selected.foreign_key];
            }
            Form.setValues(values);
        }
    }, [Form, props.initialValues]);

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const options = resultSet.data.reduce((obj, item) => {
        obj[item.id] = (item?.num || item?.id || '') + '. ' + item.title;
        return obj;
    }, {});

    const submitForm = () => {
        const values = Form.getValues();
        if(Form.validate()){
            const result = {
                title: values.title,
                description: values.description,
                num: values.num
            };
            for(const language of languages){
                result['title_' + language.value] = values['title_' + language.value];
                result['description_' + language.value] = values['description_' + language.value];
            }
            if(state.category !== 'oee_output' && selected.foreign_key){
                result[selected.foreign_key] = parseInt(values.parent);
            }
            if(props.initialValues){
                selectedModel.updateItem(state.id, result, () => {
                    dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
                });
            }else{
                selectedModel.createItem(result, () => {
                    dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
                });
            }
        }
    };

    return <UIModal onClose={() => dispatch({type: 'setValues', value: {subpage: 'list', id: null}})}
            title={`${(props.initialValues === null) ? 'Add new' : 'Edit'} item`} closeOnClickOutside={false}>
        <Form.Component>
            {formConfig.map((element) => {
                const opts = element.name === 'parent' ? {options} : {};
                if(state.category === 'oee_output' && ['parent'].includes(element.name)){
                    // Hide 'parent' field on priority tab
                    return null;
                }
                return <FormElement key={element.name} {...element} {...opts}/>;
            })}
            <br/>
            <UIButton onClick={submitForm} style={{marginRight: 5}}>
                {(props.initialValues === null) ? 'New' : 'Edit'}
            </UIButton>
        </Form.Component>
    </UIModal>;
};

AdminOeeNew.defaultProps = {
    initialValues: null
};

AdminOeeNew.propTypes = {
    initialValues: PropTypes.object,
    selectedTab: PropTypes.object
};

export {AdminOeeNew};
