import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';

const TreeNode = function(props){
    const {level, node, isCheckable, value, children} = props;
    const [visible, setVisible] = useState(node.defaultVisible);
    const isValueCheckable = ('boolean' === typeof node.isCheckable) ? node.isCheckable : isCheckable;
    let nodes;

    useEffect(() => {
        setVisible(node.defaultVisible);
    }, [node.defaultVisible]);

    useEffect(() => {
        const defaultCheckboxes = document.getElementsByClassName('zol-tree-checkboxes');
        for(const checkbox of defaultCheckboxes){
            checkbox.indeterminate = false;
        }
        const checkboxes = document.getElementsByClassName('zol-tree-indeterminate');
        for(const checkbox of checkboxes){
            checkbox.indeterminate = true;
        }
    });

    if(children && (visible || node.visible)){
        nodes = props.children.map((node, key) => (
            <TreeNode key={key}
                    node={node}
                    level={level + 1}
                    parent={props.node}
                    onSelect={props.onSelect}
                    onCheck={props.onCheck}
                    isItemChecked={props.isItemChecked}
                    value={value}
                    isCheckable={isCheckable}>
                {node.children}
            </TreeNode>
        ));
    }

    const isItemChecked = (childValue, parentValue = null) => (
        props.isItemChecked ?
            props.isItemChecked(childValue) :
            value.some((selectedNode) => (
                // make sure the parent of the selected node equals the current node too!
                (selectedNode?.parent === parentValue && selectedNode.child === childValue)
            ))

    );

    // Test whether any of the child nodes within the current node are checked.
    const isChildrenChecked = (children, any = false) => {
        const childValues = children?.filter((childNode) => childNode.disabled === false);
        if(childValues?.length > 0){
            const checkChild = (childNode) => (
                childNode?.children ?
                        isChildrenChecked(childNode.children) :
                        isItemChecked(childNode, node)
            );
            if(any){
                return childValues.some(checkChild);
            }else{
                return childValues.every(checkChild);
            }
        }
        return false;
    };

    const getCheckedChildren = (children) => (
        children?.reduce((total, childNode) => {
            if(isItemChecked(childNode, node)){
                total += 1;
            }
            return total;
        }, 0)
    );

    const getAllCheckedChildren = (total, children) => {
        total += getCheckedChildren(children);
        if(children && children[0]?.children){
            for(const subItem of children){
                total = getAllCheckedChildren(total, subItem.children);
            }
        }
        return total;
    };

    const total = getAllCheckedChildren(0, node?.children);

    const onCheckSelect = () => {
        if(!node.disabled){
            // Send all childrens if parent is selected;
            if(props.children){
                const childOptions = props.children.map((i) => {
                    return {
                        parent: node.name,
                        child: i.value
                    };
                });
                return props.onCheck(childOptions, node);
            }
            return props.onCheck([{parent: props.parent.name, child: node.value}], node);
        }
    };

    const classNameTreeNode = 'zol-tree-node' +
            (props.children ? ' zol-tree-node-head' : ' zol-tree-node-child') +
            ((node.value !== null && isItemChecked(node, props.parent) ?
                    ' zol-tree-node-active' : '')) +
            (node.disabled ? ' zol-tree-node-disabled' : '');

    return <li className={((level === 0) ? ' zol-tree-parent' : 'zol-tree-child')}>
        <div className={classNameTreeNode}>
            {isValueCheckable ?
                <span onClick={onCheckSelect} className={'tree-header-container'}>
                    <input type={'checkbox'}
                            className={'zol-tree-checkboxes ' +
                            (isChildrenChecked(props.children, true) &&
                                    !isChildrenChecked(props.children) ?
                                ' zol-tree-indeterminate' :
                                '')
                            }
                            readOnly={true}
                            checked={props.children ?
                                    isChildrenChecked(props.children) :
                                    isItemChecked(node, props)
                            }/>
                    <span/>
                </span> :
                null
            }
            <span className={`zol-tree-node-content`}
                    onClick={() => {
                        if(!node.disabled){
                            if(isValueCheckable){
                                onCheckSelect();
                            }else if(children){
                                setVisible((prev) => (!prev));
                            }else{
                                props.onSelect(props.node);
                            }
                        }
                    }}>
                {node.name} {total > 0 ? '(' + total + ')' : null}
            </span>
            {(children) ?
                <span onClick={() => setVisible((prev) => (!prev))} className={'zol-tree-node-toggle'}>
                    {(visible) ? '[-]' : '[+]'}
                </span> :
                null
            }
        </div>
        {nodes ? <ul>{nodes}</ul> : null}
    </li>;
};

TreeNode.defaultProps = {
    onSelect: () => {},
    onCheck: () => {},
    searchedOptions: []
};

TreeNode.propTypes = {
    onSelect: PropTypes.func,
    onCheck: PropTypes.func,
    isItemChecked: PropTypes.func,
    level: PropTypes.number,
    node: PropTypes.object.isRequired,
    parent: PropTypes.object,
    isCheckable: PropTypes.bool,
    children: PropTypes.array,
    value: PropTypes.array,
    searchedOptions: PropTypes.array
};

export {TreeNode};
