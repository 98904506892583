import React from 'react';
import PropTypes from 'prop-types';
import {useStateContext} from '../../helpers/state-context.js';
import {getHighlightedText} from '../search/get_highlighted_text.js';
import {ReactComponent as RightArrowIcon} from '../../assets/icons/icon-arrow.svg';
// import {ReactComponent as IconBook} from '../../assets/icons/book.svg';
import {ReactComponent as IconStar} from '../../assets/icons/star.svg';
import {useIndicatorData} from './use_indicator_data.js';
// import {IndicatorDetails} from '../output/indicator-details.js';
import {useTranslation} from '../../helpers/use_translation.js';
import {ReactComponent as IconCoreKpi} from '../../assets/icons/core-kpi.svg';

const Indicators = function(props){
    const [state, dispatch] = useStateContext(['searchText', 'setModal', 'savedIndicators']);
    const {getText} = useTranslation();

    const {dataLength, data} = useIndicatorData();
    if(dataLength === 0 || !dataLength){
        return null;
    }

    const toggleSavedIndicator = (id) => {
        const nextIndicatorIds = [...state.savedIndicators];
        const pos = nextIndicatorIds.indexOf('st' + id);
        if(pos === -1){
            nextIndicatorIds.push('st' + id);
        }else{
            nextIndicatorIds.splice(pos, 1);
        }
        dispatch({type: 'savedIndicators', value: nextIndicatorIds});
    };

    const isSaved = (id) => (state.savedIndicators.includes('st' + id));

    // const openIndicatorDetails = (indicator) => {
    //     const modalProps = {
    //         title: getText('indicator-details-title') + ' > ' + indicator.num,
    //         width: '90vw',
    //         height: '100vh',
    //         className: 'zol-indicator-details'
    //     };
    //     state.setModal(<IndicatorDetails type="ST" id={indicator.id}/>, modalProps);
    // };

    return <div className="zol-show-toggle-box">
        {data.map((indicator, key) => (
            <div key={key} className="toggle-box-item page-break" style={props.styleBox}>
            <span className={'toggle-box-bullit-point' + (indicator.is_core_kpi ? ' core-kpi' : '')}>
                <RightArrowIcon/>
                {indicator.is_core_kpi ? <IconCoreKpi title="This is a core KPI"/> : null}
            </span>
            <span className="toggle-box-title" style={props.styleTitle}>
                <strong className={indicator.is_core_kpi ? 'core-kpi' : ''}>{indicator.num}</strong><br/>
                {getHighlightedText(indicator.title, state.searchText)}
                <div className="indicator-status">
                    {/* <span className="indicator-open-details"
                            onClick={() => {openIndicatorDetails(indicator);}}>
                        <IconBook/>
                        {getText('indicator-details-open')}
                    </span> */}
                    {<span className={'indicator-saved' + (isSaved(indicator.id) ? ' zol-active' : '')}
                            onClick={() => {toggleSavedIndicator(indicator.id);}}>
                        {getText(isSaved(indicator.id) ? 'saved-indicators-saved' : 'saved-indicators-save')}
                        <IconStar/>
                    </span>
                    }
                </div>
            </span>
            </div>
        ))}
    </div>;
};

Indicators.defaultProps = {
    styleBox: {},
    styleTitle: {}
};

Indicators.propTypes = {
    styleBox: PropTypes.object,
    styleTitle: PropTypes.object
};

export {Indicators};
