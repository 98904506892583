import './index.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {UIIntroduction} from '../ui/introduction/index.js';
import {useStateContext} from '../../helpers/state-context.js';
import {useTranslation} from '../../helpers/use_translation.js';

const Introduction = function(props){
    const [state, dispatch] = useStateContext();
    const {getText} = useTranslation();

    const steps = {objectives: [
        {
            target: '#zol-donut-circle',
            anchor: 'left',
            header: getText('introduction-donut-title'),
            content: getText('introduction-donut-description')
        },
        {
            target: '#zol-long-term',
            anchor: 'bottom',
            header: getText('introduction-lt-outcome-title'),
            content: getText('introduction-lt-outcome-description'),
            action: () => {
                if(state.lt_id === null){
                    dispatch({type: 'setLTOutcome', value: 1});
                }
            }
        },
        {
            target: '#zol-details-page',
            anchor: 'bottom',
            header: getText('introduction-st-outcome-title'),
            content: getText('introduction-st-outcome-description')
        },
        {
            target: '#zol-header-filter',
            anchor: 'top',
            header: getText('introduction-filters-title'),
            content: getText('introduction-filters-description')
        },
        {
            target: '#zol-header',
            anchor: 'top',
            width: 645,
            header: 'Tour completed',
            content: <p>
                This completes the tour. We hope you will enjoy exploring this tool.
            </p>
        }
        // {
        //     target: '#zol-header',
        //     anchor: 'top',
        //     width: 645,
        //     header: getText('introduction-video'),
        //     content: <>
        //         {getText('introduction-video-description')}
        //         <iframe src={getText('introduction-video-url')}
        //                 width="640" height="360" title="SRF Intro video"/>
        //     </>
        // }
    ],
    oee: [],
    ccp: []};

    return <UIIntroduction steps={steps[props.type]}>
        {props.children}
    </UIIntroduction>;
};

Introduction.propTypes = {
    type: PropTypes.string.isRequired
};

export {Introduction};
