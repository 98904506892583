import React, {useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {StateContext, useStateContext, getSimpleReducer} from '../state-context.js';
import {useSize} from '../use_size.js';

/**
 * Creates a wrapper element around it's children and watches the size of that element. Whenever it changes
 * it calls props.parseSize(width, height). You can configure your own function an return any value that you
 * want to store in the stateContext (the name can be configured by props.stateName). Children within this
 * component will have access to the stateContext value. By default you'll get one of three values in the
 * 'renderSize' value of the stateContext: 'small', 'medium' or 'large'. This can be used for responsive
 * development of your components.
 */
function SizedApp(props){
    const initialState = {};
    initialState[props.stateName] = props.defaultSize;

    return <StateContext initialState={initialState} reducer={getSimpleReducer([props.stateName])}>

        <SizedAppInner parseSize={props.parseSize}
                defaultSize={props.defaultSize}
                stateName={props.stateName}>

            {props.children}

        </SizedAppInner>

    </StateContext>;
}

SizedApp.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    parseSize: PropTypes.func,
    defaultSize: PropTypes.string,
    stateName: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.number])
};

SizedApp.defaultProps = {
    parseSize: (width) => {
        let size = '';
        if(width > 980){
            size = 'large';
        }else if(width > 576){
            size = 'medium';
        }else{
            size = 'small';
        }
        return size;
    },
    defaultSize: 'large',
    stateName: 'renderSize'
};

function SizedAppInner(props){
    const [size, measuredRef] = useSize(props.parseSize, props.defaultSize);
    const [state, dispatch] = useStateContext([props.stateName]);

    useEffect(() => {
        if(size !== state.renderSize){
            dispatch({type: props.stateName, value: size});
        }
    }, [size, state.renderSize, props.stateName, dispatch]);

    return <div ref={measuredRef}>
        {props.children}
    </div>;
}
SizedAppInner.propTypes = SizedApp.propTypes;
SizedAppInner.defaultProps = SizedApp.defaultProps;

export {SizedApp};
