import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../assets/variables.module.scss';
import {getHighlightedText} from '../../../search/get_highlighted_text.js';
import {useStateContext} from '../../../../helpers/state-context.js';

const titleStyle = {
    margin: 0,
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 800
};

const DonutAllAnnotations = function(props){
    const [state] = useStateContext(['searchText']);
    if(!props.data || !props.data.length){
        return null;
    }

    const drawPolyLines = (data) => (
        data.map((item, key) => {
            // if(item === data[data.length - 1]){
            //     return;
            // }

            const getPoints = () => {
                const posA = [];
                const posB = [0, 0];

                posA[0] = Math.sin(item.endAngle) * props.radius * 1.05;
                // the x-value that starts 5% outside the donut
                posA[1] = Math.cos(item.endAngle) * props.radius * - 1.05;
                // the y-value where you do it times -, as the top half
                // of the donut is where the y-coordinates are negative

                posB[0] = Math.sin(item.endAngle) * props.radius * 1.1;
                // Math.sin(radian) * props.radius * 1.1;
                posB[1] = Math.cos(item.endAngle) * props.radius * -1.1;
                return [posA, posB];
            };

            const [posA, posB] = getPoints();

            // know where to position line by looking at placement of donut segements
            // use start and endAngle
            const midangle = (item.startAngle +
                 (item.endAngle - item.startAngle) / 2) % fullCircleInRadian;
            const isRightSide = midangle < Math.PI;
            // line equally long to foreignObject box
            const posC = [props.widthScreen / 2 * (isRightSide ? 1 : -1), posB[1]];
            const points = [posA, posB, posC];
            return <polyline key={key}
                    fill={'none'}
                    stroke={styles.colorLight}
                    strokeWidth={.5}
                    points={points}/>;
        })
    );
    const fullCircleInRadian = 6.2831853072;

    const allOutcomes = props.data.map((it) => it.children);

    return allOutcomes.map((outcomes, key) => (
        <React.Fragment key={key}>
            {drawPolyLines(outcomes)}
            {outcomes.map((item, key) => {
                const midangle = (item.startAngle + (item.endAngle - item.startAngle) / 2) %
                        fullCircleInRadian;
                const isRightSide = midangle < Math.PI;
                const getPoints = () => {
                    const pos = [0, 0];
                    const radian = ((item.endAngle - item.startAngle) / 2 + item.startAngle);
                    // the angle in radians from start angle to middle of selected item in donut
                    pos[0] = props.radius * (isRightSide ? 1.1 : -1.1);
                    pos[1] = Math.cos(radian) * props.radius * -1.1;

                    const isUpperHalf = pos[1] < 0;
                    const startY = item.startAngle == 0 ?
                        - props.height / 2 :
                        item.startAngle == Math.PI ?
                            props.height / 2 :
                            Math.cos(item.startAngle) * props.radius * -1.1;
                    const endY = item.endAngle.toFixed(3) == 6.283 ?
                        - props.height / 2 :
                        item.endAngle == Math.PI ?
                            props.height / 2 :
                            Math.cos(item.endAngle) * props.radius * -1.1;

                    pos[1] = (startY + endY) / 2 +
                            (isUpperHalf ? 15 : -20);
                    return pos;
                };
                const textPos = getPoints();
                const isUpperHalf = textPos[1] < 0 ? true : false;

                const [width, maxLines] = [(props.widthScreen / 2 - props.radius * 1.1), 2];
                const height = 25 * (maxLines + 1);
                // lineHeight + bottom or top times number of lines + title line

                textPos[0] = textPos[0] - (isRightSide ? 0 : width);
                textPos[1] = textPos[1] - (isUpperHalf ? height : 0);

                const textStyle = {
                    position: 'absolute',
                    lineHeight: '20px',
                    bottom: isUpperHalf ? '5px' : null,
                    top: isUpperHalf ? null : '5px',
                    right: isRightSide ? null : '0px',
                    left: isRightSide ? '0px' : null,
                    color: styles.colorMedium,
                    overflow: 'hidden',
                    // from overflow to webkitBoxOrient is to add an ellipsis when too much text (...)
                    display: '-webkit-box',
                    WebkitLineClamp: maxLines.toString(),
                    WebkitBoxOrient: 'vertical'
                };
                const getCornerName = () => {
                    const vertical = isUpperHalf ? 'top' : 'bottom';
                    const horizontal = isRightSide ? 'right' : 'left';
                    return vertical + '-' + horizontal;
                };
                const itemNumber = <p style={titleStyle}>
                        {(item.data.num || item.data.id)}
                </p>;
                const itemTitle = <p style={{fontSize: 14, margin: 0, display: 'inline'}}>
                    {getHighlightedText(item.data.title, state.searchText)}
                </p>;

                return <g key={key}>
                    <foreignObject transform={`translate(${textPos})`}
                            width={width}
                            height={height}
                            style={{textAlign: !isRightSide ? 'right' : 'left'}}>
                        <div style={textStyle}>
                            {itemNumber}  {itemTitle}
                        </div>
                    </foreignObject>
                    {(item.children || item.data.id) ?
                        <foreignObject
                                transform={`translate(${textPos[0]},
                                        ${(textPos[1] + (isUpperHalf ? height : -70))})`}
                                width={width}
                                height={height}
                                className={`${getCornerName()}`}
                                style={{
                                    textAlign: isRightSide ? 'right' : 'left',
                                    display: 'flex',
                                    alignItems: !isUpperHalf ? 'flex-start' : 'flex-end',
                                    height: 75
                                }}/> :
                        null
                    }

                </g>;
            })}
        </React.Fragment>
    ));
};

DonutAllAnnotations.propTypes = {
    data: PropTypes.array,
    radius: PropTypes.number,
    widthScreen: PropTypes.number,
    height: PropTypes.number
};

export {DonutAllAnnotations};
