import React from 'react';
import {FormElement} from '../helpers/form/element.js';
import {useStateContext} from '../helpers/state-context.js';
import {UIButton} from '../components/ui/button/button.js';
import {ModelAuthToken} from '../model/auth/token.js';
import {useForm} from '../helpers/form/use_form.js';
import {UIInput} from '../components/ui/input/input.js';
import {UIInputPassword} from '../components/ui/input/password.js';
import {ViewMain} from '../view/main.js';
import {StateContext} from '../helpers/state-context.js';

const formElements = [
    {
        title: 'Email address',
        name: 'email',
        element: UIInput,
        type: 'wide'
    },
    {
        title: 'Password',
        name: 'password',
        element: UIInputPassword,
        type: 'wide'
    }
];

const PageSignIn = function(props){
    const Form = useForm(formElements);
    const [, dispatch] = useStateContext([]);

    const submitForm = () => {
        if(Form.validate()){
            const onSuccess = () => {
                dispatch({type: 'page', value: 'admin'});
            };
            const onFailure = (error) => {
                Form.setError('password', error);
            };
            ModelAuthToken.createItem(
                    Form.getValue('email'), Form.getValue('password'), onSuccess, onFailure);
        }
    };

    return <StateContext initialState={{language: 'en'}}>
        <ViewMain>
            <div className="srf-sign-in">

                <h1>Sign in</h1>
                <p>Please enter your credentials to proceed</p>

                <Form.Component>
                    {formElements.map((item) => (
                        <FormElement key={item.name} {...item} onEnter={submitForm}/>
                    ))}
                </Form.Component>

                <UIButton className="primary" onClick={submitForm}>
                    Sign in
                </UIButton>

            </div>
        </ViewMain>
    </StateContext>;
};

export {PageSignIn};
