import React from 'react';
import PropTypes from 'prop-types';
import {useModel} from '../../helpers/model/use_model.js';
import {ModelSrf} from '../../model/srf.js';
import {Loader} from '../../helpers/elements/loader.js';
import {IndicatorItem} from './indicator-item.js';
import './output.scss';
import {useStateContext} from '../../helpers/state-context.js';

const OutputIndicator = function(props){
    const [{language}] = useStateContext(['language']);
    const {outputId} = props;
    const resultSet = useModel(ModelSrf,
            {
                select: 'indicator_title as title, indicator_num as num, indicator_id',
                filter: 'output_num eq ' + '\'' + outputId + '\'',
                order: 'num',
                language
            });

    if(resultSet.status !== ModelSrf.Status.SUCCESS){
        return <div style={{height: '100px'}}>
            <Loader size={50} style={{position: 'relative'}}/>
        </div>;
    }

    return <div className="zol-show-toggle-box">
        {resultSet.data.map((indicator) => (
            <IndicatorItem key={indicator.indicator_id} id={indicator.indicator_id} num={indicator.num}
                    title={indicator.title}/>
        ))}
    </div>;
};

OutputIndicator.propTypes = {
    outputId: PropTypes.string.isRequired
};

export {OutputIndicator};
