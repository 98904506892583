import './donut.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {DonutAnnotation} from './annotation/annotation.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {DonutSegment} from './segment/index.js';

// TODO: refactor, try split it up in multiple simpler components
const DonutRing = function(props){
    const {
        data,
        radius,
        slice,
        type,
        has1Level,
        frozen,
        getSegmentColor,
        stateKey,
        annotationActive,
        onlyLongTerm,
        isMobile,
        width,
        donutRadius
    } = props;
    const [state] = useStateContext([stateKey, 'hover', 'lt_id', 'renderSize']);
    const isLevel1 = type === 'objectives' || has1Level;

    const screenMargin = state.renderSize === 'small' ? 0 : 40;

    return <g>
        {data.map((item, key) => {
            const {color: segmentColor, opacity} = getSegmentColor(
                    item, isLevel1, frozen, ! has1Level, stateKey);
            const isLastChild = ! isLevel1 && slice?.children?.length === key + 1;
            const isFirstChild = ! isLevel1 && key === 0;

            const showAnnotation = (item) => {
                if(! annotationActive || has1Level){
                    return false;
                }
                if(onlyLongTerm && ! isLevel1){
                    // if you only want to show the long-terms
                    return true;
                }else if(onlyLongTerm && isLevel1){
                    // if you only want to show the long-terms, don't show the objectives as well
                    return false;
                }else if(state.hover === null && state.lt_id === null){
                    // Show main objectives if nothing is selected or hovered
                    return isLevel1;
                }else if(state.hover?.type === 'objectives'){
                    // Show hovered objective
                    return isLevel1 && state.hover?.id === item.data.id;
                }else{
                    // Show hovered long term item and not if a long-term item is selected
                    return ! isLevel1 && state.hover?.id === item.data.id &&
                            parseInt(state.lt_id) !== state.hover?.id;
                }
            };

            return <g key={key} style={{opacity}}>
                <g>
                    <DonutSegment item={item}
                            type={type}
                            segmentColor={segmentColor}
                            isLastChild={isLastChild}
                            isFirstChild={isFirstChild}
                            donutRadius={donutRadius}
                            radius={radius}
                            a={'index'}
                            numItems={data.length}
                            frozen={frozen}
                            value={stateKey}
                            level1Clickable={has1Level}
                            onlyLongTerm={onlyLongTerm}
                            isLevel1={isLevel1}/>

                    {onlyLongTerm ?
                        null :
                        <DonutAnnotation
                                item={item}
                                type={type}
                                isMobile={isMobile}
                                widthScreen={Math.min(width - screenMargin, 1400)}
                                radius={donutRadius}
                                visible={showAnnotation(item)}/>}
                </g>
                {item.children && type === 'objectives' ?
                    <DonutRing {...props}
                            data={item.children}
                            radius={0.73}
                            slice={item}
                            type="lt_outcome"/> :
                    null
                }
            </g>;
        })}
    </g>;
};

DonutRing.propTypes = {
    data: PropTypes.array.isRequired,
    radius: PropTypes.number.isRequired,
    slice: PropTypes.object,
    type: PropTypes.string.isRequired,
    has1Level: PropTypes.bool.isRequired,
    frozen: PropTypes.bool.isRequired,
    getSegmentColor: PropTypes.func.isRequired,
    stateKey: PropTypes.string.isRequired,
    annotationActive: PropTypes.bool.isRequired,
    onlyLongTerm: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    donutRadius: PropTypes.number.isRequired
};

export {DonutRing};
