import React from 'react';
import {getHash} from '../functions/get_hash.js';
/**
 * This class structures a flat result into the structure blueprint that can be provided as a JSON object.
 * This is ported from the Java class in the backend (Spring boot version)
 * That structure can have the following format:
 *
 *   {
 *       "result_key1": "data_key1",
 *       "result_key2": [
 *           "data_key2"
 *       ],
 *       "result_key3": {
 *           "result_key4": "data_key3"
 *       },
 *       "result_key5": [
 *           {
 *               "result_key6": "data_key4",
 *               "result_key7": "data_key5",
 *               "result_key8": [
 *                  {
 *                      "result_key9": "data_key6",
 *                      "result_key10": "data_key7"
 *                  }
 *               ]
 *           }
 *       ]
 *   }
 * Note that an array can only have a single element. This can either be a single data result row name, or
 * a single object.
 * When values used for the structure tree are not selected, the properties matching these missing values are
 * not included in the result.
 */
class StructuredSet {
    constructor(structure){
        this.structuredTree = structure;
        this.resultMap = new Map();
        this.data = [];
    }

    static fromArray(dataArray, structure){
        const set = new StructuredSet(structure);
        for(const row of dataArray){
            set.addRow(row);
        }
        return set;
    }

    addRow(row){
        let result = new Map();
        this.processData(result, row, this.structuredTree, '');
        result = this.mergeIntoResultMap(result, this.structuredTree, '');
        if(result !== null){
            this.data.push(result);
        }
        return result;
    }

    // reformat the current row into a structure
    processData(result, row, jsonObject, currentFieldName){
        let rowHash = currentFieldName;

        // TODO: building up the hash relies on the iteration order over the JSON object to be the same each
        // time we process a row. This is likely since we are not mutating the jsonObject between iterations,
        // however we need to make sure this is guaranteed.
        for(const [field, node] of Object.entries(jsonObject)){
            if(node instanceof Array){
                const listResult = [];
                for(const listElm of node){
                    if(typeof listElm === 'object'){
                        const subResult = new Map();
                        this.processData(subResult, row, listElm, field);
                        // if the result is empty we're not adding it.
                        if(subResult.size > 0){
                            listResult.push(subResult);
                        }
                    }else{
                        // it should be string pointing to the field name in row
                        const value = row[listElm];
                        // Don't add null values
                        if(value !== null){
                            listResult.push(value);
                        }
                    }
                    // in the structuredResult we can only have 1 item in the array that provide a blueprint
                    // of the result. If there are more items, we will simply ignore them.
                    break;
                }
                // Only add this property when there were children
                if(listResult.length > 0){
                    result.set(field, listResult);
                }
            }else if(typeof node === 'object'){
                const subResult = new Map();
                const subHash = this.processData(subResult, row, node, field);
                // when no values where added to this subResult, we're not adding it to the result either
                if(subResult.size > 0){
                    rowHash += '.' + subHash;
                    result.set(field, subResult);
                }
            }else if(typeof node === 'function'){
                const value = node(row);
                result.set(field, value);
            }else{
                // it should be string pointing to the field name in row
                const value = row[node];
                // Store only values that have been selected
                if(value !== null){
                    rowHash += '.' + getHash(value);
                    result.set(field, value);
                }
            }
        }

        // If nothing was selected on this level, we also don't have a hash.
        if(result.size > 0){
            result.set('__hash', rowHash);
        }

        return rowHash;
    }

    // This function merged the new structured result row into the existing resultset.
    // TODO: we can implement a reduce function callback somewhere here to get aggregated values
    mergeIntoResultMap(result, jsonObject, parentHash){
        if(result.get('__hash') === null){
            return null;
        }

        const hash = (parentHash.length > 0 ? parentHash + '.' : '') + result.get('__hash');

        let existingRow = null;
        // If the hash doesn't exist yet in our mapping, we create it and can return the value at the end. We
        // still need to parse through the tree to store refferences to the sub results, but without adding
        // values to the list (we already have it since this instance is the first).
        if(! this.resultMap.get(hash)){
            this.resultMap.set(hash, result);
        }else{
            // There's already a tree with the same hash. Merging the array values into the existing result.
            existingRow = this.resultMap.get(hash);
        }

        // add the array values into the correct row.
        for(const [field, node] of Object.entries(jsonObject)){
            if(node instanceof Array){
                // Node is array, so the result should also be an array, this is safe to assume
                const listValue = result.get(field);
                const value = listValue[0];
                let nextValue = null;
                for(const listElm of node){
                    if(typeof listElm === 'object'){
                        // We might have another array in this object, so we need to do recursive parsing.
                        // It's possible that we don't need to add a new object at this level in the array,
                        // because we add a new record in one of its children or, sibblings. In that case
                        // mergeIntoResultMap returns null.
                        // We always store object as a Map of <String, Object>, so we can safely cast to this
                        // type.
                        nextValue = this.mergeIntoResultMap(/* object */ value, listElm, hash);
                    }else{
                        nextValue = value;
                    }
                    if(existingRow !== null && nextValue !== null){
                        const existingValue = existingRow.get(field);
                        existingValue.push(nextValue);
                    }
                    // in the structuredResult we can only have 1 item in the array that provide a blueprint
                    // of the result. If there are more items, we will simply ignore them.
                    break;
                }
            }else if(typeof node === 'object'){
                // We have to go into the object, because it might have array values that we need to merge
                const listObj = result.get(field);
                this.mergeIntoResultMap(listObj, node, hash);
            }
        }

        if(existingRow === null){
            // remove the internal "__hash" property as we don't want that in our response
            this.removeHash(result);
            return result;
        }
        return null;
    }

    // recursively remove the "__hash" property from the resultrow
    // All ArrayList and Hashmap will have the same types, so we can safely cast them accordingly
    removeHash(result){
        result.delete('__hash');

        for(const value of result.values()){
            if(value instanceof Array){
                for(const listItem of value){
                    if(listItem instanceof Map){
                        this.removeHash(listItem);
                    }
                }
            }else if(value instanceof Map){
                this.removeHash(value);
            }
        }
    }

    unmapData(data){
        let result = data;
        // make a shallow copy
        if(data instanceof Map){
            // unmap: turn map into object
            result = Object.fromEntries(data);
        }else if(data instanceof Array){
            result = [...data];
        }else if(data && data instanceof Object){
            result = {...data};
        }
        // recursively unmap any child properties
        if(result instanceof Array){
            for(let i = 0; i < result.length; i++){
                result[i] = this.unmapData(result[i]);
            }
        }else if(React.isValidElement(result)){
            // Do not go into this object as it is a React element with recursive references
        }else if(result instanceof Object){
            for(const key of Object.keys(result)){
                result[key] = this.unmapData(result[key]);
            }
        }
        return result;
    }

    getData(){
        return this.unmapData(this.data);
    }
}

export {StructuredSet};
