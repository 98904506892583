import React, {useEffect} from 'react';
import {UIModal} from '../../ui/modal/modal.js';
import PropTypes from 'prop-types';
import {UIButton} from '../../ui/button/button.js';
import {FormElement} from '../../../helpers/form/element.js';
import {UIInput} from '../../ui/input/input.js';
import {UIInputCheckbox} from '../../ui/input/checkbox.js';
import {useForm} from '../../../helpers/form/use_form.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {list} from './level_mapping.js';
import {UIInputSelect} from '../../ui/input/select.js';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {isWithinLength} from '../../../helpers/form/validation.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const AdminObjectivesNew = function(props){
    const [state, dispatch] = useStateContext(['page', 'id', 'category']);
    const {getLanguages} = useTranslation();
    const languages = getLanguages().filter((l) => l.value !== 'en');

    const selected = list.find((item) => item.id === state.category);
    const selectedModel = selected.editModel || selected.model;
    const formConfig = [
        {
            title: 'Num',
            name: 'num',
            element: UIInput,
            type: 'wide'
        },
        ...(state.category === 'srf_st_outcome_indicator' ?
            [{
                title: 'Core KPI',
                name: 'is_core_kpi',
                element: UIInputCheckbox,
                required: false,
                type: 'wide',
                options: [{value: true, label: 'This is a core KPI'}]
            }] :
            []
        ),
        {
            title: 'Title',
            name: 'title',
            element: UIInput,
            required: true,
            type: 'wide'
        },
        ...languages.map((lang) => {
            return {
                title: 'Title ' + lang.title?.toLowerCase(),
                name: 'title_' + lang.value,
                required: true,
                type: 'wide',
                element: UIInput
            };
        }),
        {
            title: 'Short title',
            name: 'short_title',
            element: UIInput,
            required: ['srf_st_outcome_indicator', 'srf_indicator'].includes(state.category) ? false : true,
            type: 'wide',
            validations: ['srf_st_outcome_indicator', 'srf_indicator'].includes(state.category) ?
                null :
                [
                    {
                        checkValue: (value) => isWithinLength(value, 512),
                        message: 'Short title must be under 500 characters'
                    }
                ]
        },
        ...languages.map((lang) => {
            return {
                title: 'Short title ' + lang.title?.toLowerCase(),
                name: 'short_title_' + lang.value,
                element: UIInput,
                required: ['srf_st_outcome_indicator', 'srf_indicator'].includes(state.category) ?
                        false :
                        true,
                type: 'wide',
                validations: ['srf_st_outcome_indicator', 'srf_indicator'].includes(state.category) ?
                    null :
                    [
                        {
                            checkValue: (value) => isWithinLength(value, 512),
                            message: 'Short title must be under 500 characters'
                        }
                    ]
            };
        }),
        {
            title: 'Parent',
            name: 'parent',
            element: UIInputSelect,
            options: {},
            type: 'wide'
        }
    ];

    const Form = useForm(formConfig);
    const previousIndex = list.findIndex((item) => item.id === state.category) -
        (state.category === 'srf_output' ? 2 : 1);
    const previousItem = list[(previousIndex < 0) ? 0 : previousIndex];
    const previousModel = previousItem.editModel || previousItem.model;
    const resultSet = useModelFeedback(previousModel);

    useEffect(() => {
        if(props.initialValues !== null){
            const values = {
                title: props.initialValues.title,
                num: props.initialValues.num || '',
                short_title: props.initialValues.short_title || '',
                is_core_kpi: props.initialValues.is_core_kpi ? [true] : []
            };
            for(const language of languages){
                values['title_' + language.value] = props.initialValues['title_' + language.value];
                values['short_title_' + language.value] = props.initialValues['short_title_' +
                        language.value];
            }

            if(selected.foreign_key){
                values.parent = props.initialValues.parent_id || props.initialValues[selected.foreign_key];
            }
            Form.setValues(values);
        }
    }, [Form, props.initialValues]);

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const options = [...resultSet.data]
            .sort((a, b) => ((a?.num || a?.id) > (b?.num || b?.id) ? 1 : -1))
            .reduce((obj, item) => {
                obj['o' + item.id] = (item?.num || item?.id || '') + '. ' + item.title;
                return obj;
            }, {});

    const submitForm = () => {
        const values = Form.getValues();
        if(Form.validate()){
            const result = {
                title: values.title
            };
            for(const language of languages){
                result['title_' + language.value] = values['title_' + language.value];
            }

            if(!['srf_objective'].includes(state.category)){
                result.num = values.num;
            }
            if(['srf_lt_outcome', 'srf_st_outcome', 'srf_output', 'srf_objective'].includes(state.category)){
                result.short_title = values.short_title;
                for(const language of languages){
                    result['short_title_' + language.value] = values['short_title_' + language.value];
                }
            }
            if(state.category !== 'srf_objective' && selected.foreign_key &&
                    typeof values.parent === 'string' && values.parent.substring(0, 1) === 'o'){
                result[selected.foreign_key] = parseInt(values.parent.substring(1));
            }
            if(state.category === 'srf_st_outcome_indicator'){
                result['is_core_kpi'] = values.is_core_kpi.length > 0 &&
                        (values.is_core_kpi[0] === true || values.is_core_kpi[0] === 'true');
            }
            if(props.initialValues){
                selectedModel.updateItem(state.id, result, () => {
                    dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
                });
            }else{
                selectedModel.createItem(result, () => {
                    dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
                });
            }
        }
    };

    return <UIModal onClose={() => dispatch({type: 'setValues', value: {subpage: 'list', id: null}})}
            title={`${(props.initialValues === null) ? 'Add new' : 'Edit'} item`} closeOnClickOutside={false}>
        <Form.Component>
            {formConfig.map((element) => {
                const opts = element.name === 'parent' ? {options} : {};
                // Hide 'parent', 'num' fields on Objectives tab
                if((state.category === 'srf_objective' && ['parent', 'num'].includes(element.name)) ||
                    // Hide short_title on all tabs except lt_outcome, st_outcome and srf_output;
                    (!['srf_lt_outcome', 'srf_st_outcome', 'srf_output', 'srf_objective']
                            .includes(state.category)) &&
                        element.name === 'short_title'){
                    return null;
                }
                return <FormElement key={element.name} {...element} {...opts}/>;
            })}
            <br/>
            <UIButton onClick={submitForm} style={{marginRight: 5}}>
                {(props.initialValues === null) ? 'New' : 'Edit'}
            </UIButton>
        </Form.Component>
    </UIModal>;
};

AdminObjectivesNew.defaultProps = {
    initialValues: null
};

AdminObjectivesNew.propTypes = {
    initialValues: PropTypes.object,
    selectedTab: PropTypes.object
};

export {AdminObjectivesNew};
