import {useEffect, useCallback, useState} from 'react';
import {SimpleEvent} from './simple-event.js';

const useClickOutsideElement = function(callback, rootElement = document.body){
    const [element, setElement] = useState(null);
    const measuredRef = useCallback((node) => {
        setElement(node);
    }, []);

    const onRootElementClick = (event) => {
        let node = event.target;
        if(element !== null && node !== element){
            while(node.parentNode && node.parentNode !== rootElement && node.parentNode !== document.body){
                node = node.parentNode;
                if(node === element){
                    // click inside the element, do not trigger the callback
                    return;
                }
            }
            // element not found, so the click was outside the element. Apply the callback
            callback();
        }
    };

    useEffect(() => {
        const event = new SimpleEvent(rootElement, 'click', onRootElementClick);
        return () => {
            event.remove();
        };
    }, [onRootElementClick]);

    return measuredRef;
};

export {useClickOutsideElement};
