// ref: https://stackoverflow.com/a/44764466
// Note: It does not extract css of external website urls.

const extractCSS = function(){
    const css = [];
    for(let i = 0; i < document.styleSheets.length; i++){
        const sheet = document.styleSheets[i];
        const rules = ('cssRules' in sheet) ? sheet.cssRules : sheet.rules;
        if(rules){
            css.push('\n/* Stylesheet : ' + (sheet.href || '[inline styles]') + ' */');
            for(let j = 0; j < rules.length; j++){
                const rule = rules[j];
                const cssRule = ('cssText' in rule) ?
                        rule.cssText :
                        rule.selectorText + ' {\n' + rule.style.cssText + '\n}\n';
                // Ignore media queries in css;
                if(!cssRule.includes('@media')){
                    css.push(cssRule);
                }
            }
        }
    }
    return css.join('\n') + '\n';
};

export {extractCSS};
