import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';
import {ModelSrfIndicator} from './srf_indicator.js';
import {ModelSrfStOutcomeIndicator} from './srf_st_outcome_indicator.js';

let srfIndicatorModel = null;
ModelSrfIndicator.addConsumer(ModelSrfIndicator, {}, (model) => {
    if(model.status === ModelSrfIndicator.Status.SUCCESS){
        srfIndicatorModel = model;
    }
});

let srfSTIndicatorModel = null;
ModelSrfStOutcomeIndicator.addConsumer(ModelSrfStOutcomeIndicator, {}, (model) => {
    if(model.status === ModelSrfStOutcomeIndicator.Status.SUCCESS){
        srfSTIndicatorModel = model;
    }
});

class ModelSrfIndicatorGuidance extends ModelAuthenticatedXhr {
    static modelName = 'ModelSrfIndicatorGuidance';

    static getSelect(input){
        return input;
    }

    // static createItem(data, onSuccess = () => {}){
    //     this.saveData([ModelSrfIndicatorGuidance], CONFIG.api_endpoint + 'srf-indicator-guidance',
    //             'POST',
    //             data,
    //             (response) => {
    //                 if(response.success){
    //                     const item = response.result ? (response.result[0] || null) : null;
    //                     onSuccess(item);
    //                 }else{
    //                     // todo: post status to global message channel
    //                     console.error(response.error || 'Unknow error');
    //                 }
    //             }, {contentType: 'params'});
    // }

    // NB id should be a value of "type/id"
    static updateItem(id, data, onSuccess = () => {}){
        this.saveData([ModelSrfIndicatorGuidance], CONFIG.api_endpoint + 'srf-indicator-guidance/' + id,
                'PATCH',
                data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                        // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    // NB id should be a value of "type/id"
    // static deleteItem(id, onSuccess = () => {}){
    //     this.saveData([ModelSrfIndicatorGuidance], CONFIG.api_endpoint + 'srf-indicator-guidance/' + id,
    //             'DELETE',
    //             null,
    //             (response) => {
    //                 if(response.success){
    //                     const item = response.result ? (response.result[0] || null) : null;
    //                     onSuccess(item);
    //                 }else{
    //                 // todo: post status to global message channel
    //                     console.error(response.error || 'Unknow error');
    //                 }
    //             });
    // }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf-indicator-guidance',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getEndpoint(){
        return this.endpoint + (this.select.id ? '/' + this.select.id : '');
    }

    getPayload(select){
        return null;
    }

    getSelect(input){
        return ModelSrfIndicatorGuidance.getSelect(input);
    }

    getDataFromResponse(data){
        try {
            const res = JSON.parse(data);

            const indicators = srfIndicatorModel?.data?.reduce((obj, item) => {
                if(! obj[item.id]){
                    obj[item.id] = item.num;
                }
                return obj;
            }, {});

            const stIndicators = srfSTIndicatorModel?.data?.reduce((obj, item) => {
                if(! obj[item.id]){
                    obj[item.id] = item.num;
                }
                return obj;
            }, {});

            // Add correct output to indicator;
            const test = (res.result === undefined) ?
                res.value || [] :
                res.result.map((item) => {
                    item.num = item.indicator_type === 'ST' ?
                            stIndicators[item.fk_srf_indicator_id] : indicators[item.fk_srf_indicator_id];
                    item.parent_id = item.fk_srf_indicator_id;
                    item.title = 'Indicator ' + item.num;
                    item.id = item.indicator_type + '/' + item.fk_srf_indicator_id;
                    return {...item};
                });
            return test;
        } catch (e){
            return super.getDataFromResponse(data);
        }
    }
}

export {ModelSrfIndicatorGuidance};
