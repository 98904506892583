import {useModelFeedback} from '../model/use_model_feedback.js';
import {ModelSrfLinkView} from '../../model/srf-link-view.js';
import {ModelSrf} from '../../model/srf.js';
import {useStateContext} from '../state-context.js';
import {getLinkFilter} from './get_link_filter.js';

const useLinkFilter = () => {
    const [state] = useStateContext(['gcm', 'reg_priorities', 'sdg', 'language']);
    const filterState = {reg_priorities: state.reg_priorities, sdg: state.sdg, gcm: state.gcm};

    // TODO: this doesn't consider the case when child element of the current item has links to an item.
    // however in that case we do want to highlight the result too.
    const srfStructure = ['objective', 'lt_outcome', 'st_outcome'];
    const resultSetSrf = useModelFeedback(ModelSrf,
            {
                select: srfStructure.map((i) => (i + '_id')).join(', '),
                language: state.language
            });

    const resultSetLinks = useModelFeedback(ModelSrfLinkView,
            {
                select: 'item_type, fk_item_id, link_type, fk_link_id',
                filter: getLinkFilter(filterState)
            });

    const filterHasValue = getLinkFilter(filterState) !== false;
    if(resultSetSrf.feedback || resultSetLinks.feedback){
        return {
            links: [],
            hasValue: filterHasValue
        };
    }

    // For each child link add the value to the parent item too. That way we hightlight any item that contains
    // a linked item somewhere within
    const data = [...resultSetLinks.data];
    const parentIds = {
        st_outcome: resultSetSrf.data.reduce((obj, item) => {
            obj[item.st_outcome_id] = item.lt_outcome_id;
            return obj;
        }, {}),
        lt_outcome: resultSetSrf.data.reduce((obj, item) => {
            obj[item.lt_outcome_id] = item.objective_id;
            return obj;
        }, {})
    };
    for(let i = srfStructure.length - 1; i > 0; i--){
        const itemType = srfStructure[i];
        const parentType = srfStructure[i - 1];
        data.forEach((link) => {
            if(link.item_type === 'srf_' + itemType){
                // Note this may result in duplicate link records, but that is okay
                data.push({
                    ...link,
                    item_type: 'srf_' + parentType,
                    fk_item_id: parentIds[itemType][link.fk_item_id]
                });
            }
        });
    }

    return {
        links: data,
        hasValue: filterHasValue
    };
};

export {useLinkFilter};
