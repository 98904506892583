import React, {useEffect, useState} from 'react';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelSrfLinkView} from '../../model/srf-link-view.js';
import {useStateContext} from '../../helpers/state-context.js';
import {StructuredSet} from '../../helpers/data-wrangling/structured-set.js';
import {ReactComponent as PlusSvg} from '../../assets/icons/plus.svg';
import {ReactComponent as RightArrowIcon} from '../../assets/icons/icon-arrow.svg';
import {getHighlightedText} from '../search/get_highlighted_text.js';
import {sortFilter} from '../../helpers/srf/sort_filter.js';
import {useTranslation} from '../../helpers/use_translation.js';
import {scrollTo} from '../../helpers/scroll.js';

const structure = {
    id: 'rp_region_id',
    title: 'rp_region_title',
    children: [
        {
            id: 'rp_priority_id',
            title: 'rp_priority_title',
            num: (row) => row.rp_priority_num || ''
        }
    ]
};

const LinkedRegionalPriority = function(props){
    let selectedRegion = JSON.parse(localStorage.getItem('selected_region'));
    let scrollToRegion = false;
    if(selectedRegion && ! (selectedRegion instanceof Array)){
        selectedRegion = [parseInt(selectedRegion)];
        localStorage.setItem('selected_region', JSON.stringify(selectedRegion));
        scrollToRegion = true;
    }
    const [expandedRps, setExpandedRps] = useState(selectedRegion || []);
    const [state] = useStateContext();
    const {getText} = useTranslation();
    const resultSet = useModelFeedback(ModelSrfLinkView, {
        select: 'rp_priority_id, rp_priority_title, rp_priority_num, rp_region_id, rp_region_title',
        filter: `rp_priority_id ne null and item_type eq 'srf_st_outcome' and fk_item_id eq ${state.st_id}`,
        language: state.language
    });

    useEffect(() => {
        const timerId = setTimeout(() => {
            if(scrollToRegion){
                const element = document.getElementById('zol-region-' + selectedRegion[0]);
                if(element){
                    scrollTo(element, true, 1, null, -150);
                }
            }
        }, 500);
        return () => {clearTimeout(timerId);};
    }, []);

    const setRegion = (regionId) => {
        let newList = [...expandedRps];
        // Add region to list
        if(expandedRps.includes(regionId)){
            newList = expandedRps.filter((r) => r !== regionId);
        }else{
            newList.push(regionId);
        }
        localStorage.setItem('selected_region', JSON.stringify(newList));
        setExpandedRps(newList);
    };

    const setRegions = (list) => {
        localStorage.setItem('selected_region', JSON.stringify(list));
        setExpandedRps(list);
    };

    const list = StructuredSet.fromArray(sortFilter(resultSet.data, 'regional'), structure).getData();

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    if(list.length === 0){
        return <p>{getText('linked-filter-empty', {filterKey: getText('filter-rp-one')})}</p>;
    }

    const areAllSelected = expandedRps.length === list.length;

    // TODO: Make this toggle container a reusable decorator. It is used both here as well as for the
    // indicators.
    return <>
        <div style={{display: 'flex', justifyContent: 'flex-end'}} className={'pdf-export-hide'}>
            <span className={'zol-st-toggle' + (areAllSelected ? '' : ' zol-st-toggle-active')}
                    onClick={() => {setRegions(list.map((el) => el.id));}}>{getText('show-list-all')}</span> |
            <span className={'zol-st-toggle' + (areAllSelected ? ' zol-st-toggle-active' : '')}
                    onClick={() => {setRegions([]);}}>{getText('hide-list-all')}</span>
        </div>
        {list.map((item, key) => {
            const isVisible = expandedRps.includes(item.id);
            return <div className="zol-show-toggle-container page-break" key={key}
                    id={'zol-region-' + item.id} style={{margin: '20px 0'}}>
                <div className="zol-show-toggle"
                        onClick={() => setRegion(item.id)}>
                    <p>{item.title}</p>
                    <p className="show-hide-button pdf-export-hide">
                        {isVisible ? `${getText('hide-list')} ` : `${getText('show-list')} `}
                        <PlusSvg transform={isVisible ? 'rotate(45)' : null}/>
                    </p>
                </div>
                {isVisible ?
                    <div className="zol-show-toggle-box">
                        {item?.children.map((priority, key) => (
                            <div key={key} className="toggle-box-item">
                            <span className="toggle-box-bullit-point">
                                <RightArrowIcon/>
                            </span>
                            <span className="toggle-box-title">
                                {priority.num} - {getHighlightedText(priority.title, state.searchText)}
                            </span>
                            </div>
                        ))}
                    </div> :
                    null
                }
            </div>;
        })}
    </>;
};

export {LinkedRegionalPriority};
