import React, {useState, useEffect} from 'react';
import {UIModal} from '../../ui/modal/modal.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {ModelSrfIndicator} from '../../../model/srf_indicator.js';
import {ModelSrfStOutcomeIndicator} from '../../../model/srf_st_outcome_indicator.js';
import {ModelSrfIndicatorGuidance} from '../../../model/srf_indicator_guidance.js';
import {UIButton} from '../../ui/button/button.js';

const AdminGuidanceNew = function(props){
    const [state, dispatch] = useStateContext(['page', 'id', 'category']);
    const fields = {
        unit: 'Contributing divisions/units',
        definition: 'Indicator definition',
        segregation: 'Minimum disaggregation requirements',
        data_source: 'Data source(s)',
        data_collection: 'Data collection methods and tools',
        data_analysis: 'Data analysis (optional)',
        reporting_frequency: 'Minimum reporting frequency',
        extra_indicators: 'Donor /complementary indicators',
        reporting_areas: 'Reporting Area(s)',
        notes: 'Additional notes'
    };
    const [values, setValues] = useState({});
    const [hasInitialValues, setHasInitialValues] = useState(false);

    const resultSet = useModelFeedback(ModelSrfIndicatorGuidance, {id: state.id});
    const initialValues = resultSet.feedback ? null : resultSet.data[0];
    useEffect(() => {
        if(! hasInitialValues && initialValues !== null){
            const initValues = Object.keys(fields).reduce((obj, field) => {
                obj[field] = initialValues[field] || '';
                return obj;
            }, {});
            setValues(initValues);
            setHasInitialValues(true);
        }
    }, [initialValues]);

    const params = state.id.split('/');
    const indicatorType = params[0];
    const indicatorId = params[1];

    const resultSetIndicator = useModelFeedback(
            indicatorType === 'ST' ? ModelSrfStOutcomeIndicator : ModelSrfIndicator,
            {id: indicatorId});
    const indicator = resultSetIndicator.feedback ? {} : resultSetIndicator.data[0];

    const closeModal = () => {
        dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
    };

    const changeText = (event) => {
        if(hasInitialValues){
            const field = event.currentTarget.name;
            const value = event.currentTarget.value;
            setValues((prev) => {return {...prev, [field]: value};});
        }
    };

    const onSubmit = () => {
        ModelSrfIndicatorGuidance.updateItem(state.id, values, () => {closeModal();});
    };

    return <UIModal onClose={closeModal} title="Edit item" width={Math.min(0.9 * window.innerWidth, 1100)}
            closeOnClickOutside={false}>
        <p>Indicator: <strong>{indicator.num || '...'}</strong> {indicator.title || ''}</p>
        <p>
            Provide the nessesary details for the indicator in the text field below. You can format the text
            using <a href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noreferrer">
            Markdown</a> rules.
        </p>

        {Object.keys(fields).map((field) => (
            <div key={field} className="admin-indicator-guidance-field">
                <label>{fields[field]}</label>
                <textarea name={field} value={values[field] || ''} onChange={changeText}/>
            </div>
        ))}

        <UIButton onClick={onSubmit}>Save</UIButton>
    </UIModal>;
};

AdminGuidanceNew.defaultProps = {};

AdminGuidanceNew.propTypes = {};

export {AdminGuidanceNew};
