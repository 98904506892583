import {cloneObject} from './clone_object.js';

const extend = function(object, defaults, clone = true){
    if(defaults == null){
        defaults = {};
    }

    // note that Object.assign does not provide a deep clone
    let newObject = defaults;
    if(clone){
        newObject = cloneObject(defaults);
    }

    if(object == null || typeof object != 'object'){
        return newObject;
    }

    for(const property of Object.keys(object)){
        if(object[property] && object[property].constructor &&
                object[property].constructor === Object){
            newObject[property] = newObject[property] || {};

            // properties that start with "_recursion_" refer to itself and should
            // not be cloned.
            // todo: not the best solution
            if(property.length > 11 && property.substring(0, 11) === '_recursion_'){
                newObject[property] = object[property];
            }else{
                newObject[property] = extend(object[property], newObject[property], false);
            }
        }else if(object[property] instanceof Array){
            newObject[property] = [];
            for(let i = 0; i < object[property].length; i++){
                if(object[property][i] && object[property][i].constructor &&
                        object[property][i].constructor === Object){
                    newObject[property].push(extend({}, object[property][i], false));
                }else if(object[property][i] instanceof Array){
                    const obj = extend({}, {tmp: object[property][i]}, false);
                    newObject[property].push(obj.tmp);
                }else{
                    newObject[property].push(object[property][i]);
                }
            }
        }else{
            newObject[property] = object[property];
        }
    }
    return newObject;
};

export {extend};
