import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {ModelSrf} from '../../../model/srf.js';
import {ModelCcp} from '../../../model/ccp.js';
import {ModelOeeOutput} from '../../../model/oee_output.js';
import * as d3Shape from 'd3-shape';
import {useStateContext} from '../../../helpers/state-context.js';

const useDonutData = function(type){
    const [state] = useStateContext();
    const isMobile = ['small', 'medium'].includes(state.renderSize);
    const objTitleField = isMobile ? 'objective_short_title' : 'objective_title';
    const resultSetObjectives = useModelFeedback(ModelSrf,
            {
                select: 'objective_id, ' + objTitleField +
                        ' as title, objective_id as id, objective_short_title',
                language: state.language
            });
    const resultSetOutcome = useModelFeedback(ModelSrf,
            {
                select: 'lt_outcome_id as id, lt_outcome_num as num, ' +
                    'lt_outcome_short_title as title, objective_id',
                language: state.language
            });

    const lang = state.language === 'en' ? '' : '_' + state.language;
    const resultSetPriorities = useModelFeedback(ModelCcp,
            {select: 'title' + lang + ' as title, id, num'});
    const resultSetOee = useModelFeedback(ModelOeeOutput,
            {select: 'title' + lang + ' as title, id, num, description' + lang + ' as description'});

    const donut = d3Shape.pie()
            .sort(null)
            .value(1);

    const getDonutData = (data, slice) => {
        if(slice !== null){
            return donut
                    .startAngle(slice.startAngle)
                    .endAngle(slice.endAngle)(data);
        }
        return donut(data);
    };

    if(type === 'objectives'){
        const objectiveData = resultSetObjectives.feedback ? [] :
            getDonutData(resultSetObjectives.data, null);
        const data = (!resultSetObjectives.feedback && !resultSetOutcome.feedback) ?
            resultSetObjectives.data?.map((item) => {
                const ltOutcomes = resultSetOutcome.data?.filter((it) =>
                    it.objective_id === item.objective_id);
                const objective = objectiveData.find((obj) => obj.data.objective_id === item.objective_id);
                return {
                    ...objective,
                    children: getDonutData(ltOutcomes, objective)
                };
            }) :
            [];
        return data;
    }else if(type === 'ccp'){
        return resultSetPriorities.feedback ? [] :
            getDonutData(resultSetPriorities.data, null);
    }else if(type === 'oee'){
        return resultSetOee.feedback ? [] :
            getDonutData(resultSetOee.data, null);
    }
    return [];
};

export {useDonutData};
