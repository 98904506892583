import React, {useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {ModelPDFExport} from '../../helpers/pdf-export/pdf-export.js';
import {ExportFilter} from './filter.js';
import {useStateContext} from '../../helpers/state-context.js';
import {Loader} from '../../helpers/elements/loader.js';
import './export.scss';

const ExportContainer = function(props){
    const [state, dispatch] = useStateContext(['exportImage']);

    useEffect(() => {
        let timerId = null;
        if(state.exportImage && state.exportImage === props.id){
            const element = document.getElementById(props.id);
            if(element && element?.innerHTML){
                element.classList.add('pdf-content');
                timerId = setTimeout(() => {
                    ModelPDFExport.export(
                            {
                                extension: props.extension,
                                html: '<div class="pdf-content">' + element.innerHTML + '</div>',
                                options: {
                                    scale: props.scale,
                                    landscape: false
                                }
                            },
                            () => {
                                element.classList.remove('pdf-content');
                                dispatch({type: 'exportImage', value: ''});
                            });
                }, 2000);
            }
        }
        return () => {
            clearTimeout(timerId);
        };
    }, [state.exportImage, dispatch]);

    return <div id={props.id} style={{position: 'relative'}}>
        {props.extension === 'pdf' && state.exportImage ? <ExportFilter/> : null}
        {props.children}
        {state.exportImage ?
                <Loader style={{backgroundColor: 'rgb(255,255,255)', zIndex: 1}}/> :
                null
        }
    </div>;
};

ExportContainer.defaultProps = {
    scale: 0.5
};

ExportContainer.propTypes = {
    extension: PropTypes.oneOf(['pdf', 'jpeg']).isRequired,
    id: PropTypes.string.isRequired,
    scale: PropTypes.number
};

export {ExportContainer};
