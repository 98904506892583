import React, {useEffect} from 'react';
import {UIModal} from '../../ui/modal/modal.js';
import PropTypes from 'prop-types';
import {UIButton} from '../../ui/button/button.js';
import {FormElement} from '../../../helpers/form/element.js';
import {UIInput} from '../../ui/input/input.js';
import {useForm} from '../../../helpers/form/use_form.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {ModelSrfAdmin} from '../../../model/srf_admin.js';
import {isEmail, isEqual} from '../../../helpers/form/validation.js';
import {UIInputPassword} from '../../ui/input/password.js';

const formConfig = [
    {
        title: 'Email',
        name: 'email',
        element: UIInput,
        required: true,
        type: 'wide',
        validations: [
            {
                checkValue: isEmail,
                message: 'Email is not correct'
            }
        ]
    },
    {
        title: 'Password',
        name: 'password',
        element: UIInputPassword,
        required: true,
        type: 'wide',
        validations: [
            {
                checkValue: (value) => (value && value.length >= 8),
                message: 'The password needs to be at least 8 characters long'
            }
        ]
    },
    {
        title: 'Confirm password',
        name: 'password_confirm',
        element: UIInputPassword,
        required: true,
        type: 'wide',
        validations: [
            {
                checkValue: (value, allValues) => isEqual(value, allValues.password),
                message: 'The provided passwords do not match'
            }
        ]
    }
];

const AdminUserNew = function(props){
    const [state, dispatch] = useStateContext();
    const Form = useForm(formConfig);

    useEffect(() => {
        if(props.initialValues !== null){
            Form.setValues(props.initialValues);
        }
    }, [Form, props.initialValues]);

    const submitForm = () => {
        if(Form.validate()){
            const result = Form.getValues();
            if(props.initialValues){
                ModelSrfAdmin.updateItem(state.id, result, () => {
                    dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
                });
            }else{
                ModelSrfAdmin.createItem(result, () => {
                    dispatch({type: 'setValues', value: {subpage: 'list', id: null}});
                });
            }
        }
    };

    return <UIModal onClose={() => dispatch({type: 'setValues', value: {subpage: 'list', id: null}})}
            title={`${(props.initialValues === null) ? 'Add new' : 'Edit'} user`} closeOnClickOutside={false}>
        <Form.Component>
            {formConfig.map((element) => (
                <FormElement key={element.name} {...element}/>
            ))}
            <br/>
            <UIButton onClick={submitForm} style={{marginRight: 5}}>
                {(props.initialValues === null) ? 'New' : 'Edit'}
            </UIButton>
        </Form.Component>
    </UIModal>;
};

AdminUserNew.defaultProps = {
    initialValues: null
};

AdminUserNew.propTypes = {
    initialValues: PropTypes.object,
    selectedTab: PropTypes.object
};

export {AdminUserNew};
