import {getDonutColors} from '../color.js';
import {useModelFeedback} from '../model/use_model_feedback.js';
import {ModelSrf} from '../../model/srf.js';
// import {useDonutData} from '../../components/chart/donut/use_donut_data.js';
import {useStateContext} from '../state-context.js';

const useDonutColor = () => {
    const [state] = useStateContext(['page', 'lt_id', 'language']);
    const resultSet = useModelFeedback(ModelSrf,
            {select: 'objective_id, lt_outcome_id as lt_id', language: state.language}
    );
    if(resultSet.feedback){
        return resultSet.feedback;
    }

    // const data = useDonutData(state.page);
    // const colors = getDonutColors(data.length, state.page);
    // todo: doing this leads to bugs (calling hooks)

    const objectives = resultSet.data.reduce((arr, item) => {
        if(!arr.includes(item.objective_id)){
            arr.push(item.objective_id);
        }
        return arr;
    }, []);
    const colors = getDonutColors(objectives.length, state.page);

    const objective = resultSet.data.find((item) => item.lt_id === parseInt(state.lt_id));
    const objectiveColor = colors[objectives.indexOf(objective?.objective_id)] || [];
    return {colors, objectiveColor};
};

export {useDonutColor};
