import React from 'react';
import {PropTypes} from 'prop-types';

const UITabsTab = function(props){
    return <>{props.children}</>;
};

UITabsTab.propTypes = {
    value: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

// a proptype check used to test if the children of a parent component are of type UITabsTab
const ofTypeUITabsTab = function(props, propName, componentName){
    const prop = props[propName];
    if(props.children.length === 0){
        return new Error('`' + componentName + '` expects at least one child of type `UITabsTab`.');
    }
    React.Children.forEach(prop, function(child){
        if(child.type !== UITabsTab){
            return new Error('`' + componentName + '` children should be of type `UITabsTab`.');
        }
    });

    return null;
};

export {UITabsTab, ofTypeUITabsTab};
