import './index.scss';
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {Tooltip} from '../../../helpers/elements/tooltip.js';
import {useSize} from '../../../helpers/use_size.js';
import {IntroductionModal} from './modal.js';
import {IntroductionStep} from './step.js';
import {scrollTo} from '../../../helpers/scroll.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {getStepPosition} from './get_step_position.js';

const UIIntroduction = function(props){
    const [state, dispatch] = useStateContext();
    const [size, measuredRef] = useSize();
    const [step, setStep] = useState(null);
    const isMobile = ['small', 'medium'].includes(state.renderSize);

    const setTour = (showTour) => {
        dispatch({type: 'tourEnabled', value: showTour});
        if(showTour){
            dispatch({type: 'reg_priorities', value: []});
            dispatch({type: 'sdg', value: []});
            dispatch({type: 'gcm', value: []});
            dispatch({type: 'rotate', value: 0});
            dispatch({type: 'setLTOutcome', value: null});
        }
    };

    useEffect(() => {
        // Update tour;
        setStep((state.tourEnabled === true) ? 0 : null);
        if(state.tourEnabled === false){
            localStorage.setItem('introduction', state.tourEnabled);
            Tooltip.getInstance().setState({});
        }
    }, [state.tourEnabled]);

    useEffect(() => {
        // Load localstorage to check if the intro is ever started.
        const intro = localStorage.getItem('introduction');
        if(state.tourEnabled === null){
            const introVal = (intro === 'null' || intro === null) ? null : (intro === 'true');
            setTour(introVal);
        }
    }, [state.tourEnabled]);

    useEffect(() => {
        let timerId = null;
        timerId = setTimeout(() => {
            if(state.tourEnabled && step !== null){
                const current = props.steps[step];
                if(current.action){
                    current.action();
                }
                const node = document.querySelector(current.target);
                if(node){
                    scrollTo(node, true, 1, null, -300);
                    const [left, top] = getStepPosition(node, current.anchor);
                    if(left && top){
                        const settings = {
                            position: [left, top],
                            header: current?.header,
                            text: <IntroductionStep setStep={setStep}
                                    language={state.language}
                                    setTour={setTour}
                                    step={step}
                                    steps={props.steps}/>,
                            anchor: current.anchor
                        };
                        if(current.width){
                            settings.width = current.width;
                        }
                        Tooltip.getInstance(document.getElementById('zol-introduction-container'))
                                .setState(settings);
                    }
                }
            }
        }, 100);
        return () => {
            clearTimeout(timerId);
        };
    }, [step, state.tourEnabled, size.width, size.height]);

    const classEnabled = `zol-introduction-${((state.tourEnabled && step === null || !state.tourEnabled) ?
            'disabled' :
            'enabled')}`;
    const classStep = state.tourEnabled && step !== null ? `zol-introduction-step-${step}` : '';
    return <div id="zol-introduction-container"
            className={'zol-introduction ' + classEnabled + ' ' + classStep} ref={measuredRef}>
        {((state.tourEnabled || (state.tourEnabled === false && step === null)) || isMobile) ?
            null :
            <IntroductionModal setTour={setTour}/>
        }
        {props.children}
    </div>;
};

UIIntroduction.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        target: PropTypes.string,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        anchor: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
        header: PropTypes.string,
        action: PropTypes.func,
        width: PropTypes.number
    }))
};

export {UIIntroduction};
