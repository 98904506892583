import {useEffect} from 'react';
import {useStateContext} from '../state-context.js';

const InitItemSetter = function(props){
    const [state, dispatch] = useStateContext();

    useEffect(() => {
        if(state.initItem){
            const {searchText, ...values} = state.initItem;
            dispatch({type: 'initItem', value: null});
            dispatch({type: 'setValues', value: values});
            dispatch({type: 'searchText', value: searchText});
        }
    }, [state.initItem]);

    return null;
};

export {InitItemSetter};
