import React from 'react';
import './filter.scss';
import {useStateContext} from '../../helpers/state-context.js';
import {useLinkData} from '../../helpers/srf/use_link_data.js';
import {useTranslation} from '../../helpers/use_translation.js';

const ExportFilter = function(props){
    const [state] = useStateContext();
    const {getText} = useTranslation();
    const {sdgList, gcmList, rpList} = useLinkData();

    const renderRp = () => (
        <div>
            <h3>{getText('filter-rp-many')}</h3>
            {rpList?.filter((i) => state.reg_priorities.includes(i.rp_priority_id))
                    ?.map((item, key) => (
                        <div key={key} className={'zol-export-filter-item'}>
                            {item.rp_region} &gt; {item.rp_priority}
                        </div>
                    ))}
        </div>
    );

    const renderSdg = () => (
        <div>
            <h3>{getText('filter-sdg-many')}</h3>
            {sdgList?.filter((i) => state.sdg.includes(i.sdg_target_id))
                    ?.map((item, key) => (
                        <div key={key} className={'zol-export-filter-item'}>
                            {item.sdg_goal} &gt; {item.sdg_target}
                        </div>
                    ))}
        </div>
    );

    const renderGcm = () => (
        <div>
            <h3>{getText('filter-gcm-many')}</h3>
            {gcmList?.filter((i) => state.gcm.includes(i.id))
                    ?.map((item, key) => (
                        <div key={key} className={'zol-export-filter-item'}>
                            {item.title}
                        </div>
                    ))}
        </div>
    );

    const hasSdg = (state.sdg?.length > 0);
    const hasGcm = (state.gcm?.length > 0);
    const hasRP = (state.reg_priorities?.length > 0);

    if(!hasSdg && !hasRP && !hasGcm){
        return null;
    }

    return <div>
        <h2>Active filters</h2>
        {hasSdg ? renderSdg() : null}
        {hasGcm ? renderGcm() : null}
        {hasRP ? renderRp() : null}
    </div>;
};

export {ExportFilter};
