import React from 'react';
import {UrlStateContext} from '../../helpers/url-state-context/url-state-context.js';
import {StateConsumer} from '../../helpers/state-context/state-consumer.js';
import {getSimpleReducer, StateContext} from '../../helpers/state-context.js';
import {UISwitch} from '../../components/ui/switch/switch.js';
import {AdminObjectives} from './objectives.js';
import {AdminCcp} from './ccp.js';
import {AdminOee} from './oee.js';
import {AdminUser} from './user.js';
import {AdminLink} from './link.js';
import {AdminDownloads} from './downloads.js';
import {ViewAdmin} from '../../view/admin.js';
import {AdminHeader} from '../../components/admin/header.js';

const PageAdmin = function(props){
    const options = {
        srf: {
            value: 'srf',
            label: 'Programmatic Objectives',
            Component: AdminObjectives
        },
        ccp: {
            value: 'ccp',
            label: 'Cross-Cutting Priorities',
            Component: AdminCcp
        },
        oee: {
            value: 'oee',
            label: 'Organizational Effectiveness and Efficiency',
            Component: AdminOee
        },
        user: {
            value: 'user',
            label: 'Users',
            Component: AdminUser
        },
        link: {
            value: 'link',
            label: 'Links',
            Component: AdminLink
        },
        downloads: {
            value: 'downloads',
            label: 'Downloads',
            Component: AdminDownloads
        }
    };

    return <UrlStateContext path={['type', 'subpage', 'category', 'id']}
            reducer={getSimpleReducer(['type', 'subpage', 'category', 'id'])}
            initialState={{type: 'srf', subpage: 'list', category: null, id: null}}>
        <StateContext initialState={{language: 'en'}}>
            <StateConsumer watch={['type']}>
                {(state, dispatch) => {
                    const option = options[state.type];
                    return <ViewAdmin>
                        <AdminHeader/>
                        <UISwitch value={state.type}
                                style={{width: '100%'}}
                                itemStyle={{width: '25%'}}
                                type={'horizontal'}
                                onChange={(type) => (
                                    dispatch({type: 'setValues', value: {type, subpage: 'list'}})
                                )}
                                options={Object.values(options)}/>
                        <option.Component/>
                    </ViewAdmin>;
                }}
            </StateConsumer>
        </StateContext>
    </UrlStateContext>;
};

export {PageAdmin};
