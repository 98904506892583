import React from 'react';
import {PropTypes} from 'prop-types';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {ModelSrfStOutcomeIndicator} from '../../../model/srf_st_outcome_indicator.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const OverviewShortTermIndicator = function(props){
    const [, dispatch] = useStateContext();
    const {getText} = useTranslation();

    const resultSet = useModelFeedback(
            ModelSrfStOutcomeIndicator,
            {filter: 'fk_srf_st_outcome_id eq ' + props.shortTermId}
    );

    if(resultSet.feedback){
        return resultSet.feedback;
    };

    const openIndicators = (item) => {
        dispatch({type: 'setSTOutcome', value: props.shortTermId});
        dispatch({type: 'topic', value: 'st_outcome_indicator'});
    };

    const stIndicatorCount = resultSet.data?.length;
    const title = (stIndicatorCount > 1) ?
            getText('filter-st-outcome-indicator-many') :
            getText('filter-st-outcome-indicator-one');
    return <div className={`zol-short-term-overview-block`} style={{margin: '10px 0'}}>
        <span className={`zol-short-term-overview-title ${(stIndicatorCount === 0 ? '-inactive' : '')}`}
                onClick={() => {openIndicators();}}>
            {stIndicatorCount || getText('st-outome-overview-count-empty')} {title}
        </span>
    </div>;
};

OverviewShortTermIndicator.propTypes = {
    shortTermId: PropTypes.number
};

export {OverviewShortTermIndicator};
