// this is definitely not a complete implementation for cloning an object but good enough in most cases.
const cloneObject = function(value, depth = 0){
    if(value != null && value.constructor && value.constructor === Object){
        const newValue = {};
        for(const property of Object.keys(value)){
            // properties that start with "_recursion_" refer to itself and should
            // not be cloned.
            // todo: not the best solution
            if(property.length > 11 && property.substring(0, 11) === '_recursion_'){
                newValue[property] = value[property];
            }else{
                newValue[property] = cloneObject(value[property], depth + 1);
            }
        }
        return newValue;
    }else if(value instanceof Array){
        const newValue = [];
        for(let i = 0; i < value.length; i++){
            newValue.push(cloneObject(value[i], depth + 1));
        }
        return newValue;
    }else if(value instanceof Set){
        return new Set(value);
    }else if(value instanceof Map){
        return new Map(value);
    }

    return value;
};

export {cloneObject};
