import React from 'react';
import './home.scss';
import {StateContext} from '../helpers/state-context/state-context.js';
import {UrlStateContext} from '../helpers/url-state-context/url-state-context.js';
import {ViewMain} from '../view/main.js';
import {Feedback} from '../components/feedback/index.js';
import {ExportContainer} from '../components/export/container.js';
import {Introduction} from '../components/introduction/index.js';
import {OEE} from '../components/oee/index.js';
import {ChartDonut} from '../components/chart/donut/index.js';
import {ExportButtons} from '../components/export/buttons.js';
import {InitItemSetter} from '../helpers/srf/init-item-setter.js';

const initialState = {
    output: null
};

const initialState2 = {
    // TODO: hover and rotate state can be moved to the donut component as this isn't used elsewhere.
    hover: null,
    rotate: 0,
    searchText: null,
    exportImage: '',
    tourEnabled: null
};

const PageOee = function(props){
    return <UrlStateContext initialState={initialState} hash={['output']}>
        <StateContext initialState={initialState2}>
            <InitItemSetter/>
            <Introduction type={'oee'}>
                <ViewMain type={'oee'}>
                    <ExportContainer id='zol-export' extension='pdf'>
                        <ChartDonut has1Level={true}/>
                        <div className="zol-details-page" id="zol-details-page">
                            <OEE/>
                        </div>
                        <ExportButtons enableFullLayout={false}/>
                        <Feedback/>
                    </ExportContainer>
                </ViewMain>
            </Introduction>
        </StateContext>
    </UrlStateContext>;
};

export {PageOee};
