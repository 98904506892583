import React from 'react';
import {PropTypes} from 'prop-types';
import './loader.css';

const Loader = function(props){
    const borderStyle = Math.round(0.15 * props.size) + 'px solid rgba(' + props.color.join(',') + ',0.2)';
    const style = {
        marginTop: (-0.5 * props.size) + 'px',
        marginLeft: (-0.5 * props.size) + 'px',
        borderTop: Math.round(0.15 * props.size) + 'px solid rgb(' + props.color.join(',') + ')',
        borderRight: borderStyle,
        borderBottom: borderStyle,
        borderLeft: borderStyle,
        width: props.size + 'px',
        height: props.size + 'px'
    };

    return <div className="zol-loader-default pdf-export-hide" style={props.style}>
        <div style={style}/>
    </div>;
};

Loader.defaultProps = {
    size: 80,
    color: [100, 100, 100]
};

Loader.propTypes = {
    style: PropTypes.object,
    size: PropTypes.number,
    color: PropTypes.arrayOf(PropTypes.number)
};

export {Loader};
