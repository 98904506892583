import {getEasing} from './get_easing.js';
import {useState, useEffect} from 'react';
import {requestAnimationFrame} from './request_animation_frame.js';
import {getDefaultTween} from './get_default_tween.js';

// This animation hook lets you animate properties. Each time the value changes it will interpolate from the
// old value to the new. You set the duration of the animation in ms. By providing a getTween function you can
// contol the logic of the interpolation. When initialValue is set this is used as the start value for the
// first animation. Finally you can provide an custom easing function.
// Keep in mind that you can not call this hook conditionally
const useAnimation = function(value, duration = 500, getTween = getDefaultTween, initialValue = null,
        easing = (fact) => (getEasing(fact, 'cubic'))){
    const [instance, updateInstance] = useState({
        time: (new Date()).getTime(),
        startValue: initialValue ?? value,
        currentValue: initialValue ?? value,
        endValue: value,
        fact: initialValue === null ? 1 : 0,
        getTween: getTween || getDefaultTween
    });

    // We are calling the animation cycle inside a useEffect. This way we can clean up the timer when to
    // parent component unmounts
    useEffect(() => {
        let timerId = null;
        if(instance.fact !== 1){
            // we are animating
            // Setup a new animation cycle when there is one or more animations going on
            timerId = requestAnimationFrame.call(window, () => {
                // Update the settings in our instance based on the progress of the animation. We need to get
                // the current state instance from the callback function, because the reference in this scope
                // might be outdated.
                updateInstance((prevInstance) => {
                    const currentTime = (new Date()).getTime();
                    const delta = currentTime - prevInstance.time;
                    const newInstance = {...prevInstance, time: currentTime};
                    // calculate the progress: a number between 0 (start) and 1 (end)
                    newInstance.fact = Math.min(newInstance.fact + (delta / duration), 1);
                    if(newInstance.fact < 1){
                        // calculate the current value
                        newInstance.currentValue = newInstance.getTween(
                                newInstance.startValue, newInstance.endValue, easing(newInstance.fact));
                    }else{
                        // animation completed, set the end value
                        newInstance.currentValue = newInstance.endValue;
                    }
                    // update the instance, which will trigger an update of the parent component and render
                    // the new settings
                    return newInstance;
                });
            });
        }

        return () => {
            // clear the timer
            if(timerId){
                if(window.cancelAnimationFrame){
                    window.cancelAnimationFrame(timerId);
                }else{
                    window.clearTimeout(timerId);
                }
            }
        };
    });

    if(instance.endValue !== value){
        // value has changed, trigger a new animation
        updateInstance((prev) => {
            return {
                ...prev,
                startValue: instance.currentValue,
                endValue: value,
                fact: 0,
                getTween: getTween || getDefaultTween,
                time: (new Date()).getTime()
            };
        });
    }

    // return the current value to render
    return instance.currentValue;
};

export {useAnimation};
