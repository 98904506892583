import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';
import {ModelSrfSdgGoal} from './srf_sdg_goal.js';

let srfSdgGoalModel = null;
ModelSrfSdgGoal.addConsumer(ModelSrfSdgGoal, {}, (model) => {
    if(model.status === ModelSrfSdgGoal.Status.SUCCESS){
        srfSdgGoalModel = model;
    }
});

class ModelSrfSdgTarget extends ModelAuthenticatedXhr {
    static modelName = 'ModelSrfSdgTarget';

    static getSelect(input){
        return input;
    }

    static createItem(data, onSuccess = () => {}){
        this.saveData(ModelSrfSdgTarget, CONFIG.api_endpoint + 'srf-sdg-target', 'POST', data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static updateItem(id, data, onSuccess = () => {}){
        this.saveData(ModelSrfSdgTarget, CONFIG.api_endpoint + 'srf-sdg-target/' + id, 'PATCH', data,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                }, {contentType: 'params'});
    }

    static deleteItem(id, onSuccess = () => {}){
        this.saveData(ModelSrfSdgTarget, CONFIG.api_endpoint + 'srf-sdg-target/' + id, 'DELETE', null,
                (response) => {
                    if(response.success){
                        const item = response.result ? (response.result[0] || null) : null;
                        onSuccess(item);
                    }else{
                    // todo: post status to global message channel
                        console.error(response.error || 'Unknow error');
                    }
                });
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf-sdg-target',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getEndpoint(){
        return this.endpoint + (this.select.id ? '/' + this.select.id : '');
    }

    getPayload(select){
        return null;
    }

    getData(){
        return {
            status: this.status,
            data: this.data || [],
            parentValues: srfSdgGoalModel.data
        };
    }

    getSelect(input){
        return ModelSrfSdgTarget.getSelect(input);
    }
}

export {ModelSrfSdgTarget};
