import './select-grouped.scss';
import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {UIInput} from '../input/input.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const OptionsList = function(props){
    const {getText} = useTranslation();
    const [searchQuery, setSearchQuery] = useState(props.level === 0 ? '' : props.searchQuery);
    const isSearchEnabled = props.isSearchEnabled && props.level === 0;
    const curDim = props.dimensions[0];
    const lastDim = props.dimensions[props.dimensions.length - 1];

    const parentOptions = props.options.reduce((obj, option) => {
        const isOptionChecked = props.value.includes(option[lastDim]);
        const newOption = {
            value: option[curDim],
            children: props.dimensions.length > 1 ? [option] : [],
            details: option,
            isChecked: isOptionChecked,
            amountChecked: isOptionChecked ? 1 : 0
        };

        // if search is active, filter out options that do not match. If we match on a parent item we also
        // include all the children. I think this makes sense as checking the parent will enable all children.
        if(isSearchEnabled && searchQuery !== ''){
            let includeOption = false;
            for(const dim of props.dimensions){
                if(props.getOptionLabel(newOption, dim, true).search(new RegExp(searchQuery, 'i')) !== -1){
                    includeOption = true;
                    break;
                }
            }
            if(! includeOption){
                // skip this option
                return obj;
            }
        }

        // make sure the object is a string so the order will remain
        const objKey = 'key-' + option[curDim];
        if(! obj[objKey]){ // insert the new option for this dimension
            obj[objKey] = newOption;
        }else if(props.dimensions.length > 1){ // add another child for the same option
            obj[objKey].children.push(option);
            if(isOptionChecked){
                obj[objKey].amountChecked++;
            }else{
                obj[objKey].isChecked = false;
            }
        }

        return obj;
    }, {});

    const toggleOption = (option) => {
        if(props.dimensions.length === 1){
            // This is the final level. We select this option directly.
            const nextValues = [...props.value];
            const pos = nextValues.indexOf(option.value);
            if(pos >= 0){
                nextValues.splice(pos, 1);
            }else{
                nextValues.push(option.value);
            }
            props.onChange(nextValues);
            return;
        }
        // this is a group we need to select the child values below instead
        const nextValues = [...props.value];
        for(const child of option.children){
            const value = child[lastDim];
            if(option.isChecked){
                // currently checked, uncheck all child options
                nextValues.splice(nextValues.indexOf(value), 1);
            }else{
                // currently not all options are checked. Check any unchecked children
                if(! nextValues.includes(value)){
                    nextValues.push(value);
                }
            }
        }
        props.onChange(nextValues);
    };

    const childListProps = {
        ...props,
        dimensions: props.dimensions.slice(1),
        level: props.level + 1
    };

    return <>
        {isSearchEnabled ?
            <span className="zol-select-grouped-search" onClick={(e) => {e.stopPropagation();}}>
                <UIInput name="searchSelectOptions"
                        value={searchQuery}
                        onChange={setSearchQuery}
                        isFocusOnMount={true}
                        placeholder="Type to search"/>
            </span> :
            null
        }
        <ul className={'zol-select-grouped-options zol-level-' + props.level}>
            {Object.values(parentOptions).map((option) => (
                <props.Option key={option.value}
                        value={option.value}
                        label={props.getOptionLabel(option, curDim, false)}
                        isChecked={option.isChecked}
                        isPartlyChecked={option.amountChecked > 0}
                        isGroup={props.dimensions.length > 1}
                        searchQuery={searchQuery}
                        isEnabled={props.getIsOptionEnabled(option, curDim)}
                        isOpenOnTitleClick={props.isOpenOnTitleClick}
                        onChange={(value) => {toggleOption(option);}}>
                    {option.children.length > 0 ?
                        <OptionsList {...childListProps} options={option.children}/> :
                        null
                    }
                </props.Option>
            ))}
            {Object.values(parentOptions).length === 0 ?
                <li><p className="zol-no-data">
                    {getText('ui-no-values-matching')}
                </p></li> :
                null
            }
        </ul>
    </>;
};

OptionsList.defaultProps = {
    level: 0,
    searchQuery: ''
};

OptionsList.propTypes = {
    dimensions: PropTypes.array.isRequired, // an array of the dimensions used from parent to child
    options: PropTypes.array.isRequired, // array of object containing all dimensions (parent values repeat)
    // The value currently selected. You are required to manage this outside the component
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired, // callback when the value changes
    // if dimensions is not the value that we want to show, we can use this function to lookup another value
    getOptionLabel: PropTypes.func.isRequired,
    isMultiSelect: PropTypes.bool.isRequired, // whether we can select multiple values (default true)
    isSearchEnabled: PropTypes.bool.isRequired, // whether we want to include a search function
    getIsOptionEnabled: PropTypes.func.isRequired, // function to check whether we can select this option
    Option: PropTypes.elementType.isRequired, // The component to render an option
    level: PropTypes.number, // the level we are in the tree (starts at 0)
    searchQuery: PropTypes.string, // passing on the search value for internal use
    // clicking the title of the group by default opens the group, but with this options you can set the value
    isOpenOnTitleClick: PropTypes.bool.isRequired
};

export {OptionsList};
