import {memo} from './functions/memo.js';
import {CONFIG} from '../config.js';

// Here we configure multiple lists of color combinations. We start with palettes with the least amount of
// colors and go up from there. If we need a palette we call getColorPalette, which will choose the most
// appropriate list (the one that covers the options required, but as little as possible). If we request a
// palette with more colors that the largest list here, we will interpolate between these options to get
// all the colors we need. All colors here are rgb values.
const palettes = CONFIG.colorPalettes || [
    [[91, 146, 229], [255, 184, 28]],

    [[91, 146, 229], [255, 103, 31], [255, 184, 28], [92, 184, 178]],

    [[91, 146, 229], [210, 38, 48], [255, 103, 31], [255, 184, 28], [92, 184, 178]],

    [[91, 146, 229], [0, 51, 160], [152, 54, 178], [210, 38, 48], [255, 103, 31], [255, 184, 28],
        [205, 220, 58], [92, 184, 178]],

    [[1, 188, 214], [46, 190, 255], [91, 146, 229], [0, 51, 160], [104, 58, 183], [152, 54, 178],
        [210, 15, 119], [210, 38, 48], [255, 103, 31], [255, 184, 28], [255, 236, 60], [205, 220, 58],
        [139, 195, 73], [36, 155, 35], [92, 184, 178]]
];

export const getColorPalette = function(amount, customPalettes = null){
    const selectedPalettes = (customPalettes === null) ? palettes : customPalettes;

    let index = 0;
    for(const palette of selectedPalettes){
        index++;
        if(palette.length < amount && index < selectedPalettes.length){
            continue;
        }

        const step = palette.length / amount;
        let offset = 0;
        const result = [];
        for(let i = 0; i < amount; i++){
            const position = i * step + offset;
            const s = Math.floor(position);
            let f = position - s;
            offset = 0;

            // if close enough to palette colour 'snap' to it.
            if(step >= 1){
                f = Math.round(f);
            }else{
                if(f < 0.5 * step){
                    offset = -0.5 * f;
                    f = 0;
                }else if(1 - f < 0.5 * step){
                    offset = 0.5 * (1 - f);
                    f = 1;
                }
            }
            if(s + 1 >= palette.length){
                result.push([
                    palette[s][0],
                    palette[s][1],
                    palette[s][2]
                ]);
            }else{
                result.push([
                    Math.round((1 - f) * palette[s][0] + f * palette[s + 1][0]),
                    Math.round((1 - f) * palette[s][1] + f * palette[s + 1][1]),
                    Math.round((1 - f) * palette[s][2] + f * palette[s + 1][2])
                ]);
            }
        }
        return result;
    }
};

export const getDonutColors = function(amount, page = null){
    if(page === 'ccp'){
        return getColorPalette(amount,
                [[[0, 51, 160], [64, 102, 184], [128, 153, 208]]]);
    }else if(page === 'oee'){
        return getColorPalette(amount,
                [[[92, 184, 178], [133, 202, 197], [174, 220, 217]]]);
    }else{ // objectives
        if(amount === 1){
            return [37, 130, 223];
        }else{
            return getColorPalette(amount,
                    [[[37, 130, 223], [103, 190, 218], [65, 166, 222], [40, 99, 227]]]);
        };
    }
};

// TODO: palette between multiple colors
// export const getPaletteBetween = (colors, amount, alpha = false) => {
//     let part = amount / colors.length;
//     console.log(part);
//
//     const result = [];
//     for(let i = 0; i < amount; i++){
//         if(amount < part){
//             const darkColor = colors[i].match(/\((.*)\)/)[1].replaceAll('%', '').split(',').map(Number);
//             const step = (10 - (darkColor[2])) / parseInt(part);
//             result.push(
//                     `hsl${alpha ? 'a' : ''}(${darkColor[0]}, ${darkColor[1]}%, ` +
//                     `${darkColor[2]}%${alpha ? `,${alpha}` : ''})`
//             );
//             darkColor[2] = darkColor[2] + step;
//         }else{
//             part += amount / colors.length;
//         }
//     }
//     return result;
// };

export const getSectionPalette = (color, amount, alpha = false) => {
    const maxLightness = 10;
    // get numbers from hsl colorcode in array;
    const darkColor = color.match(/\((.*)\)/)[1].replaceAll('%', '').split(',').map(Number);
    const step = (maxLightness - (darkColor[2])) / parseInt(amount);

    const result = [];
    for(let i = 0; i < amount; i++){
        result.push(`hsl${alpha ? 'a' : ''}(${darkColor[0]}, ${darkColor[1]}%, ` +
                `${darkColor[2]}%${alpha ? `,${alpha}` : ''})`);
        darkColor[2] = darkColor[2] + step;
    }
    return result;
};

export const parseCSSColor = function(color){
    color = color.trim().toLowerCase();
    return parseCSSColorExec(color);
};

const parseCSSColorExec = memo(function(color){
    color = colorMap.get(color) || color;
    let hex3 = color.match(/^#([0-9a-f]{3})$/i);
    if(hex3){
        hex3 = hex3[1];
        return [
            parseInt(hex3.charAt(0), 16) * 0x11,
            parseInt(hex3.charAt(1), 16) * 0x11,
            parseInt(hex3.charAt(2), 16) * 0x11, 1
        ];
    }
    let hex6 = color.match(/^#([0-9a-f]{6})$/i);
    if(hex6){
        hex6 = hex6[1];
        return [
            parseInt(hex6.substr(0, 2), 16),
            parseInt(hex6.substr(2, 2), 16),
            parseInt(hex6.substr(4, 2), 16), 1
        ];
    }
    const rgba = color.match(/^rgba\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+.*\d*)\s*\)$/i) ||
            color.match(/^rgba\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
    if(rgba){
        return [
            parseInt(rgba[1], 10),
            parseInt(rgba[2], 10),
            parseInt(rgba[3], 10),
            rgba[4] === undefined ? 1 : parseInt(rgba[4], 10)
        ];
    }
    const rgb = color.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
    if(rgb){
        return [parseInt(rgb[1], 10), parseInt(rgb[2], 10), parseInt(rgb[3], 10), 1];
    }
    if(color.indexOf('hsl') === 0){
        return hslToRgb(color);
    }
    console.error('Unrecognized color format for \'' + color + '\'');
});

export const hslToRgb = memo(function(hsl){
    if(typeof hsl == 'string'){
        hsl = hsl.match(/(\d+(\.\d+)?)/g);
    }
    const h = hsl[0] / 360;
    const s = hsl[1] / 100;
    const l = hsl[2] / 100;
    const a = (hsl[3] === undefined) ? 1 : hsl[3];
    let t1; let t2; let t3; let rgb; let val;

    if(s === 0){
        val = Math.round(l * 255);
        rgb = [val, val, val, a];
    }else{
        if(l < 0.5){
            t2 = l * (1 + s);
        }else{
            t2 = l + s - l * s;
        }
        t1 = 2 * l - t2;
        rgb = [0, 0, 0];
        for(let i = 0; i < 3; i++){
            t3 = h + 1 / 3 * - (i - 1);
            t3 < 0 && t3++;
            t3 > 1 && t3--;
            if(6 * t3 < 1){
                val = t1 + (t2 - t1) * 6 * t3;
            }else if(2 * t3 < 1){
                val = t2;
            }else if(3 * t3 < 2){
                val = t1 + (t2 - t1) * (2 / 3 - t3) * 6;
            }else{
                val = t1;
            }
            rgb[i] = Math.round(val * 255);
        }
    }
    rgb.push(a);
    return rgb;
});

export const rgbToHsl = memo(function(rgb){
    const r = rgb[0] / 255;
    const g = rgb[1] / 255;
    const b = rgb[2] / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h; let s; const l = (max + min) / 2;

    if(max === min){
        h = s = 0; // achromatic
    }else{
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max){
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default: break;
        }

        h /= 6;
    }

    return [h, s, l];
});

export const rgbToCss = function(color){
    if(color.length > 3){
        return 'rgba(' + color[0] + ',' + color[1] + ',' + color[2] + ',' + color[3] + ')';
    }
    return 'rgb(' + color[0] + ',' + color[1] + ',' + color[2] + ')';
};

export const getColorLightness = function(color){
    let rgb = color;
    if(typeof color == 'string'){
        rgb = parseCSSColor(color);
    }
    const hsl = rgbToHsl(rgb);
    return Math.round(100 * hsl[2]);
};

const colorMap = new Map([['aliceblue', '#f0f8ff'], ['antiquewhite', '#faebd7'], ['aqua', '#00ffff'],
    ['aquamarine', '#7fffd4'], ['azure', '#f0ffff'], ['beige', '#f5f5dc'], ['bisque', '#ffe4c4'],
    ['black', '#000000'], ['blanchedalmond', '#ffebcd'], ['blue', '#0000ff'], ['blueviolet', '#8a2be2'],
    ['brown', '#a52a2a'], ['burlywood', '#deb887'], ['cadetblue', '#5f9ea0'], ['chartreuse', '#7fff00'],
    ['chocolate', '#d2691e'], ['coral', '#ff7f50'], ['cornflowerblue', '#6495ed'], ['cornsilk', '#fff8dc'],
    ['crimson', '#dc143c'], ['cyan', '#00ffff'], ['darkblue', '#00008b'], ['darkcyan', '#008b8b'],
    ['darkgoldenrod', '#b8860b'], ['darkgray', '#a9a9a9'], ['darkgreen', '#006400'], ['darkkhaki', '#bdb76b'],
    ['darkmagenta', '#8b008b'], ['darkolivegreen', '#556b2f'], ['darkorange', '#ff8c00'],
    ['darkorchid', '#9932cc'], ['darkred', '#8b0000'], ['darksalmon', '#e9967a'], ['darkseagreen', '#8fbc8f'],
    ['darkslateblue', '#483d8b'], ['darkslategray', '#2f4f4f'], ['darkturquoise', '#00ced1'],
    ['darkviolet', '#9400d3'], ['deeppink', '#ff1493'], ['deepskyblue', '#00bfff'], ['dimgray', '#696969'],
    ['dodgerblue', '#1e90ff'], ['firebrick', '#b22222'], ['floralwhite', '#fffaf0'],
    ['forestgreen', '#228b22'],
    ['fuchsia', '#ff00ff'], ['gainsboro', '#dcdcdc'], ['ghostwhite', '#f8f8ff'], ['gold', '#ffd700'],
    ['goldenrod', '#daa520'], ['gray', '#808080'], ['green', '#008000'], ['greenyellow', '#adff2f'],
    ['honeydew', '#f0fff0'], ['hotpink', '#ff69b4'], ['indianred', '#cd5c5c'], ['indigo', '#4b0082'],
    ['ivory', '#fffff0'], ['khaki', '#f0e68c'], ['lavender', '#e6e6fa'], ['lavenderblush', '#fff0f5'],
    ['lawngreen', '#7cfc00'], ['lemonchiffon', '#fffacd'], ['lightblue', '#add8e6'],
    ['lightcoral', '#f08080'],
    ['lightcyan', '#e0ffff'], ['lightgoldenrodyellow', '#fafad2'], ['lightgray', '#d3d3d3'],
    ['lightgreen', '#90ee90'], ['lightpink', '#ffb6c1'], ['lightsalmon', '#ffa07a'],
    ['lightseagreen', '#20b2aa'], ['lightskyblue', '#87cefa'], ['lightslategray', '#778899'],
    ['lightsteelblue', '#b0c4de'], ['lightyellow', '#ffffe0'], ['lime', '#00ff00'], ['limegreen', '#32cd32'],
    ['linen', '#faf0e6'], ['magenta', '#ff00ff'], ['maroon', '#800000'], ['mediumaquamarine', '#66cdaa'],
    ['mediumblue', '#0000cd'], ['mediumorchid', '#ba55d3'], ['mediumpurple', '#9370db'],
    ['mediumseagreen', '#3cb371'], ['mediumslateblue', '#7b68ee'], ['mediumspringgreen', '#00fa9a'],
    ['mediumturquoise', '#48d1cc'], ['mediumvioletred', '#c71585'], ['midnightblue', '#191970'],
    ['mintcream', '#f5fffa'], ['mistyrose', '#ffe4e1'], ['moccasin', '#ffe4b5'], ['navajowhite', '#ffdead'],
    ['navy', '#000080'], ['oldlace', '#fdf5e6'], ['olive', '#808000'], ['olivedrab', '#6b8e23'],
    ['orange', '#ffa500'], ['orangered', '#ff4500'], ['orchid', '#da70d6'], ['palegoldenrod', '#eee8aa'],
    ['palegreen', '#98fb98'], ['paleturquoise', '#afeeee'], ['palevioletred', '#db7093'],
    ['papayawhip', '#ffefd5'], ['peachpuff', '#ffdab9'], ['peru', '#cd853f'], ['pink', '#ffc0cb'],
    ['plum', '#dda0dd'], ['powderblue', '#b0e0e6'], ['purple', '#800080'], ['red', '#ff0000'],
    ['rosybrown', '#bc8f8f'], ['royalblue', '#4169e1'], ['saddlebrown', '#8b4513'], ['salmon', '#fa8072'],
    ['sandybrown', '#f4a460'], ['seagreen', '#2e8b57'], ['seashell', '#fff5ee'], ['sienna', '#a0522d'],
    ['silver', '#c0c0c0'], ['skyblue', '#87ceeb'], ['slateblue', '#6a5acd'], ['slategray', '#708090'],
    ['snow', '#fffafa'], ['springgreen', '#00ff7f'], ['steelblue', '#4682b4'], ['tan', '#d2b48c'],
    ['teal', '#008080'], ['thistle', '#d8bfd8'], ['tomato', '#ff6347'], ['turquoise', '#40e0d0'],
    ['violet', '#ee82ee'], ['wheat', '#f5deb3'], ['white', '#ffffff'], ['whitesmoke', '#f5f5f5'],
    ['yellow', '#ffff00'], ['yellowgreen', '#9acd32']]);
