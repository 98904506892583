import React from 'react';
import PropTypes from 'prop-types';
import {Transformation} from '../../../helpers/math/transformation.js';
import * as d3Shape from 'd3-shape';
import styles from '../../../assets/variables.module.scss';
import {useStateContext} from '../../../helpers/state-context.js';

// This components handles the shadow at the donut; As it's not a simple circle, but has cuts in it;
const DonutShadow = function(props){
    const {data, startRadius, donutRadius} = props;
    const [state] = useStateContext();
    const transformation = new Transformation(0, 0, 1, -1 * state.rotate * Math.PI / 180);
    const rotation = transformation.getTransformationMatrix().multiplyVector([3, 5]);

    return <g id={'zol-donut-circle'} className={'zol-donut-shadow'}>
        {data.map((item, key) => {
            const arc = d3Shape.arc()
                    .innerRadius(donutRadius * startRadius)
                    .outerRadius(donutRadius *
                        (props.has1Level ? (startRadius + 0.4) : 1))
                    .padAngle(.04);
            return <path key={key}
                    fill={styles.colorBackground}
                    d={arc(item)}
                    filter={`drop-shadow(${rotation[0]}px ${rotation[1]}px 2px rgb(0 0 0 / 0.4))`}/>;
        })}
    </g>;
};

DonutShadow.defaultProps = {
    has1Level: false,
    data: [],
    startRadius: 0.25,
    donutRadius: 200
};

DonutShadow.propTypes = {
    data: PropTypes.array,
    startRadius: PropTypes.number,
    donutRadius: PropTypes.number,
    has1Level: PropTypes.bool
};

export {DonutShadow};
