import PropTypes from 'prop-types';
import React, {useState, useEffect, useCallback} from 'react';

const UIInput = function(props){
    const onKeyDown = (e) => {
        if(props.onEnter && e.keyCode === 13){ // enter
            props.onEnter(e.target.value);
        }
    };
    const [element, setElement] = useState(null);
    const measuredRef = useCallback((node) => {
        if(node !== null){
            setElement(node);
        }
    }, []);
    useEffect(() => {
        if(props.isFocusOnMount && element){
            element.focus();
        }
    }, [props.isFocusOnMount, element]);

    return <input className={`zol-single-input ${props.hasError ? 'zol-single-error' : ''}`}
            ref={measuredRef}
            type={props.type}
            value={props.value}
            onKeyDown={onKeyDown}
            placeholder={props.placeholder}
            onChange={(e) => props.onChange(e.target.value)}
            onBlur={(e) => props.onSave(e.target.value)}/>;
};

UIInput.defaultProps = {
    value: '',
    type: 'text',
    hasError: false,
    onChange: () => {},
    onSave: () => {},
    isFocusOnMount: false
};

UIInput.propTypes = {
    name: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onEnter: PropTypes.func,
    placeholder: PropTypes.string,
    isFocusOnMount: PropTypes.bool
};

export {UIInput};
