import React from 'react';
import PropTypes from 'prop-types';
import {ItemBlock} from '../../item-block/index.js';
import {OverviewOutput} from './output.js';
// import {OverviewRegionalPriorites} from './regional-priorities.js';
import {OverviewSdg} from './sdg.js';
import {OverviewGcm} from './gcm.js';
import {useLinkFilter} from '../../../helpers/srf/use_link_filter.js';
import {useStateContext} from '../../../helpers/state-context.js';
import {UIButton} from '../../ui/button/button.js';
import {OverviewShortTermIndicator} from './short-term-indicator.js';
import {useTranslation} from '../../../helpers/use_translation.js';

const ShortTermOutcomeOverview = function(props){
    const [, dispatch] = useStateContext();
    const {getText} = useTranslation();
    const {item, total} = props;
    const filter = useLinkFilter();
    const stLinks = filter.links.filter((link) => (
        link.item_type === 'srf_st_outcome' && item.id === link.fk_item_id
    ));
    const opacity = (filter.hasValue) ? (stLinks.length > 0) ? 1 : .4 : 1;
    return <ItemBlock
            className={'page-break'}
            details={
                <>
                    <UIButton className={'zol-blue'}
                            onClick={() => dispatch({type: 'setSTOutcome', value: item.id})}>
                        {getText('st-outcome-overview-details')}
                    </UIButton>
                    <OverviewShortTermIndicator shortTermId={item.id}/>
                </>
            }
            title={getText('filter-st-outcome-one') + ' ' + item.num}
            text={item.short_title}
            style={{width: `calc(${100 / total}% - 10px)`, opacity, maxWidth: 300}}>
        <div className={`zol-short-term-overview`}>
            <OverviewOutput shortTermId={item.id}/>
            {/* <OverviewRegionalPriorites shortTermId={item.id}/> */}
            <OverviewSdg shortTermId={item.id}/>
            <OverviewGcm shortTermId={item.id}/>
        </div>
    </ItemBlock>;
};

ShortTermOutcomeOverview.propTypes = {
    total: PropTypes.number,
    item: PropTypes.shape({
        id: PropTypes.number,
        num: PropTypes.string,
        short_title: PropTypes.string
    }).isRequired
};

export {ShortTermOutcomeOverview};
