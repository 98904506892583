import {CONFIG} from '../config.js';
import {ModelAuthenticatedXhr} from './authenticated-xhr.js';

class ModelSrf extends ModelAuthenticatedXhr {
    static modelName = 'ModelSrf';

    static getSelect(input){
        const select = {
            select: input.select ?
                input.select :
                'objective_title, objective_id, lt_outcome_num, lt_outcome_title, ' +
                    'st_outcome_num, st_outcome_title',
            filter: input.filter ?
                input.filter :
                '',
            order: input.order ?
                input.order :
                '',
            language: input.language ?
                input.language :
                'en'
        };
        return select;
    }

    constructor(selectData){
        super(selectData, {
            endpoint: CONFIG.api_endpoint + 'srf',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getSelect(input){
        return ModelSrf.getSelect(input);
    }

    getData(){
        let result = [];
        if(this.status === ModelSrf.Status.SUCCESS){
            if(this.data){
                result = [...this.data];
                if(! this.select.order){
                    result.sort((a, b) => (a.id < b.id) ? -1 : 1);
                }
            }
        }
        return {status: this.status, data: result};
    }
}

export {ModelSrf};
