import React from 'react';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelSrfLinkView} from '../../model/srf-link-view.js';
import {useStateContext} from '../../helpers/state-context.js';
import {gcmMapping} from '../../assets/icons-gcm.js';
import './style.scss';
import {sortFilter} from '../../helpers/srf/sort_filter.js';
import {useTranslation} from '../../helpers/use_translation.js';

const LinkedGcm = function(props){
    const [state] = useStateContext();
    const {getText} = useTranslation();
    const resultSet = useModelFeedback(ModelSrfLinkView, {
        select: 'gcm_objective_id as id, gcm_objective_title as title',
        filter: `gcm_objective_id ne null and item_type eq 'srf_st_outcome' and fk_item_id eq ${state.st_id}`,
        language: state.language
    });

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    if(resultSet.data.length === 0){
        return <p>{getText('linked-filter-empty', {filterKey: getText('filter-gcm-one')})}</p>;
    }

    return <div className="zol-st-gcm">
        <ul>
            {sortFilter(resultSet.data, 'gcm').map((item, key) => {
                const GcmIcon = gcmMapping[item.id] ? gcmMapping[item.id] : null;
                return <li key={item.id}>
                    <GcmIcon/>
                    <div>
                        <b>{getText('filter-gcm-one')} {item.id}: </b>
                        {item.title}
                    </div>
                </li>;
            })}
        </ul>
    </div>;
};

export {LinkedGcm};
